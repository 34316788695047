import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Assistants, Chats, Filters, Resources, Results, Search, Style } from '@local/client-contracts';
import { KeyName } from '@local/ts-infra';
import { UTextareaInteractiveComponent } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { KeyboardHelperService } from '@shared/helper/keyboard-helper.service';
import { LogService } from '@shared/services';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { SessionService } from '@shared/services/session.service';
import { getProfileIcon } from '@shared/utils/set-icon.util';
import { Logger } from '@unleash-tech/js-logger';
import { NgScrollbar } from 'ngx-scrollbar';
import { Subscription, distinctUntilChanged, filter, map, take } from 'rxjs';
import { WorkspacesService } from 'src/app/bar/services';
import { FiltersService } from 'src/app/bar/services/filters.service';
import { HubService } from 'src/app/bar/services/hub.service';
import { SearchOptions, SearchResultContext, SearchService, SearchSession } from 'src/app/bar/services/search';
import { AnswersSourceSettings } from 'src/app/bar/services/search/client';
import { AnswerGenerateState, AnswerSearchItem } from '../../../results';
import { ChatResourcesService } from '../../services/chat-resources.service';
import { ChatsService } from '../../services/chats.service';
import { cloneDeep, isEqual } from 'lodash';

interface WorkspaceData {
  name: string;
  icon: Results.Icon;
}

interface Feedback {
  feedbackType: Resources.FeedbackType;
  intent: Chats.AnswerIntent;
  resources: Search.ResultResourceItem[];
  linkIds: string;
  resourceIds: string;
  index: number;
}

@UntilDestroy()
@Component({
  selector: 'chat-page',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatComponent implements OnInit {
  readonly sendIcon = {
    styles: {
      color: 'var(--primary-A100)',
      width: '32px',
      height: '32px',
      fontSize: '14px',
      backgroundColor: 'var(--color-primary)',
      borderRadius: '4px',
    },
    value: 'send_fill',
  };
  readonly stopIcon = {
    styles: {
      color: 'var(--color-text-secondary)',
      width: '32px',
      height: '32px',
      fontSize: '12px',
      backgroundColor: 'transparent',
      borderRadius: '4px',
      border: '1px solid var( --color-border-popup-2)',
      boxShadow: '0px 2px 5px 0px rgba(209, 155, 255, 0.17)',
    },
    value: 'stop-square',
  };
  readonly emptyStateIcon: Style.Icon = {
    lightUrl: './assets/chat/empty-state-light.svg',
    darkUrl: './assets/chat/empty-state-dark.svg',
  };
  readonly TEXTAREA_PLACEHOLDER = 'Ask me about anything in';
  readonly MAX_INPUT_WORDS = 800;
  readonly MAX_INPUT_WORDS_REACHED_MSG = 'Word limit reached';
  private readonly KEY_HANDLER_PRIORITY = 4;
  private logger: Logger;
  private searchSession: SearchSession;
  private innerFilters: Filters.Values;
  private keyboardHelperService: KeyboardHelperService;
  private keyHandlerId: string;
  private searchSubscription: Subscription;

  feedback: Feedback;
  chat: Chats.Chat;
  workspaceData: WorkspaceData;
  userIcon: Results.Icon;
  isLoadingAnswer = false;
  isTempChatSession = false;
  generatingState: boolean;
  private _query = '';
  isValidTextareaInput = true;
  get query() {
    return this._query;
  }
  set query(value: string) {
    if (!value) {
      this._query = '';
    } else {
      this._query = value;
    }
    this.isValidQuery = !!(this.query && this.query.trim());
  }

  isValidQuery = false;
  draftQuestion: Chats.ChatQuestionMessage = null;
  isSearchCompleted = false;
  isTypingFinished = true;
  clientSearchId: string;
  sessionId: string;
  isNewChat = false;

  @ViewChild(UTextareaInteractiveComponent) uTextareaInteractive: UTextareaInteractiveComponent;
  @ViewChild(NgScrollbar) scrollRef: NgScrollbar;
  @ViewChild('footer') footerRef: ElementRef;
  @ViewChild('answerFeedback') answerFeedbackRef: ElementRef;

  private get currentFiltersForQuestion(): Promise<Filters.Values> {
    return this.filtersService.transformFiltersForDisplay(this.innerFilters);
  }

  constructor(
    logService: LogService,
    private workspaceService: WorkspacesService,
    private sessionService: SessionService,
    private hubService: HubService,
    private chatsService: ChatsService,
    private searchService: SearchService,
    private cdr: ChangeDetectorRef,
    private filtersService: FiltersService,
    private chatResourcesService: ChatResourcesService,
    private keyboardService: KeyboardService
  ) {
    this.logger = logService.scope('ChatComponent');
  }

  ngOnInit() {
    this.initChatMessages();
  }

  private initChatMessages() {
    this.searchSession = this.searchService.getOrCreateSearchSession('Chat-page');
    this.setUpKeyboardHelperService();
    this.getWorkspaceData();
    this.getUserIcon();
    this.hubService.readOnly = true;
    this.chatsService.currentChat$
      .pipe(
        filter((c) => !!c),
        untilDestroyed(this)
      )
      .subscribe((newChat) => {
        this.chat = newChat;
        if (newChat.id === 'new') {
          this.isNewChat = true;
        } else {
          this.isNewChat = false;
          this.isTempChatSession = this.checkTempChatSession(newChat);
          if (this.isTempChatSession) {
            this.query = newChat.chatHistory[0]?.question.query;
            this.sendHandler();
            return;
          }
        }
        this.resetState();
        this.cdr.markForCheck();
        this.scrollToBottom();
      });
  }

  private checkTempChatSession(newChat: Chats.Chat) {
    return !newChat.chatHistory?.[0]?.answer;
  }
  ngAfterViewInit() {
    this.scrollToBottom();
  }
  ngOnDestroy() {
    this.searchSession?.destroy();
    this.chatResourcesService.resetCache();
    this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
  }
  async sendHandler() {
    if (!this.isValidTextareaInput || this.isLoadingAnswer || !this.isValidQuery || !this.isTypingFinished) {
      return;
    }

    this.innerFilters = this.filtersService.allFilters;
    const filters = await this.currentFiltersForQuestion;

    if (this.isNewChat) {
      this.isNewChat = false;
      await this.chatsService.createTempChatSession(this.query, filters);
      return;
    }

    if (this.feedback) {
      this.closeFeedback();
    }
    this.draftQuestion = { query: this.query, filters };
    this.clearTextarea();
    this.isLoadingAnswer = true;
    this.isTypingFinished = false;
    this.updateSearchCompleted(false);
    this.cdr.markForCheck();
    this.scrollToBottom();
    this.searchForAnswer();
  }

  private searchForAnswer() {
    this.unsubscribeSearchSession();
    const chatHistory: Assistants.ChatHistoryRequest[] = this.chat.chatHistory
      ?.filter((c) => c.question.state !== 'Skipped')
      .map((c) => ({
        question: c.question.query,
        answer: c.answer?.content?.text || '',
      }));
    const options: SearchOptions = {
      resetSession: false,
      query: this.draftQuestion.query, //new question
      sources: [
        {
          type: 'answers',
          minQueryLength: 1,
          minWordLength: 1,
          minWords: 1,
          maxCount: 1,
          chatHistory, //old question & answer
          allowAllQuestionQueries: true,
        } as AnswersSourceSettings,
      ],
      trigger: 'user_query',
    };
    let first = true;
    this.searchSubscription = this.searchSession
      .search$(options)
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged((prev, next) => isEqual(prev, next))
      )
      .subscribe(async (ctx: SearchResultContext) => {
        if (!ctx.sources?.length) {
          return;
        }
        const results = this.handleSearchSessionCompleted(ctx);
        if (results?.length) {
          const result = results[0];
          if (result.state !== 'Loading') {
            if (this.isTempChatSession) {
              this.handleNewChat(result);
              this.isTempChatSession = first = false;
              return;
            }
            this.handleChatUpdate(result, first);
            first = false;
          }
        }
      });
  }
  private unsubscribeSearchSession() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
      this.searchSubscription = undefined;
    }
  }

  private handleSearchSessionCompleted(ctx: SearchResultContext) {
    this.sessionId = ctx.sessionId;
    this.clientSearchId = ctx.clientSearchId;
    this.updateSearchCompleted(ctx.searchCompleted);
    const results = ctx.sources?.find((item) => item.source.type === 'answers')?.items as AnswerSearchItem[];
    return results;
  }
  onFilterChanged() {
    this.uTextareaInteractive?.setFocus();
  }
  private updateSearchCompleted(isCompleted: boolean) {
    this.isSearchCompleted = isCompleted;
    this.cdr.markForCheck();
    this.scrollToBottom();
  }

  private clearTextarea() {
    this.uTextareaInteractive.clearInput();
  }
  private getWorkspaceData() {
    this.workspaceService.current$
      .pipe(
        untilDestroyed(this),
        filter((ws) => !!ws)
      )
      .subscribe((workspace) => {
        const icon = this.workspaceService.getLogo();
        this.workspaceData = { name: workspace.name, icon };
        this.cdr.markForCheck();
      });
  }
  onAnswerTypedFinished() {
    this.isTypingFinished = true;
    this.updateSearchCompleted(true);
  }
  scrollToBottom() {
    requestAnimationFrame(() => {
      this.scrollRef?.scrollTo({ bottom: 0 });
    });
  }
  private getUserIcon() {
    this.sessionService.current$
      .pipe(
        untilDestroyed(this),
        filter((session) => !!session),
        map((session) => session.user),
        take(1)
      )
      .subscribe((user) => {
        this.userIcon = user?.picture ? { lightUrl: user.picture } : getProfileIcon();
        this.cdr.markForCheck();
      });
  }
  onQueryChange($event) {
    this.query = $event;
  }
  private async createChatHistoryItem(result?: AnswerSearchItem): Promise<Chats.ChatHistory | null> {
    if (!result || !result.state) {
      return this.createSkippedAnswer();
    }
    switch (result.state) {
      case 'Full':
      case 'Generating':
      case 'GeneratingDone':
        return this.createResultsAvailableAnswer(result);
      case 'NoResults':
      case 'RephraseRequired':
        return this.createNoResultsFoundAnswer(result);
      default:
        return this.createSkippedAnswer();
    }
  }
  async handleChatUpdate(result: AnswerSearchItem, first: boolean) {
    const newChatHistoryItem = await this.createChatHistoryItem(result);
    if (newChatHistoryItem) {
      this.updateChatHistory(newChatHistoryItem, result?.state, !first);
    }
  }
  private async createBaseChatHistoryItem(result: AnswerSearchItem): Promise<Chats.ChatHistory> {
    const filters = await this.currentFiltersForQuestion;
    return {
      question: { query: result.query, filters },
      searchId: result.query,
      createdTime: Date.now(),
    };
  }
  private async createResultsAvailableAnswer(result: AnswerSearchItem): Promise<Chats.ChatHistory> {
    const baseItem = await this.createBaseChatHistoryItem(result);
    const resources = this.chatsService.convertToAnswerResources(result.resources);
    this.chatResourcesService.updateResourceInCache(result.resources);

    const answer = {
      content: { text: result?.text, formattedAnswer: result?.formattedAnswer },
      state: Chats.ChatAnswerState.ResultsAvailable,
      resources,
    };
    if (result?.intent) {
      answer['intent'] = result.intent;
    }
    return {
      ...baseItem,
      answer,
    } as Chats.ChatHistory;
  }
  private async createNoResultsFoundAnswer(result: AnswerSearchItem): Promise<Chats.ChatHistory> {
    const baseItem = await this.createBaseChatHistoryItem(result);
    const state = result.state === 'RephraseRequired' ? Chats.ChatAnswerState.RephraseRequired : Chats.ChatAnswerState.NoResultsFound;
    const answer = {
      state,
    };

    if (result?.intent) {
      answer['intent'] = result.intent;
    }

    return {
      ...baseItem,
      answer,
    } as Chats.ChatHistory;
  }
  private async createSkippedAnswer(): Promise<Chats.ChatHistory> {
    const filters = await this.currentFiltersForQuestion;
    return {
      question: { query: this.draftQuestion.query, state: 'Skipped', filters },
      createdTime: Date.now(),
      answer: { state: Chats.ChatAnswerState.NoResultsFound },
    } as Chats.ChatHistory;
  }
  async handleNewChat(result: AnswerSearchItem) {
    const { state, text, formattedAnswer, resources, query, searchId, intent } = result;
    const filters = this.chat.chatHistory[0].question.filters;

    const answer: Chats.ChatAnswerMessage = this.chatsService.createAnswer(state, text, formattedAnswer, resources, intent);
    this.chatsService.createChatSession(query, filters, answer, this.chat.id, searchId);

    const newChatHistoryItem = await this.createChatHistoryItem(result);
    if (newChatHistoryItem) {
      this.updateCurrentChat(newChatHistoryItem, state);
    }
  }
  private updateCurrentChat(newChatHistoryItem: Chats.ChatHistory, state?: Assistants.AnswerStatusType) {
    if (this.checkTempChatSession(this.chat)) {
      this.chat = { ...this.chat, chatHistory: [newChatHistoryItem] };
      this.isTempChatSession = false;
    } else {
      this.addChatHistory(newChatHistoryItem);
    }
    this.resetState(state);
  }

  private addChatHistory(newChatHistoryItem: Chats.ChatHistory, generateAnswer?: boolean) {
    const chatHistory = cloneDeep(this.chat.chatHistory) ?? [];
    const lastIndex = chatHistory.length - 1;
    const lastChat = chatHistory[lastIndex];

    if (generateAnswer && lastChat) {
      if (lastChat.searchId) {
        this.chat.chatHistory[lastIndex].answer = newChatHistoryItem.answer;
        this.cdr.markForCheck();
        return;
      }
      chatHistory.pop();
    }

    chatHistory.push(newChatHistoryItem);
    this.chat = { ...this.chat, chatHistory };
  }

  private async handleCancelQuestion(): Promise<Chats.ChatHistory> {
    const filters = await this.currentFiltersForQuestion;
    return {
      question: { query: this.draftQuestion.query, filters },
      createdTime: Date.now(),
      answer: { state: Chats.ChatAnswerState.QueryCancelled },
    } as Chats.ChatHistory;
  }
  private resetState(state?: Assistants.AnswerStatusType): void {
    if (state === 'Generating') {
      this.generatingState = true;
    }
    if (state === 'GeneratingDone') {
      this.isTypingFinished = true;
    }
    if (!AnswerGenerateState.includes(state)) {
      this.generatingState = false;
    }
    this.isLoadingAnswer = false;
    this.draftQuestion = null;
    this.innerFilters = null;
    this.cdr.markForCheck();
  }

  private updateChatHistory(newChatHistoryItem: Chats.ChatHistory, state?: Assistants.AnswerStatusType, generateAnswer?: boolean) {
    if (this.chat?.id !== 'new' && state !== 'Generating') {
      const updatedChatHistory: Chats.ChatHistory[] = cloneDeep(this.chat.chatHistory || []);
      if (state === 'GeneratingDone') {
        updatedChatHistory.pop();
      }
      updatedChatHistory.push(newChatHistoryItem);
      const updateAction: Chats.UpdateAction[] = [{ field: 'chatHistory', type: 'Update', value: updatedChatHistory }];
      this.chatsService.update(this.chat.id, updateAction);
    }
    this.addChatHistory(newChatHistoryItem, generateAnswer);
    this.resetState(state);
  }

  handleIconClick() {
    if (this.isLoadingAnswer) {
      this.cancelQuery();
    } else {
      this.sendHandler();
    }
  }
  private async cancelQuery() {
    this.searchSession.destroy();

    const newChatHistoryItem = await this.handleCancelQuestion();

    if (newChatHistoryItem) {
      if (this.isTempChatSession) {
        this.chatsService.createChatSession(
          newChatHistoryItem.question.query,
          newChatHistoryItem.question.filters,
          newChatHistoryItem.answer,
          this.chat.id,
          newChatHistoryItem.searchId
        );
        this.updateCurrentChat(newChatHistoryItem);
      } else {
        this.updateChatHistory(newChatHistoryItem);
      }
    }

    this.isLoadingAnswer = false;
    this.cdr.markForCheck();
  }
  setUpKeyboardHelperService() {
    this.keyboardHelperService = new KeyboardHelperService('horizontal');
    this.registerKeyHandler();
  }
  registerKeyHandler() {
    if (this.keyHandlerId) return;
    this.keyHandlerId = this.keyboardService.registerKeyHandler(
      (keys: Array<KeyName>, event: CustomKeyboardEvent) => this.handleKeys(keys, event),
      this.KEY_HANDLER_PRIORITY
    );
  }
  private async handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent): Promise<void> {
    if (keys?.length > 1) return;
    const key = keys[0];
    switch (key) {
      case 'slash':
        if (!this?.uTextareaInteractive.isFocus) {
          this.uTextareaInteractive.setFocus();
          event.preventDefault();
        }
        break;
      case 'escape':
        if (this.feedback) {
          this.closeFeedback();
        }
    }
  }

  showFeedback($event, index: number) {
    if (this.feedback) {
      this.closeFeedback();
    }
    const { resources, type } = $event;
    this.feedback = {
      feedbackType: type,
      intent: this.chat.chatHistory[index].answer.intent,
      resources: resources,
      linkIds: resources?.map((r) => r.link.id).join(','),
      resourceIds: resources?.map((r) => r.id).join(','),
      index,
    };
    this.cdr.markForCheck();

    setTimeout(() => {
      const isLastElement = this.chat.chatHistory.length - 1 === index;
      if (isLastElement) {
        this.scrollToBottom();
      } else {
        this.scrollRef.scrollToElement(this.answerFeedbackRef?.nativeElement, { duration: 500 });
      }
    }, 0);
  }

  closeFeedback() {
    if (this.feedback) {
      this.feedback = null;
      this.cdr.markForCheck();
    }
  }
  handleInputError(isValid: boolean) {
    this.isValidTextareaInput = isValid;
    this.cdr.markForCheck();
  }
}
