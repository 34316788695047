import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Calculators, Collections, Commands, LocalActions, Search, WebSearch } from '@local/client-contracts';
import { BrowserTab } from '@local/common';
import { PopupRef } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { KeyboardHelperService } from '@shared/helper/keyboard-helper.service';
import { EventsService, LogService } from '@shared/services';
import { BrowserTabsService } from '@shared/services/browser-tabs.service';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { RouterService } from '@shared/services/router.service';
import { isOpenUrlCommand, isPreviewCommand, windowSizeObserver } from '@shared/utils';
import { NgScrollbar } from 'ngx-scrollbar';
import { pairwise } from 'rxjs';
import { BrowseBarModel } from 'src/app/bar/components/browse-bar/browse-bar.component';
import { CollectionsService } from 'src/app/bar/services/collections.service';
import { CommandsService } from 'src/app/bar/services/commands/commands.service';
import { ResultCommandService } from 'src/app/bar/services/commands/result-command.service';
import { GoLinksService } from 'src/app/bar/services/go-links.service';
import { HubService } from 'src/app/bar/services/hub.service';
import { SearchResultContext } from 'src/app/bar/services/search';
import { SearchParamsService } from 'src/app/bar/services/search-params.service';
import { SearchSourceType } from 'src/app/bar/services/search/client/search-source-type';
import { isRecentSearchesSettings } from 'src/app/bar/services/search/client/source-setings.util';
import { SuggestionsSearchService } from 'src/app/bar/services/suggestions-search.service';
import { TagsService } from 'src/app/bar/services/tags.service';
import { WEB_SEARCH_ITEMS } from '../../../hub/shared/sidebar/menu-items';
import { FilePreviewService } from '../../../preview/file-preview/services/file-preview.service';
import { InvokeCommand } from '../../../results/models/invoke-command.model';
import {
  BrowserBookmarkItem,
  CreateGoLinkItem,
  GoToItem,
  HeaderItem,
  QueryItem,
  RecentSearchItem,
  RfpSearchItem,
  SearchResults,
  SuggestionsItem,
  TelemetryTrigger,
  WebSearchItem,
} from '../../../results/models/results-types';
import { RecentSearchesService } from '../../../results/recent-searches.service';
import { ScrollService } from '../../../results/services/scroll.service';
import {
  isAnswerSearch,
  isCalculation,
  isCollection,
  isGoLink,
  isHeader,
  isOpenAdvancedSearch,
  isPreviewClickAction,
  isRecentSearch,
  isRfpItem,
  isSuggestion,
} from '../../../results/utils/results.util';
import { StaticSearchItem } from '../../../results';
import { Logger } from '@unleash-tech/js-logger';
import { KeyName, getModifiers, isEnterKey } from '@local/ts-infra';
import { CollectionsUtilService } from 'src/app/bar/services/collections-util.service';
import { StaticSearchItemType } from '../../../results/components/static-search-item/static-search-item.model';
import { QuestionnaireService } from 'src/app/bar/services/questionnaire.service';
import { ExperiencesService } from 'src/app/bar/services/experiences.service';
import { ChatsService } from '../../../chat-page/services/chats.service';

export type HomeSearchPopupData = {
  popupWidth: number;
  search?: string;
  browseBarModel?: BrowseBarModel;
  searchData: Partial<SearchResultContext>;
};
@UntilDestroy()
@Component({
  selector: 'home-search-popup',
  templateUrl: './home-search-popup.component.html',
  styleUrls: ['./home-search-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeSearchPopupComponent implements OnInit, OnDestroy, AfterViewInit {
  popupData: HomeSearchPopupData;
  minScrollbarHeight: boolean;
  focusedGroup: number;
  focusOnFilters = false;
  private windowSize$ = windowSizeObserver();
  private readonly stickyFooter: HeaderItem = {
    type: 'header',
    origin: 'sticky-search-for',
    clickable: true,
    selectable: true,
  };

  //keyboard
  private keyHandlerId: string;
  private _selectedIndex: number = undefined;

  showHeaderButton = false;
  private logger: Logger;
  private keyboardHelperService: KeyboardHelperService;
  private keyboardHelperInitialized: boolean;

  private readonly ITEM_HEIGHT = 40;
  private readonly HEADER_HEIGHT = 32;
  private readonly ONLY_GHOSTING_HEIGHT = 208;
  private readonly GHOSTING_HEIGHT = 166;
  private readonly CALCULATOR_HEIGHT = 100;
  private readonly TWO_LINES_ITEM_HEIGHT = 59;
  private readonly DEFAULT_SEARCH_ITEM_HEIGHT = 36;
  private readonly MARGIN_DEFAULT_SEARCH = 14;
  private readonly SINGLE_ITEM_HEIGHT = 38;
  private readonly MAX_HEIGHT_POPUP = 388;
  private readonly MARGIN_MIN_HEIGHT_POPUP = 8;
  private readonly SEARCH_ANSWER_ITEM_HEIGHT = 56;
  private readonly NO_APPS_BAR_HEIGHT = 80;
  private browseBarHeight = 56;

  //scroll
  private scrollService: ScrollService<SearchResults>;
  @ViewChild(CdkVirtualScrollViewport) scrollViewport: CdkVirtualScrollViewport;
  @ViewChild(NgScrollbar) scrollbarRef: NgScrollbar;
  @ViewChildren('resultItem', { read: ElementRef }) resultElements: QueryList<ElementRef>;

  @Output() onClearSearch = new EventEmitter();
  @Output() onReOpen = new EventEmitter();
  @Output() removeFocus = new EventEmitter();
  @Output() onOpenPopup = new EventEmitter();
  @Output() onSelectedIndex = new EventEmitter();

  keyboardOn = false;
  lastHeaderIndex: number;

  constructor(
    logger: LogService,
    private routerService: RouterService,
    private ref: PopupRef<HomeSearchPopupComponent, HomeSearchPopupData>,
    private keyboardService: KeyboardService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    private searchParamsService: SearchParamsService,
    private commandsService: CommandsService,
    private resultCommandService: ResultCommandService,
    private hubService: HubService,
    private tagsService: TagsService,
    private goLinksService: GoLinksService,
    private recentSearches: RecentSearchesService,
    private eventsService: EventsService,
    private suggestionsSearchService: SuggestionsSearchService,
    public collectionsService: CollectionsService,
    private collectionsUtilService: CollectionsUtilService,
    private browserTabsService: BrowserTabsService,
    private filePreviewService: FilePreviewService, // for instancing purposes
    private questionnaireService: QuestionnaireService,
    private experienceService: ExperiencesService,
    private chatsService: ChatsService
  ) {
    this.logger = logger.scope('HomeSearchPopupComponent');
  }

  get items() {
    return this.popupData.searchData.items;
  }

  set items(value) {
    this.popupData.searchData.items = value;
  }

  get isOnlyGoLinksSearch() {
    return this.popupData?.search?.trim()?.startsWith('go/');
  }

  get scrollHeight(): number {
    return this.calcScrollHeight();
  }

  get popupHeight(): number {
    return this.calcPopupHeight();
  }

  get selectedIndex(): number {
    return this._selectedIndex;
  }

  get existOpenAdvancedItem(): boolean {
    return this.popupData.searchData?.items?.some((item) => isOpenAdvancedSearch(item));
  }

  set selectedIndex(value: number) {
    this.onSelectedIndex.emit(value);
    this._selectedIndex = value;
  }

  calcPopupHeight(): number {
    const itemsLength = this.popupData.browseBarModel.items.length;
    if (!itemsLength) {
      this.browseBarHeight = this.NO_APPS_BAR_HEIGHT;
    }
    let scrollHeight = this.scrollHeight;
    if (!this.popupData.search) {
      const height = scrollHeight + this.browseBarHeight;
      return height > this.MAX_HEIGHT_POPUP ? this.MAX_HEIGHT_POPUP : height;
    }
    if (this.isOnlyGoLinksSearch || this.existOpenAdvancedItem) {
      return scrollHeight > this.MAX_HEIGHT_POPUP ? this.MAX_HEIGHT_POPUP : scrollHeight;
    }
    if (this.items.length === 0 && this.popupData.searchData.searchCompleted && !this.isOnlyGoLinksSearch) {
      return this.SINGLE_ITEM_HEIGHT;
    }
    if (!itemsLength) {
      scrollHeight += this.browseBarHeight;
    }
    return scrollHeight + this.SINGLE_ITEM_HEIGHT;
  }

  calcScrollHeight(): number {
    if (!this.popupData.browseBarModel.items?.length) {
      this.browseBarHeight = this.NO_APPS_BAR_HEIGHT;
    }
    if (this.items.length === 0) {
      if (this.popupData.searchData.searchCompleted && !this.isOnlyGoLinksSearch && !this.existOpenAdvancedItem) {
        return this.SINGLE_ITEM_HEIGHT;
      }
      // ghosting state
      return this.ONLY_GHOSTING_HEIGHT;
    }
    let height = 0;
    height = this.items.reduce((total, item) => {
      if (item.type === 'calculator') {
        return (total += this.CALCULATOR_HEIGHT);
      }
      if (item.type === 'header') {
        return (total += this.HEADER_HEIGHT);
      }
      if (!this.popupData.search) {
        return (total += this.DEFAULT_SEARCH_ITEM_HEIGHT);
      }
      if (
        (item.type === 'result' && (item as Search.ResultResourceItem).snippet) ||
        item.type === 'browser-bookmark' ||
        item.type === 'browser-tab'
      ) {
        return (total += this.TWO_LINES_ITEM_HEIGHT);
      }
      if ((item as StaticSearchItem).invokeType === 'search-answer') {
        return (total += this.SEARCH_ANSWER_ITEM_HEIGHT + this.MARGIN_MIN_HEIGHT_POPUP);
      }
      return (total += this.ITEM_HEIGHT);
    }, 0);

    if (!this.popupData.searchData.searchCompleted) {
      height = height + this.GHOSTING_HEIGHT;
    }
    if (!this.popupData.search) {
      const scroll = this.MAX_HEIGHT_POPUP - this.browseBarHeight;
      return height > scroll ? scroll : height + this.MARGIN_DEFAULT_SEARCH;
    }
    if (this.isOnlyGoLinksSearch || this.existOpenAdvancedItem) {
      if (!this.popupData.browseBarModel.items.length) {
        height += this.browseBarHeight;
      }
      return height > this.MAX_HEIGHT_POPUP ? this.MAX_HEIGHT_POPUP : height;
    }
    let scroll = this.MAX_HEIGHT_POPUP - this.SINGLE_ITEM_HEIGHT;
    if (!this.popupData.browseBarModel.items.length) {
      scroll -= this.browseBarHeight;
    }
    return height > scroll ? scroll : height + this.MARGIN_MIN_HEIGHT_POPUP;
  }

  ngAfterViewInit(): void {
    this.refreshScrollService();
    this.ref.onUpdate(() => {
      this.load();
      setTimeout(() => {
        if (this.scrollViewport) {
          this.refreshScrollService();
        }
      }, 50);
    });
  }

  get isCalculatorItemExist() {
    return this.items.some((elm) => elm.type === 'calculator');
  }

  ngOnInit(): void {
    this.selectedIndex = undefined;
    this.scrollService = new ScrollService(this.ngZone);
    this.setUpKeyboardHelperService(true);
    this.load();

    this.windowSize$.pipe(untilDestroyed(this), pairwise()).subscribe(([first, second]) => {
      if (first.width !== second.width || first.height !== second.height) {
        this.closeRef();
        this.onReOpen.emit();
      }
    });
    this.cdr.markForCheck();
  }

  load() {
    this.popupData = this.ref.data;
    this.selectedIndex = this.isOnlyGoLinksSearch || this.existOpenAdvancedItem ? 0 : undefined;
    const items = this.popupData.search ? [...this.items, this.stickyFooter] : this.items;
    if (this.keyboardHelperService) {
      this.keyboardHelperService.onInit(this.selectedIndex, items, this.scrollService);
      this.keyboardHelperInitialized = true;
    }
    this.minScrollbarHeight = this.items.length <= 7;
    this.popupData.searchData.lastHeaderIndex++;
    this.popupData.searchData.items.forEach((item, i) => (item.resultIndex = this.getRealPosition(i)));

    this.cdr.markForCheck();
  }
  ngOnDestroy(): void {
    this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
  }

  @HostBinding('style.width') get width() {
    return this.popupData.popupWidth + 'px';
  }

  @HostListener('mousemove')
  onMouseMove() {
    this.keyboardOn = false;
  }

  onClearSearchClick() {
    this.onClearSearch.emit();
    this.cdr.markForCheck();
  }

  onSearch() {
    this.routerService.navigateByUrl(`search?q=${encodeURIComponent(this.popupData.search)}`);
    this.closeRef();
  }

  onStaticItemInvoke(type: StaticSearchItemType, item: StaticSearchItem) {
    switch (type) {
      case 'fill-questionnaire': {
        this.rfpItemClicked(item);
        break;
      }
      default:
        this.onSearch();
        break;
    }
  }

  private async rfpItemClicked(item: RfpSearchItem) {
    await this.questionnaireService.open({ processId: item.processId });
    this.onOpenPopup.emit();
  }

  recentSearchClicked(recentSearch: RecentSearchItem) {
    const position = this.items.filter((i) => i.type === 'recent-search').findIndex((elm) => elm === recentSearch);
    this.recentSearches.reSearch(recentSearch, position);
    this.ref.close();
    this.ref.destroy();
  }

  setUpKeyboardHelperService(unsetIndex?: boolean) {
    this.keyboardHelperService = new KeyboardHelperService();
    this.keyboardHelperService.updateCdr.pipe(untilDestroyed(this)).subscribe(() => this.cdr.markForCheck());
    this.keyboardHelperService.updateSelectedIndex.pipe(untilDestroyed(this)).subscribe((index) => {
      this.searchForFocusGroup(index);
      this.cdr.markForCheck();
    });

    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys, event) => {
      if (['ArrowDown', 'ArrowUp'].includes(keys[0])) this.keyboardOn = true;
      if (this.keyboardHelperInitialized) {
        this.keyboardHelperService.handleArrows(keys, event);
        this.checkIfCurrentIndexIsHeader(keys, event);
        this.handleKeyEvents(keys, event);
      }
    }, 8);
  }

  checkIfCurrentIndexIsHeader(keys: Array<KeyName>, event: CustomKeyboardEvent) {
    const item = this.items[this.selectedIndex];
    if (item?.type === 'header' && !(item as HeaderItem)?.selectable) {
      this.keyboardHelperService.handleArrows(keys, event);
    }
  }

  private refreshScrollService() {
    this.scrollService.items = this.popupData.search ? [...this.items, this.stickyFooter] : this.items;
    this.scrollService.itemHeights = Array(this.items.length).fill(this.ITEM_HEIGHT);
    this.scrollService.init(this.scrollbarRef, this.resultElements, this.scrollViewport);
    if (!this.scrollViewport?.elementRef?.nativeElement) {
      this.scrollService?.destroy();
    }
    this.cdr.markForCheck();
  }

  clearRecentSearches() {
    this.eventsService.event('search_page.recent_searches_clear');
    this.recentSearches.clearAll();
    let prefixResultsCount = 0;
    let recentSearchCount = 0;

    let currentSource: SearchSourceType;
    let index = 0;
    while (currentSource !== 'recent-search' || index < this.popupData.searchData?.sources?.length - 1) {
      const s = this.popupData.searchData?.sources[index];
      if (isRecentSearchesSettings(s.source)) {
        recentSearchCount = s?.items?.length;
      } else {
        prefixResultsCount += s?.items?.length || 0;
      }
      currentSource = s.source.type;
      index++;
    }
    this.popupData.searchData?.items.splice(prefixResultsCount, recentSearchCount);
    this.cdr.markForCheck();
  }

  onHoverItem(ind: number) {
    if (!this.keyboardOn) {
      this.searchForFocusGroup(ind);
    }
    this.showHeaderButton = this.items[ind]?.source === 'recent-search';
  }

  searchForFocusGroup(ind: number) {
    this.updateSelectedIndex(ind);
    let found = false;
    for (let i = ind; i >= 0; i--) {
      if (isHeader(this.items[i]) && (this.items[i] as HeaderItem).group) {
        this.focusedGroup = i;
        found = true;
        break;
      }
    }
    if (!found) this.focusedGroup = null;
  }

  updateSelectedIndex(ind: number) {
    this.selectedIndex = ind;
    this.showHeaderButton = this.items[ind]?.type === 'recent-search';
    this.keyboardHelperService.selectedIndex = ind;
  }

  onTabClick(index: number, trigger: TelemetryTrigger) {
    const item = this.items[index];
    const position = this.getRealPosition(index);
    const event = this.resultCommandService.getEventMetadata(
      this.popupData.searchData.sessionId,
      this.popupData.searchData.clientSearchId,
      (<any>item)?.searchId,
      trigger,
      'browser-tab',
      item as Search.Item,
      position,
      'tabs'
    );
    this.eventsService.event('results.redirect', event);
    this.browserTabsService.activate((<BrowserTab>(<any>item)).id);
  }

  onBookmarkClick(index: number, trigger: TelemetryTrigger) {
    const item = this.items[index];
    const data = {
      command: { type: 'open-url', url: (item as BrowserBookmarkItem).url },
      trigger,
      context: { openInSelf: false },
      invokerItem: item,
    } as InvokeCommand;

    this.onInvoke(data, 'browser-bookmark');
  }

  onPreviewClick(item: SearchResults, trigger: TelemetryTrigger) {
    if (!isPreviewClickAction(item?.action)) return;
    this.closeRef();
    this.removeFocus.emit();
    this.commandsService.executeCommand({
      type: 'preview',
      model: item,
      previewable: true,
      state: 'popup',
      pageType: 'home',
    } as Commands.Preview<SearchResults>);
  }

  onQueryClick(index) {
    const item = this.items[index];
    this.routerService.navigateByUrl((<QueryItem>(<any>item)).url);
  }
  async handleKeyEvents(keys: Array<KeyName>, event: CustomKeyboardEvent) {
    const key = keys[0];
    const modifiers = getModifiers(keys);
    const item = this.items[this.selectedIndex];

    if (key === 'tab') {
      this.selectedIndex = this.items.length;
      this.cdr.markForCheck();
    }
    if (isEnterKey(key) && (this.selectedIndex === undefined || this.selectedIndex === this.items.length)) {
      return;
    }
    if (isEnterKey(key) && (item?.type !== 'header' || (item as HeaderItem).selectable)) {
      let result = item;
      if (!result) {
        return;
      }
      event.stopPropagation();
      if (result.type === 'header') {
        this.focusGroupClick();
        return;
      }
      if (isPreviewClickAction(result.action)) {
        this.onPreviewClick(result, 'keyboard');
      } else if (result.type === 'goto') {
        this.onGoToItemClick(result as GoToItem);
      } else if (result.type == 'browser-bookmark') {
        this.onBookmarkClick(this.selectedIndex, 'keyboard');
      } else if (result.type == 'browser-tab') {
        this.onTabClick(this.selectedIndex, 'keyboard');
      } else if (result.type == 'query') {
        this.onQueryClick(this.selectedIndex);
      } else if (result.type === 'search-line') {
        this.onSearch();
      } else if (result.type === 'local-action') {
        this.onActionItemClick(this.selectedIndex, 'keyboard');
      } else if (result.type === 'create-go-link') {
        this.onCreateGoLinkItemClick(this.selectedIndex, 'keyboard');
      } else if (isCalculation(result) && result.resource.result?.displayText) {
        this.onInvoke({ command: result.resource.result.onClick, trigger: 'keyboard', context: { openInSelf: false } }, 'calculator');
      } else if (isCollection(result)) {
        this.collectionsService.openCollection(result, 'search-view');
      } else if (isGoLink(result)) {
        this.onInvoke({ command: result.view.title.onClick, trigger: 'keyboard', context: { openInSelf: false } }, 'go-link');
      } else if (isSuggestion(result)) {
        this.onSuggestionItemClicked(result);
      } else if (result.type === 'web-search') {
        const data = {
          command: { type: 'open-url', url: (item as WebSearchItem).url },
          trigger: 'keyboard' as TelemetryTrigger,
          context: { openInSelf: false },
        };
        this.onInvoke(data, 'web-search');
      } else if (isRecentSearch(result)) {
        this.recentSearchClicked(result);
      } else if (isOpenAdvancedSearch(result)) {
        this.onSearch();
      } else if (isAnswerSearch(result)) {
        this.onSearch();
      } else if (isRfpItem(result)) {
        this.rfpItemClicked(result);
      } else {
        const resultResourceItem = result as Search.ResultResourceItem;
        result = result as Search.ResultResourceItem;
        if (!resultResourceItem.view || !resultResourceItem.resource) {
          this.logger.error('result item does not contain view or resource', { id: resultResourceItem.id, type: resultResourceItem.type });
          return;
        }
        const resource: Commands.LinkContextResource = {
          id: resultResourceItem.resource?.id,
          kind: 'link-resource',
          type: resultResourceItem.resource?.type,
        };
        this.onInvoke(
          {
            command: resultResourceItem.view.title.onClick,
            context: { resource, openInSelf: false },
            invokerItem: result,
            trigger: 'keyboard',
          },
          'result'
        );
      }
    }
    if (key === 'ArrowUp' && !this.selectedIndex) {
      this.focusOnFilters = !this.focusOnFilters;
      this.cdr.markForCheck();
    }
    if (key == 'ArrowDown' && this.focusOnFilters) {
      this.focusOnFilters = false;
      this.cdr.markForCheck();
    }
  }

  async moveToGroupSearch() {
    const item = this.items[this.focusedGroup] as HeaderItem;
    if (!item?.clickable || !item?.group) return;
    const group = item.group;
    if (group.name === 'web-search') {
      const url = `/web-search/${group.value}?q=${this.popupData.search}`;
      this.routerService.navigateByUrl(url);
      const i = WEB_SEARCH_ITEMS.find((p) => p.id.split('web-search_')[1] === group.value);
      if (i) {
        this.tagsService.all = [this.hubService.suggestionsToTag(i)];
      }
      return;
    }
    await this.routerService.navigateByUrl('search');
    this.searchParamsService.addGroup(group);
    this.hubService.query = this.popupData.search;
    this.closeRef();
  }

  public async onInvoke(data: InvokeCommand, type: Search.ResultType): Promise<void> {
    const item = this.items[this.selectedIndex];
    this.resultCommandService.onInvoke(
      this.items,
      { ...data, context: { ...data.context, openInSelf: false } },
      type,
      this.popupData.searchData.sessionId,
      this.popupData.searchData.clientSearchId,
      (<any>item)?.searchId,
      this.selectedIndex,
      this.popupData.searchData.lastHeaderIndex,
      null,
      '/search'
    );

    if (isOpenUrlCommand(data.command)) {
      this.closeRef();
    }
    if (isPreviewCommand(data.command)) {
      this.removeFocus.emit();
      this.closeRef();
    }
  }

  onDisclaimerClick(url: string): void {
    const command: Commands.OpenUrl = { type: 'open-url', url };
    this.onInvoke({ command, trigger: 'mouse_click' }, 'calculator');
  }

  onCalcItemClick(index: number): void {
    const item = this.items[index] as Calculators.Item;
    this.onInvoke({ command: item.resource.result.onClick, trigger: 'mouse_click' }, 'calculator');
  }

  onGoToItemClick(item: GoToItem) {
    this.commandsService.executeCommand(item.command);
    this.closeRef();
  }

  onCreateGoLinkItemClick(index: number, trigger: TelemetryTrigger) {
    const item = this.items[index] as CreateGoLinkItem;
    this.goLinksService.openPopup(undefined, item.url, undefined);
  }
  onCreateChatItemClick() {
    this.chatsService.openChat();
  }
  onActionItemClick(index: number, trigger: TelemetryTrigger) {
    const action = this.items[index] as LocalActions.LocalActionItem;
    if (action.id === 'new-chat') {
      this.onCreateChatItemClick();
    } else if (action.id === 'new-go-link') {
      this.onCreateGoLinkItemClick(index, trigger);
    } else if (['new-curated-collection', 'new-live-collection', 'new-wiki-collection'].includes(action.id)) {
      this.collectionsService.openCollectionView({ kind: (<Commands.DynamicCommand>action.command).value as Collections.Kind });
      this.closeRef();
    } else if (action.id === 'new-card') {
      this.collectionsUtilService.openWikiCard();
      this.closeRef();
    } else if (action.id === 'new-rfp-assistant') {
      this.experienceService.createAssistant({ experienceType: 'rfp' });
    } else if (action.id === 'new-salesforce-cases-assistant') {
      this.experienceService.createAssistant({ experienceType: 'salesforce' });
    } else if (action.id === 'new-general-assistant') {
      this.experienceService.createDraftAssistant('general');
    } else {
      const firstLinkId = Object.keys(action.urls)[0];
      const context: Commands.Context = {
        linkId: action.urls[firstLinkId][0].linkId,
        resource: { id: action.id, kind: 'local-action' },
      };
      this.onInvoke({ command: action.command, context, trigger, invokerItem: action }, 'local-action');
    }
    if (trigger === 'mouse_click') {
      this.closeRef();
    }
  }

  closeRef() {
    this.ref.close();
    this.ref.destroy();
  }

  onWebSearchItemClick(item: WebSearch.ResultItem): void {
    this.commandsService.executeCommand({ type: 'open-url', url: item.url } as Commands.OpenUrl);
  }

  isFirstWebSearchResult(index: number): boolean {
    return index > 0 && isHeader(this.items[index - 1]);
  }

  focusGroupClick() {
    this.moveToGroupSearch();
  }

  async onSuggestionItemClicked(item: SuggestionsItem) {
    this.suggestionsSearchService.onSuggestionSelected(item);
  }

  getRealPosition(index: number) {
    return this.resultCommandService.getRealPosition(this.popupData.searchData.items, index, this.popupData.searchData.lastHeaderIndex);
  }
}
