import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ManualPromise } from '@local/common';
import { PopupRef, PopupService } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BreadcrumbsService } from '@shared/services/breadcrumbs.service';
import { SessionService } from '@shared/services/session.service';
import { SlackBotService } from '@shared/services/slack-bot.service';
import { capitalCase } from '@local/ts-infra';
import { FilePreviewPopupComponent } from '../../../preview/file-preview/components/file-preview-popup/file-preview-popup.component';
import { AssistantsGuideModel } from '../../models/assistants-guide.model';
import { assistantsGuidesContent } from './assistants-guide.content';
import { ExperiencesService } from 'src/app/bar/services/experiences.service';
import { firstValueFrom } from 'rxjs';
import { Config } from '@environments/config';

@UntilDestroy()
@Component({
  selector: 'assistants-guide',
  templateUrl: './assistants-guide.component.html',
  styleUrls: ['./assistants-guide.component.scss'],
})
export class AssistantsGuideComponent implements OnInit {
  private readonly assistantsGuides = assistantsGuidesContent.assistantsGuides;
  readonly START_INDEX = 2;
  private popupRef: PopupRef<FilePreviewPopupComponent, any>;
  private redirectUrl: string;
  private appType: 'slack' | 'zendesk' | 'teams';
  model: AssistantsGuideModel;
  isAssistantInstalled: boolean;
  mediaPreviewModel: any;

  //slack
  private slackUrlInit = new ManualPromise<void>();

  //zendesk
  private readonly zendeskRedirectUrl = 'https://www.zendesk.com/marketplace/apps/support/928747/unleash';

  //teams
  private readonly teamsRedirectUrl = Config.teamsInstallUrl;

  constructor(
    private activeRoute: ActivatedRoute,
    private sessionService: SessionService,
    private cdr: ChangeDetectorRef,
    private slackService: SlackBotService,
    private breadcrumbsService: BreadcrumbsService,
    private popupService: PopupService,
    private experiencesService: ExperiencesService
  ) {}

  ngOnInit(): void {
    this.appType = this.activeRoute.snapshot.params.id;
    if (this.appType) {
      this.model = this.assistantsGuides[this.appType];
    }

    this.initBreadcrumbs();
    this.initInstallUrl();
    this.initVideoModel();
  }

  private initBreadcrumbs() {
    this.breadcrumbsService.items = [
      {
        title: 'Assistants',
        path: 'assistants',
        icon: {
          type: 'font-icon',
          value: 'icon-assistant',
        },
      },
      {
        title: capitalCase(this.appType),
        path: this.appType,
        icon: {
          type: 'img',
          value: this.model?.icon,
        },
      },
    ];
  }

  private initInstallUrl() {
    switch (this.appType) {
      case 'slack':
        this.sessionService.current$.pipe(untilDestroyed(this)).subscribe((x) => {
          this.initSlackUrl(x?.workspace?.accountId, x?.token);
        });
        break;
      case 'zendesk':
        this.redirectUrl = this.zendeskRedirectUrl;
        this.experiencesService.visible$.pipe(untilDestroyed(this)).subscribe((all) => {
          this.isAssistantInstalled = !!all?.find((assistant) => assistant.experienceType === 'zendesk');
        });
        break;
      case 'teams':
        this.redirectUrl = this.teamsRedirectUrl;
        break;
      default:
        break;
    }
  }

  private initVideoModel() {
    if (!this.model?.video) {
      return;
    }
    this.mediaPreviewModel = {
      fileType: 'video',
      icon: { type: 'img', value: { lightUrl: '/assets/file-icons/video.svg' } },
      item: { srcUrl: this.model?.video.srcUrl, isFromCache: true },
    };
  }

  private async initSlackUrl(accountId: string, token: string) {
    if (!accountId || !token) {
      return;
    }

    if (this.slackUrlInit.status) {
      this.slackUrlInit = new ManualPromise<void>();
    }

    const res = await firstValueFrom(this.slackService.installUrl$(accountId, token));
    this.redirectUrl = res?.redirectUrl;
    this.isAssistantInstalled = res?.installed;
    this.cdr.markForCheck();
    if (!this.slackUrlInit.status) {
      this.slackUrlInit.resolve();
    }
  }

  async installLink() {
    if (this.appType === 'slack') {
      await this.slackUrlInit;
    }

    window.open(this.redirectUrl);
  }

  openFilePreviewPopup() {
    if (this.popupRef) {
      this.popupRef.destroy();
    }

    const filePreviewModel = this.model.video || this.model.image;

    const item = {
      action: { type: 'files' },
      type: 'assets-file',
      view: {
        title: { text: filePreviewModel.name },
        icon: {
          lightUrl: filePreviewModel.icon,
        },
      },
      resource: {
        traits: {
          mimeType: filePreviewModel.mimeType,
        },
      },
      srcUrl: filePreviewModel.srcUrl,
    };

    this.popupRef = this.popupService.open('center', FilePreviewPopupComponent, item, {
      position: 'center',
      backdropStyle: 'blur-2',
    });
  }
}
