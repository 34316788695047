import { Config } from '@environments/config';
import { Results, Style } from '@local/client-contracts';
import { isNativeWindow } from '@local/common-web';

export function isProdEnv() {
  return location.hostname === 'app.unleash.so';
}

export function isLocalEnv() {
  return location.hostname === 'localhost';
}

export function isReleaseEnv() {
  return window.location.hostname === 'app.unleash.team';
}

export function isDevEnv() {
  return window.location.hostname === 'app.london.unleash.team';
}

export function isPreviewEnv() {
  return window.location.hostname.endsWith('previews.unleash.team');
}

export function isMatrix<T>(x: any): x is T[][] {
  if (!x) {
    return false;
  }
  return Array.isArray(x) && !!x[0] && Array.isArray(x[0]);
}

export function isIcon(x: any): x is Style.Icon {
  return !!x && typeof x === 'object' && 'lightUrl' in x;
}

export function hasDropdown(model: Results.Tag | Results.Badge | Results.BulletPart): boolean {
  // TEMP: remove dropdowns because they contain actions
  return false; // !!model && !!model.dropdown && model.dropdown.items && model.dropdown.items.length > 0;
}

export function measureInputText(el: HTMLInputElement, fontSize?: number): number {
  const c = document.createElement('canvas').getContext('2d');
  c.font = fontSize ? `${fontSize}px` : '';
  const txtWidth = c.measureText(el.value).width;

  return txtWidth;
}

/**
 * Check whether the event occurred roughly inside (or above) the element.
 *
 * @param {MouseEvent} event Event to check.
 * @param {Node} element Element to check.
 */
export function isClickInElement(element: HTMLElement, event: MouseEvent): boolean {
  const rect = element.getBoundingClientRect();
  const x = event.clientX;
  if (x < rect.left || x >= rect.right) return false;
  const y = event.clientY;
  if (y < rect.top || y >= rect.bottom) return false;
  return true;
}

/** @param bufferHeight - In some cases the element is in the viewport but hidden by position absolute / sticky items.
 * In this cases we would want to take them into account in the calculation
 */
export function isFullyInViewport(
  el: HTMLElement,
  viewport: HTMLElement,
  bufferHeight: { top?: number; bottom?: number } = {
    bottom: 0,
    top: 0,
  }
): boolean {
  if (!el) return;
  const { top, bottom } = bufferHeight;
  const { bottom: elBottom, top: elTop } = el.getBoundingClientRect();
  const { bottom: viewBottom, top: viewTop } = viewport.getBoundingClientRect();

  return elTop >= viewTop + (top ?? 0) && elBottom <= viewBottom - (bottom ?? 0);
}

export function getRandom<T>(arr: Array<T>, n: number): Array<T> {
  const result = new Array(n);
  let len = arr.length;
  const taken = new Array(len);
  if (n > len) throw new RangeError('getRandom: more elements taken than available');
  while (n--) {
    const x = Math.floor(Math.random() * len);
    result[n] = arr[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
}

export function flatten(arr, result = []) {
  for (let i = 0, length = arr.length; i < length; i++) {
    const value = arr[i];
    if (Array.isArray(value)) {
      flatten(value, result);
    } else {
      result.push(value);
    }
  }
  return result;
}

/** @param take If the same items is found in the beginning and in the end which one should be picked */
export function unique(array: any[], uniqueField: string, take: 'first' | 'last' = 'first') {
  const a = array.concat();
  for (let i = 0; i < a.length; ++i) {
    for (let j = i + 1; j < a.length; ++j) {
      if (a[i][uniqueField] === a[j][uniqueField]) {
        if (take === 'first') {
          a[i] = a[j]; //replace with the newer value
        }
        a.splice(j--, 1);
      }
    }
  }

  return a;
}

/**
 * Convert bytes into normalized size string (e.g. `2.4 MB`)
 * @param size Size in bytes
 * @param fractionDigits Optional. Number of digits to keep after decimal point
 * @param sizes Optional. List of suffixes (e.g. `['B', 'KB', 'MB']`)
 */
export function formatSize(size: number, fractionDigits = 2, sizes?: Array<string>) {
  const f = sizes || ['B', 'KB', 'MB', 'GB', 'TB'];
  return (function fsize(s, i = 0) {
    if (s > 1024) {
      return fsize(s / 1024, ++i);
    }

    return `${s.toFixed(fractionDigits)} ${i < f.length ? f[i] : ''}`.trim();
  })(size, 0);
}

/**
 * @description shallow equal (JSON.stringify) based check for array / object,
 *  better for performance
 */
export function equal<T extends Array<any> | any>(prev: T, next: T): boolean {
  const _equal = (prev, next) => JSON.stringify(prev) === JSON.stringify(next);

  if (Array.isArray(prev) && Array.isArray(next)) {
    if (prev.length !== next.length) return false;

    for (const prevI of prev) {
      const changed = next.filter((nextI) => _equal(prevI, nextI)).length === 0;
      if (changed) return false;
    }
    return true;
  }

  return _equal(prev, next);
}

/** @description returns a copy of the object without the requested key */
export function removeKey<T extends Object>(obj: T, key: keyof T) {
  if (!obj.hasOwnProperty(key)) return;

  const n = { ...obj };
  delete n[key];
  return n;
}

export const getShareAssetsPath = (fileName: string) => {
  return `/assets/bar/results/share/${fileName}`;
};

/** @returns true if the function can be called with new */
export const isClass = (value: Function) => {
  return (
    typeof value === 'function' &&
    // String proofing, not the best but more efficient then trying try/catch block
    (/^\s*class[^\w]+/.test(value.toString()) ||
      // 1. native classes don't have `class` in their name
      // 2. However, they are globals and start with a capital letter.
      (globalThis[value.name] === value && /^[A-Z]/.test(value.name)))
  );
};

export const flatByProperty = (arr: Array<any>, property: string) => {
  return arr.reduce((acc, r) => {
    if (arr[property]?.length) {
      acc = acc.concat(flatByProperty(arr[property], property));
    } else {
      acc.push(r);
    }

    return acc;
  }, []);
};

/** returns the last item of an array */
export const last = <T>(arr: Array<T>): T => {
  return arr[arr.length - 1];
};

export function generateFullPrefixedURL(url: string, type: 'url' | 'path' = 'url', originLocation?: boolean) {
  let path: string;
  switch (type) {
    case 'url': {
      const urlObj = new URL(url);
      path = `${urlObj.pathname}${urlObj.search ?? ''}`;
      break;
    }
    case 'path': {
      path = url;
      break;
    }
  }
  let origin = Config.baseUrl || window.origin;
  if (isNativeWindow() && origin.startsWith('local:')) {
    origin = origin.replace('local:', isLocalEnv() ? 'http:' : 'https:');
  }
  if (originLocation && isPreviewEnv()) {
    return `https://app.london.unleash.team${path}`;
  }
  return `${origin}${path}`;
}
