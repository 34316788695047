import { Experiences, MemorySearch, Search } from '@local/client-contracts';
import { AssistantsIconsConst, observable } from '@local/common';
import { getAssistantGroupName, getAssistantTitle, filterByKey } from '@local/common-web';
import { LogService } from '@shared/services';
import { MemorySearchService } from '@shared/services/memory-search.service';
import { SessionService } from '@shared/services/session.service';
import { getDisplayHeader } from '@shared/utils/header-builder.util';
import { isEmpty } from 'lodash';
import { Observable, ReplaySubject, Subscription, filter, firstValueFrom } from 'rxjs';
import { ExperienceSearchItem, HeaderItem, SearchResults, isHeader } from 'src/app/bar/views';
import { ExperiencesService } from '../../../experiences.service';
import { MemorySearchClient } from '../memory-search-client/memory-search-client';
import { SearchRequest } from '../search-request';
import { SearchRequestFilters } from '../search-request-filters';
import { SearchResponse } from '../search-response';
import { AssistantsSourceSettings } from './assistants-source-settings';

const filterKeys: string[] = ['assistant-createdBy', 'assistant-type', 'assistant-team', 'assistant-slack-workspace'];
export class AssistantsSearchClient extends MemorySearchClient<AssistantsSourceSettings> {
  private instances: { [sessionName: string]: { subscriptions: Subscription[] } } = {};
  private readonly FILTER_CLIENT_ID = 'assistant';

  constructor(
    logService: LogService,
    memorySearchService: MemorySearchService,
    private assistantService: ExperiencesService,
    private sessionService: SessionService
  ) {
    super(logService, memorySearchService, ['Alphabetical', 'Time'], filterKeys);
    this.logger = logService.scope('AssistantsSearchClient');
  }

  @observable
  getInput(request: SearchRequest<AssistantsSourceSettings>, response: SearchResponse): Observable<MemorySearch.Item[]> {
    const subject = new ReplaySubject<MemorySearch.Item[]>(1);
    this._innerSearch(request, subject, response);
    return subject;
  }

  protected async filter(items: MemorySearch.Item[], settings: AssistantsSourceSettings): Promise<any[]> {
    const filters = { ...(settings.filters.preFilters || {}), ...(settings.filters.postFilters || {}) };
    const user = await firstValueFrom(this.sessionService.current$.pipe(filter((v) => !!v)));
    const newItems = [];
    if (isEmpty(filters)) {
      return items;
    }
    items = items.filter((item) => !isHeader(item));
    for (const item of items) {
      const assistant = item.data as Experiences.ExperienceItem;

      let satisfyFilter = true;
      for (const key of filterKeys) {
        const filter = filters[key];
        satisfyFilter = filter
          ? filterByKey(key.replace(`${this.FILTER_CLIENT_ID}-`, ''), user, filter, {
              kind: getAssistantGroupName(assistant.experienceType),
              createdBy: assistant.createdBy,
              teamName: assistant.properties.teamName,
              workspaceName: assistant.properties.workspaceName,
            })
          : true;
        if (!satisfyFilter) break;
      }

      if (satisfyFilter) {
        newItems.push(item);
      }
    }
    return newItems;
  }

  async getOutput(items: MemorySearch.Item[], sourceSettings?: AssistantsSourceSettings): Promise<SearchResults[]> {
    return items.map((item) => item.data);
  }

  private async _innerSearch(
    request: SearchRequest<AssistantsSourceSettings>,
    subject: ReplaySubject<MemorySearch.Item[]>,
    response: SearchResponse
  ) {
    const sourceSettings = request.sourceSettings;
    const sorting = sourceSettings.sorting;
    const filters = { ...(sourceSettings.filters.preFilters || {}), ...(sourceSettings.filters.postFilters || {}) };
    this.destroy(request.id, request.sessionName);
    this.instances[request.sessionName] = { subscriptions: [] };
    this.instances[request.sessionName].subscriptions.push(
      this.assistantService.visible$.subscribe((items) => {
        if (response.cancelled) return;
        const displayItems: (Experiences.ExperienceItem | HeaderItem)[] = items?.slice(0, sourceSettings.maxCount || items.length) || [];
        const mapItems = displayItems?.map((item) => {
          return isHeader(item)
            ? { data: item, searchText: '', sortValue: '' }
            : {
                data: this.buildAssistantItem(item, filters),
                searchText: getAssistantTitle(item)?.toLowerCase(),
                sortValue: this.getSortValue(sorting, item),
              };
        });
        subject.next(mapItems);
      })
    );
  }

  private buildAssistantItem(item: Experiences.ExperienceItem, filters: SearchRequestFilters): ExperienceSearchItem {
    const experienceType = item.experienceType;
    const icon = AssistantsIconsConst['general'];
    const iconOverlay = AssistantsIconsConst[experienceType];
    const icons = filters['assistant-type'] || experienceType === 'general' ? { icon: iconOverlay ?? icon } : { icon, iconOverlay };
    return {
      ...item,
      type: isHeader(item) ? 'header' : 'assistant',
      ...(icons as any),
    };
  }

  addHeaders(request: SearchRequest<AssistantsSourceSettings>, items: SearchResults[], resultCount: number, totalResults: number): void {
    const settings = request.sourceSettings;
    if (!settings.header) return;

    const { title, titleEnd } = getDisplayHeader({ title: settings.header?.title, titleEnd: settings.header?.titleEnd }, totalResults);
    const header: HeaderItem = {
      type: 'header',
      clickable: false,
      origin: settings.type,
      title,
      titleEnd,
      group: settings.header?.group,
    };

    items.unshift(header);
  }

  private getSortValue(sorting: Search.Sort, item: Experiences.ExperienceItem): string | number {
    let sortValue: number | string;
    switch (sorting?.by) {
      case 'Alphabetical':
        sortValue = getAssistantTitle(item);
        break;
      case 'Timestamp':
        sortValue = item.modifiedAt;
        break;
    }
    return sortValue;
  }

  protected defaultSort(items: MemorySearch.Item[]): MemorySearch.Item[] {
    return items?.sort((prev, current) => current?.data?.modifiedAt - prev?.data?.modifiedAt);
  }

  destroy(id: number, sessionName: string): void {
    const instance = this.instances[sessionName];
    if (instance) {
      instance.subscriptions?.forEach((c) => c.unsubscribe());
      delete this.instances[sessionName];
    }
  }
}
