<loader-overlay *ngIf="!chat?.chatHistory?.length && !draftQuestion && !isNewChat"> </loader-overlay>
<div class="chat-container">
  <ng-container *ngIf="isNewChat && !chat?.chatHistory?.length">
    <ng-container *ngTemplateOutlet="emptyState"></ng-container>
  </ng-container>
  <ng-scrollbar class="chat-scrollbar-wrapper" visibility="hover">
    <div class="chat-items" *ngIf="chat?.chatHistory?.length" #chatResults>
      <ng-container *ngFor="let entry of chat.chatHistory; let i = index; let last = last; let first = first">
        <chat-question-message [question]="entry.question"></chat-question-message>
        <chat-answer-message
          *ngIf="entry.answer"
          [questionQuery]="entry.question.query"
          [answer]="entry.answer"
          [searchId]="entry.searchId"
          [avatarImg]="workspaceData.icon"
          [disableTyped]="!last || !isSearchCompleted"
          [viewMode]="generatingState ? 'regular' : 'typing'"
          [sessionId]="sessionId"
          [clientSearchId]="clientSearchId"
          [isLoadingAnswer]="isLoadingAnswer"
          [isFeedbackOpen]="feedback?.index === i"
          (answerTypedFinished)="onAnswerTypedFinished()"
          (scrollToBottom)="scrollToBottom()"
          (feedbackShow)="showFeedback($event, i)"
        ></chat-answer-message>
        <div #answerFeedback *ngIf="feedback?.index === i">
          <answer-feedback [model]="feedback" [searchId]="entry.searchId" (closeFeedback)="closeFeedback()"></answer-feedback>
        </div>
      </ng-container>
      <ng-container *ngIf="draftQuestion && !this.isTempChatSession">
        <chat-question-message [question]="draftQuestion"></chat-question-message>
      </ng-container>
      <ng-container *ngIf="isLoadingAnswer">
        <result-answer-ghost
          class="loading-msg"
          [avatarImg]="workspaceData.icon"
          [customStyles]="{ icon1FontSize: '15px', icon2FontSize: '9px' }"
        ></result-answer-ghost>
      </ng-container>
      <div *ngIf="!isLoadingAnswer && isTypingFinished" class="ai-container"></div>
    </div>
  </ng-scrollbar>
  <div class="footer" #footer>
    <u-textarea-interactive
      [inlineExtraIcon]="isLoadingAnswer ? stopIcon.value : sendIcon.value"
      [inlineExtraIconTooltip]="isLoadingAnswer ? 'Stop responding' : 'Send'"
      [inlineExtraIconDisabled]="(!isValidQuery && !isLoadingAnswer) || !isValidTextareaInput"
      [extraIconStyles]="isLoadingAnswer ? stopIcon.styles : sendIcon.styles"
      (onInlineExtraIconClick)="handleIconClick()"
      [placeholder]="TEXTAREA_PLACEHOLDER + ' ' + workspaceData?.name || ''"
      [rows]="1"
      [cols]="30"
      [autoResize]="true"
      [maxRowLimit]="5"
      [minHeight]="24"
      [maxWords]="MAX_INPUT_WORDS"
      [leftSideContent]="leftSideContent"
      (onChange)="onQueryChange($event)"
      (onEnter)="sendHandler()"
      (handleValidationResult)="handleInputError($event)"
      name="uTextareaInteractive"
      ngDefaultControl
    ></u-textarea-interactive>
    <ng-container *ngTemplateOutlet="maxContent"></ng-container>
    <banner-alert></banner-alert>
  </div>
</div>
<ng-template #leftSideContent>
  <chat-filters-box [disabled]="isLoadingAnswer" (filterChange)="onFilterChanged()"></chat-filters-box>
</ng-template>
<ng-template #maxContent>
  <div class="max-char-warning" *ngIf="!isValidTextareaInput">{{ MAX_INPUT_WORDS_REACHED_MSG }}</div>
</ng-template>
<ng-template #emptyState>
  <div class="empty-state">
    <u-icon [model]="{ type: 'img', value: emptyStateIcon }"></u-icon>
    <div class="title">Ask me about anything in {{ workspaceData.name }}</div>
    <div class="description">Chat and ask questions to uncover everything you want to know from your company’s knowledge base</div>
  </div>
</ng-template>
