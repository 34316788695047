<div class="tags-container" *ngIf="initialTags?.length || canEdit">
  <ng-container *ngFor="let tag of initialTags ?? []; let index = index">
    <div #tag class="collection-tag single" [tabindex]="index">
      {{ tag }}
      <u-icon
        class="close-icon"
        *ngIf="canEdit"
        (click)="onRemoveTag(tag, index)"
        [model]="{ type: 'font', value: 'icon-Windows-close' }"
        [styles]="{ fontSize: '10px' }"
      ></u-icon>
    </div>
  </ng-container>
  <u-multi-select
    #tag
    class="tag-multi-select"
    panelStyleClass="tag-multi-select-panel"
    [hideSelection]="true"
    [model]="options"
    (onSelectedChange)="addNewTag($event.itemValue.value, false)"
    (onFilter)="onFilter($event.filter)"
    (onClick)="onClick($event)"
    [filterPlaceholder]="tagMultiSelectModel.viewDetails?.placeholder || tagMultiSelectModel.title"
    [noCheckbox]="true"
    [highlightItem]="false"
    (outsideClick)="closeMultiSelect()"
    [ignoreElement]="addTagButton?.button"
    [actualElement]="inlineMode ? overlay : null"
    (enterPressed)="enterPressed()"
    (keydown.enter)="enterPressed()"
    [appendTo]="inlineMode ? 'body' : null"
  >
    <ng-template let-item pTemplate="item">
      <div *ngIf="!item.isNew" class="tag-line">
        <div class="collection-tag">
          {{ item.value }}
        </div>
        <div class="amount">{{ item.amount }} {{ itemSubtitle }}</div>
      </div>
      <div *ngIf="item.isNew && !!tagFilter" class="tag-line clicked" (click)="addNewTag(tagFilter, true)">
        <div class="create-new">Create new</div>
        <div class="collection-tag">
          {{ tagFilter }}
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="emptyfilter">
      <div *ngIf="!!isNewTagAlreadyExist" class="no-results">"{{ tagFilter }}" already exists</div>
    </ng-template>
  </u-multi-select>
  <u-button
    #addTagButton
    *ngIf="canEdit"
    type="secondary-light"
    elementSize="tiny"
    label="Add Tags"
    [styles]="{ width: '70px' }"
    (click)="addTags()"
    class="add-tag-btn"
  ></u-button>
</div>
