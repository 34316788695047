import { Chats } from '@local/client-contracts';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class ChatsRpcInvoker implements Chats.Service {
  @invoker
  get all$(): Observable<Chats.Chat[]> {
    return;
  }

  @invoker
  create(chat: Chats.Chat): Promise<void> {
    return;
  }

  @invoker
  delete(id: string): Promise<void> {
    return;
  }

  @invoker
  update(id: string, actions: Chats.UpdateAction[]): Promise<void> {
    return;
  }

  @invoker
  refresh(): Promise<void> {
    return;
  }
}
