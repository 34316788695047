import { Assistants, Commands, Filters, Search } from '@local/client-contracts';
import { isEmbed, isNativeWindow } from '@local/common-web';
import { EmbedOptions, EmbedService } from '@shared/embed.service';
import { EventInfo, LogService } from '@shared/services';
import { AssistantsService } from '@shared/services/assistants.service';
import { FlagsService } from '@shared/services/flags.service';
import { SessionService } from '@shared/services/session.service';
import { Logger } from '@unleash-tech/js-logger';
import { upperFirst } from 'lodash';
import { Subscription, firstValueFrom } from 'rxjs';
import { AnswerSearchItem, SearchResults, StaticSearchItem, TelemetryTrigger } from 'src/app/bar/views/results/models/results-types';
import { isWikiCard, isWikiCardFile } from 'src/app/bar/views/results/utils/results.util';
import { FiltersService } from '../../../filters.service';
import { ResultMarkdownService } from '../../../result-markdown.service';
import { ResultsService } from '../../../results.service';
import { WikiCardsService } from '../../../wikis/wiki-cards.service';
import { SearchClient } from '../search-client';
import { SearchRequest } from '../search-request';
import { SearchResponse } from '../search-response';
import { SearchResponseType } from '../search-response-type';
import { AnswersSourceSettings } from './answers-source-settings';

export class AnswersSearchClient implements SearchClient<AnswersSourceSettings> {
  private readonly SEPARATOR = /\s+/;
  private logger: Logger;
  private isNative = isNativeWindow();
  private isEmbed = isEmbed();
  private isExtension: boolean;
  private embedOption: EmbedOptions;
  private instances: { [sessionName: string]: Subscription } = {};

  constructor(
    logService: LogService,
    private resultsService: ResultsService,
    private filtersService: FiltersService,
    private embedService: EmbedService,
    private flagsService: FlagsService,
    private resultMarkdownService: ResultMarkdownService,
    private sessionService: SessionService,
    private wikiCardService: WikiCardsService,
    private assistantService: AssistantsService
  ) {
    this.logger = logService.scope('answers');
    this.embedService?.isExternalWebSite()?.then((e) => (this.isExtension = !e));
    this.embedService?.options$?.subscribe((op) => (this.embedOption = op));
  }

  supportsSort(_sort: Search.Sort): boolean {
    return true;
  }

  supportsFilters(_filters: Filters.Values): boolean {
    return true;
  }

  search(request: SearchRequest<AnswersSourceSettings>, response: SearchResponse): SearchResponseType {
    const subscription = this.instances[request.sessionName];
    if (subscription) {
      subscription.unsubscribe();
      this.instances[request.sessionName] = null;
    }
    return this.innerSearch(request, response);
  }

  nextPage(_request: SearchRequest<AnswersSourceSettings>, _response: SearchResponse, _trigger: TelemetryTrigger): Promise<void> {
    return;
  }

  destroy(id: number, sessionName: string): void {
    const subscription = this.instances[sessionName];
    if (subscription) {
      subscription?.unsubscribe();
      delete this.instances[sessionName];
    }
  }

  private async innerSearch(request: SearchRequest<AnswersSourceSettings>, response: SearchResponse) {
    const sourceSettings = request.sourceSettings;
    const isAnswerEnabled = await this.flagsService.isEnabled('answers');
    if (response.cancelled) {
      return;
    }
    const query: string = request.query?.trim();
    if (
      !isAnswerEnabled ||
      !query ||
      query.length < sourceSettings.minQueryLength ||
      query.split(this.SEPARATOR).filter((word) => word.length >= sourceSettings.minWordLength).length < sourceSettings.minWords
    ) {
      response.complete(true);
      return;
    }
    const mergeFilters = this.filtersService.allFilters;
    if (sourceSettings.collectionId) {
      mergeFilters['collectionId'] = [sourceSettings.collectionId];
    }
    const assistantId = sourceSettings.assistantId;
    this.instances[request.sessionName] = this.assistantService
      .answers$({
        query,
        filters: mergeFilters,
        context: { source: this.getClientType(), platform: this.getPlatformType() },
        sessionId: request.sessionId,
        answersResultsTypes: ['KnowledgeBase', 'Cards', 'ResourceLookup'] as Assistants.AnswersResultType[],
        noResults: sourceSettings.noResults,
        experienceId: assistantId,
        chatHistory: sourceSettings.chatHistory,
        allowAllQuestionQueries: sourceSettings.allowAllQuestionQueries,
      })
      .subscribe(async (res) => {
        if (response.cancelled) {
          return;
        }
        switch (res.status) {
          case 'Skipped':
            response.items = [];
            break;
          case 'IsQuestion': {
            const searchAnswerItem: StaticSearchItem = {
              type: 'static-search-item',
              icon: { type: 'font-icon', value: 'icon-answer' },
              title: 'Ask Unleash',
              description: 'Use Unleash AI to find answers based on your company’s knowledge',
              invokeType: 'search-answer',
            };
            response.items = [searchAnswerItem];
            break;
          }
          case 'NoResults':
          case 'Loading':
          case 'RephraseRequired': {
            const loadingItem = {
              type: 'answer',
              query,
              state: res.status,
              searchId: res.searchId,
              debugInfo: res.debugInfo,
              intent: res.intent,
            } as AnswerSearchItem;
            response.items = [loadingItem];
            break;
          }
          case 'Generating':
            await this.onAnswerReady(request, response, res, query);
            response.notifyUpdated();
            break;
          case 'GeneratingDone':
          case 'Full':
            await this.onAnswerReady(request, response, res, query);
            response.notifyUpdated();
            break;
        }
        response.complete(true);
      });
  }
  private buildResourceLookupMarkup(resources: Search.ResultResourceItem[], query: string): string {
    const resourceArea = resources
      .map((resource) => `${resource.view?.title?.text} ${this.getResourceLink(resource)} ${resource.view?.subtitle?.text || ''} `)
      .join('\r\n');
    return `We found the following resources for **"${query}"** \r\n      
        ${resourceArea}
        \r\n`;
  }
  private buildResourceLookup(resources: Search.ResultResourceItem[], query: string): string {
    const resourceArea = resources
      .map(
        (resource) => `<p class="resource-item">
          <img style="width:24px;height:24px!important" src="${resource.view?.icon?.['lightUrl']?.replace(
            'local://',
            'https://'
          )}" alt="resource icon"/>
          <a ${resource.view?.subtitle?.text ? 'class="with-sub-title"' : ''} target="_blank" href="${this.getResourceLink(resource)}">${
          resource.view?.title?.text
        }</a>
          <span>${resource.view?.subtitle?.text || ''}</span>
          </p>`
      )
      .join('');
    return `<p class="resource-lookup">We found the following resources for <strong>"${query}"</strong>       
        ${resourceArea}
        </p>`;
  }

  private getResourceLink(resource: Search.ResultResourceItem) {
    if (isWikiCard(resource)) {
      return this.wikiCardService.getCardUrl(resource?.resource?.data?.title, resource.resource?.data?.id, true);
    }
    if (isWikiCardFile(resource)) {
      return this.wikiCardService.getCardUrl(resource?.resource?.data?.cardTitle, resource.resource?.data?.cardId, true);
    }
    return (<Commands.OpenUrl>resource.view?.title?.onClick)?.url;
  }

  private async onAnswerReady(
    request: SearchRequest<AnswersSourceSettings>,
    response: SearchResponse,
    res: Assistants.AnswersSearchResponse,
    query: string
  ) {
    let items: SearchResults[] = (res.intent == 'ResourceLookup' ? res.results?.slice(0, 5) : res.results) || [];
    let text: string;
    let formattedAnswer: string;
    if (res.federatedAnswer) {
      text = formattedAnswer = res.federatedAnswer.answer;
      if (res.federatedAnswer.resourceIds?.length) {
        const uniqueIds = new Set();
        items = res.results
          ?.filter((r) => {
            if (res.federatedAnswer.resourceIds.includes(r.id) && !uniqueIds.has(r.id)) {
              uniqueIds.add(r.id);
              return true;
            }
            return false;
          })
          ?.slice(0, 12);
      }
    }
    for (const item of items) {
      item.action = await this.resultsService.getResultAction(item);
      if (response.cancelled) {
        return;
      }
    }
    if (res.intent === 'ResourceLookup') {
      text = this.buildResourceLookupMarkup(items as Search.ResultResourceItem[], query);
      formattedAnswer = this.buildResourceLookup(items as Search.ResultResourceItem[], query);
    }
    const template = { type: 'answer', query } as AnswerSearchItem;
    const searchItems: SearchResults[] = [];
    const answerItem = {
      ...template,
      text,
      resources: res.intent === 'ResourceLookup' ? [] : items,
      state: res.status,
      debugInfo: res.debugInfo,
      searchId: res.searchId,
      intent: res.intent,
      formattedAnswer: this.resultMarkdownService.render(formattedAnswer || ''),
    } as AnswerSearchItem;
    searchItems.push(answerItem);
    if (request.sourceSettings.displayOpenChatResult) {
      const openChatItem: StaticSearchItem = {
        type: 'static-search-item',
        icon: { type: 'font-icon', value: 'icon-bubble-dots' },
        title: 'Ask a Follow-up',
        invokeType: 'open-chat',
      };
      const currentSession = await firstValueFrom(this.sessionService.current$);
      openChatItem.description = `Ask about anything in ${currentSession?.workspace?.name || 'N/A'}`;
      searchItems.push(openChatItem);
    }
    response.items = searchItems;
  }
  private getClientType() {
    if (this.isNative) {
      return 'Desktop';
    }
    if (this.isExtension) {
      return 'Extension';
    }
    if (this.isEmbed) {
      return 'Embed';
    }
    return 'Web';
  }

  private getPlatformType() {
    const source = this.getClientType();
    let platform;
    if (source === 'Embed' && this.embedOption?.slug) {
      platform = upperFirst(this.embedOption.slug.split(':')?.[0] || '');
    }
    return platform || source;
  }

  getTelemetryEndEvent(_response: SearchResponse): Partial<EventInfo>[] {
    return;
  }
}
