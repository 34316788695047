import { Experiences, Permissions } from '@local/client-contracts';
import { CreateExperienceResponse } from '@local/common';
import { invoker } from '@unleash-tech/js-rpc';
import { Observable } from 'rxjs';

export class ExperiencesRpcInvoker implements Experiences.Service {
  @invoker
  getExperience(id: string): Promise<Experiences.ExperienceItem> {
    return;
  }

  @invoker
  updateById(body: Experiences.ExperienceItem, unavailableFilterSourceIds?: number[]): Promise<void> {
    return;
  }

  @invoker
  updateShareOptions(id: string, shareOptions: Permissions.ShareOptions): Promise<void> {
    return;
  }

  @invoker
  refresh(): Promise<void> {
    return;
  }

  @invoker
  get all$(): Observable<Experiences.ExperienceItem[]> {
    return;
  }

  @invoker
  create(
    type: Experiences.ExperienceType,
    body?: Experiences.ExperienceItemData,
    unavailableFilterSourceIds?: number[]
  ): Promise<CreateExperienceResponse> {
    return;
  }

  @invoker
  delete(id: string): Promise<void> {
    return;
  }

  @invoker
  getSharedObjects(accountId: string): Promise<Experiences.ExperienceSharedObjects> {
    return;
  }
}
