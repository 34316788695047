export type PageType =
  | 'files'
  | 'people'
  | 'mail'
  | 'wikis'
  | 'confluence'
  | 'jira'
  | 'search'
  | 'slack'
  | 'golinks'
  | 'analytics'
  | 'favorites'
  | 'collections'
  | 'live-collection'
  | 'static-collection'
  | 'wiki-collection'
  | 'collection-file'
  | 'assistants'
  | 'relevant-people'
  | 'cards'
  | 'chats'
  | 'drafts';

export type ResultActionSettings = {
  [key: string]: ResultActionSetting;
};

export type ResultActionSetting = { condition: Condition; action?: Action };

export interface Condition {
  types?: string[];
  apps?: string[];
  links?: string[];
}

export type Action = {
  type: string;
  click?: ActionModel;
  hover?: ActionModel;
};

export type ActionModel = { primary?: ActionView; secondary?: ActionView };

export type ActionView = { type: ActionType };

export type ActionType = 'preview' | 'open' | 'summary';

export type PreviewType = 'files' | 'people' | 'mail' | 'collection-file' | 'wiki card' | 'relevant-people';

export type PreviewKey = 'f' | 'p' | 'm' | 'a' | 'b' | 'rp';

export const RESULT_ACTION_SETTING: ResultActionSettings = {
  'wiki-card': {
    condition: { types: ['Wiki Card'], apps: ['wiki'] },
    action: {
      type: 'wiki card',
      click: { primary: { type: 'preview' } },
    },
  },
  files: {
    condition: { types: ['Folder', 'File', 'Attachment'] },
    action: {
      type: 'files',
      click: { primary: { type: 'preview' } },
    },
  },
  people: {
    condition: { links: [] },
    action: {
      type: 'people',
      click: { primary: { type: 'preview' } },
    },
  },
  mail: {
    condition: { types: ['Email'] },
    action: {
      type: 'mail',
      click: { primary: { type: 'preview' } },
    },
  },
  // confluence: {
  //   condition: { apps: ['Confluence'] },
  // },
  // jira: {
  //   condition: { apps: ['Jira'] },
  // },
  slack: {
    condition: { apps: ['Slack'] },
  },
  golinks: undefined,
  collections: undefined,
  'live-collection': undefined,
  'static-collection': undefined,
  'wiki-collection': undefined,
  analytics: undefined,
  favorites: undefined,
  search: undefined,
  'collection-file': {
    condition: { types: ['collection-file'] },
    action: {
      type: 'collection-file',
      click: { primary: { type: 'preview' } },
    },
  },
};
