<div class="avatar-list" *ngIf="!labelModel; else showLabelModel">
  <div class="avatars-show">
    <div *ngFor="let avatar of model.slice(0, spreadAvatarsCount); let i = index">
      <div class="avatar" #actualTarget (mouseover)="overlayPanel.get(i).show($event, actualTarget)" (mouseleave)="onHidePanel(i)">
        <ng-container
          [ngTemplateOutlet]="!!avatar.imgUrl ? imgUrl : noUrl"
          [ngTemplateOutletContext]="{ avatar: avatar, size: sizeAvatar }"
        >
        </ng-container>
        <p-overlayPanel #op [showTransitionOptions]="'0.2s'" [hideTransitionOptions]="'0ms'">
          <div class="avatar-tooltip" data-cy="avatar-tooltip">
            <div class="avatar-tooltip-name" *ngIf="avatar.name">{{ avatar.name }}</div>
            <div class="avatar-tooltip-email" *ngIf="avatar.email" data-cy="avatar-tooltip-email">{{ avatar.email }}</div>
            <div class="avatar-tooltip-description" *ngIf="avatar.description">{{ avatar.description }}</div>
          </div>
        </p-overlayPanel>
      </div>
    </div>
  </div>
  <div class="avatars-hide" *ngIf="model.length - spreadAvatarsCount > 0">
    <u-icon
      class="fallback-icon"
      #actualTargetFallback
      *ngIf="spreadAvatarsCount === 0; else plusAvatar"
      [model]="fallbackIcon"
      (mouseover)="overlayPanel.get(overlayPanel.length - 1).show($event, actualTarget)"
      (mouseleave)="onHidePanel(overlayPanel.length - 1)"
    >
    </u-icon>
    <ng-template #plusAvatar>
      <div
        class="count-viewed"
        #actualTargetIcon
        [ngClass]="{ 'over-ten': countAll > 9, 'over-hundred': countAll > 99 }"
        (mouseover)="overlayPanel.get(overlayPanel.length - 1).show($event, actualTargetIcon)"
        (mouseleave)="onHidePanel(overlayPanel.length - 1)"
        [style]="{ width: sizeAvatarCount, height: sizeAvatarCount }"
      >
        +{{ model.length - spreadAvatarsCount }}
      </div>
    </ng-template>
    <p-overlayPanel #count [showTransitionOptions]="'0.1.5s'" [hideTransitionOptions]="'0ms'">
      <ng-container
        *ngTemplateOutlet="
          tooltipHoverInner;
          context: {
            footer:
              countAll - spreadAvatarsCount - tooltipLimit > 0
                ? '+ ' + (countAll - spreadAvatarsCount - tooltipLimit) + ' more viewers'
                : null
          }
        "
      >
      </ng-container>
    </p-overlayPanel>
  </div>
</div>

<ng-template #showLabelModel>
  <div class="label-model" #actualTarget (mouseover)="overlayPanel.get(0).show($event, actualTarget)" (mouseleave)="onHidePanel(0)">
    {{ labelModel }}
  </div>
  <p-overlayPanel #count [showTransitionOptions]="'0.1.5s'" [hideTransitionOptions]="'0ms'">
    <ng-container *ngTemplateOutlet="tooltipHoverInner"></ng-container>
  </p-overlayPanel>
</ng-template>

<ng-template #imgUrl let-avatar="avatar" let-size="size">
  <avatar [url]="avatar.imgUrl" [size]="size"></avatar>
</ng-template>
<ng-template #noUrl let-avatar="avatar">
  <account-icon [model]="avatar?.initialsImg" [size]="'18px'" [state]="'default'"></account-icon>
</ng-template>

<ng-template #tooltipHoverInner let-footer="footer">
  <avatar-list-tooltip
    [items]="model.slice(spreadAvatarsCount, tooltipLimit + spreadAvatarsCount)"
    [header]="tooltipHeader"
    [footer]="footer"
    [sizeAvatarTooltip]="sizeAvatarTooltip"
  ></avatar-list-tooltip>
</ng-template>
