import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { PrimeTemplate } from 'primeng/api';
import { AutoComplete } from 'primeng/autocomplete';

export type FilterInvoker = (event) => any[];

@Component({
  selector: 'u-auto-complete',
  templateUrl: './u-auto-complete.component.html',
  styleUrls: ['./u-auto-complete.component.scss'],
})
export class UAutoCompleteComponent implements OnInit, AfterContentInit, AfterViewInit {
  private readonly MULTIPLE_CONTAINER_CLASS = '.p-autocomplete-multiple-container';
  private readonly NO_RESULTS = 'No results';
  private _suggestionItems: any[];

  @Input() set suggestionItems(value: any[]) {
    this._suggestionItems = value;
    this.emptyState = !value?.length;
  }

  get suggestionItems(): any[] {
    return this._suggestionItems;
  }

  @Input() selectedItems: any[] = [];
  @Input() field = 'name';
  @Input() descriptionField: string;
  @Input() groupItemsField: string; //require when (group === true) for removing selected-items from suggestions list.
  @Input() placeholder: string;
  @Input() multiple? = false;
  @Input() group = false;
  @Input() createNewOption: boolean;
  @Input() displayCreateNew: boolean;
  @Input() filterInvoker: FilterInvoker;
  @Input() openListOnClick = false;
  @Input() maxChar = 'medium';
  @Input() className: string;
  @Input() width = '324px';
  @Input() appendTo: any = null;
  @Input() autoFocus: boolean;
  @Input() expandOnInputClick: boolean;
  @Input() removeSelected = true;
  @Input() displayDismissButton = true;
  @Input() disabled = false;
  @Input() viewMode: 'searchInput' | 'tag' = 'searchInput';
  @Input() closeOnChange = true;

  private _showArrow: boolean;
  query: string;
  get showArrow(): boolean {
    return this._showArrow;
  }
  @Input() set showArrow(value: boolean) {
    this._showArrow = this.arrowButton = value;
  }

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFocus: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBlur: EventEmitter<any> = new EventEmitter<any>();
  @Output() onHide: EventEmitter<any> = new EventEmitter<any>();
  @Output() inputQueryChange: EventEmitter<string> = new EventEmitter<string>();

  @ContentChildren(PrimeTemplate) templates: QueryList<any>;
  @ViewChild(AutoComplete) autoComplete: AutoComplete;

  public itemTemplate: TemplateRef<any>;
  public selectedItemTemplate: TemplateRef<any>;
  public groupTemplate: TemplateRef<any>;
  public footerTemplate: TemplateRef<any>;
  dismissButton: boolean;
  arrowButton: boolean;
  allowCreateNew: boolean;
  emptyState: boolean;
  createNewItem: any;

  suggestions: any[] = [];
  closeSuggestions = false;

  constructor(private cdr: ChangeDetectorRef) {}

  filterItems: FilterInvoker = (event): any[] => {
    let filtered: any[] = [];
    const query: string = event.query || '';

    if (this.group) {
      for (const viewItem of this.suggestionItems) {
        const filteredList = this.filter(query, viewItem.items);
        if (filteredList.length) {
          const copyItem = { ...viewItem };
          copyItem.items = filteredList;
          filtered.push(copyItem);
        }
      }
      return filtered;
    }
    filtered = this.filter(query, this.suggestionItems);
    return filtered;
  };

  ngOnInit(): void {
    if (!this.filterInvoker) this.filterInvoker = this.filterItems;
    setTimeout(() => {
      const autocompleteMultipleContainerRef = this.autoComplete.el.nativeElement.querySelector('.p-autocomplete-multiple-container');
      if (autocompleteMultipleContainerRef && autocompleteMultipleContainerRef.style) {
        autocompleteMultipleContainerRef.style.width = this.width;
      }
      if (this.className) {
        this.autoComplete.el.nativeElement.querySelector('.p-autocomplete')?.classList.add(this.className);
      }
    }, 0);
  }

  ngAfterContentInit() {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'item':
          this.itemTemplate = item.template;
          break;
        case 'selectedItem':
          this.selectedItemTemplate = item.template;
          break;
        case 'group':
          this.groupTemplate = item.template;
          break;
        case 'footer':
          this.footerTemplate = item.template;
          break;
        default:
          break;
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.autoFocus) {
      this.autoComplete.focusInput();
    }
    this.autoComplete.onInputClick = () => {
      this.autoComplete.inputClick = true;
      if (!this.multiple && this.expandOnInputClick && !this.autoComplete.overlayVisible) {
        this.openDropdown();
      }
    };
  }

  @HostListener('window:keydown.enter', ['$event']) onEnterKeydown() {
    if (!this.autoComplete.filled) {
      this.openDropdown();
    }
  }

  @HostListener('click', ['$event']) onClick(event) {
    if (this.multiple) {
      const inputContainerRef = this.autoComplete?.el?.nativeElement.querySelector(this.MULTIPLE_CONTAINER_CLASS);
      if (this.expandOnInputClick && !this.autoComplete.overlayVisible && inputContainerRef?.contains(event.target)) {
        this.openDropdown();
      }
    }

    if (!this.openListOnClick) return;

    setTimeout(() => {
      const queryValue = this.multiple
        ? this.autoComplete.el.nativeElement.querySelector('input')?.value
        : this.autoComplete.inputEL.nativeElement.value;
      this.autoComplete.search(undefined, queryValue || '');
    }, 0);
  }

  isQueryUnique() {
    return this.query !== '' && !this.suggestionItems?.find((i) => i[this.field] === this.query);
  }

  onCompleteMethod($event) {
    this.query = $event.query?.trim() ?? '';
    if (!this.multiple) {
      if (this.isQueryUnique() && ((this.selectedItems && this.selectedItems[this.field] !== this.query) || !this.selectedItems)) {
        this.allowCreateNew = true;
      }
      if (this.allowCreateNew && this.createNewItem) {
        this.createNewItem = null;
      }
    } else {
      this.allowCreateNew = this.isQueryUnique();
    }
    this.inputQueryChange.emit(this.query);
    const filtered: any[] = this.filterInvoker($event);
    this.closeSuggestions = false;
    this.suggestions = filtered;
    if (this.removeSelected && this.multiple) {
      this.removeSelectedItems();
    }
    if (!this.multiple && this.createNewOption && this.createNewItem) {
      this.suggestions.push(this.createNewItem);
      this.selectedItems = this.createNewItem;
    }

    if (!this.suggestions.length && !this.createNewOption) {
      const noResultsItem: any = {};
      noResultsItem[this.field] = this.NO_RESULTS;
      if (this.group) {
        noResultsItem[this.groupItemsField] = [];
      }
      filtered.push(noResultsItem);
    }
    this.cdr.markForCheck();
  }

  filter(query: string, list: any[]): any[] {
    let existsTag = false;
    const filtered: any[] = [];
    const readyQuery: string = (query || '').toLocaleLowerCase();
    for (const item of list || []) {
      const itemName: string = item[this.field]?.toLowerCase();
      if (itemName.startsWith(readyQuery || '')) {
        if (itemName === readyQuery) {
          existsTag = true;
        }
        filtered.push(item);
      }
    }

    if (this.createNewOption && !existsTag && !this.createNewItem) {
      const isNew: any = {
        [this.field]: query,
        isNew: true,
        hide: true,
      };
      filtered.push(isNew);
    }

    return filtered;
  }

  removeSelectedItems() {
    for (const tag of this.selectedItems || []) {
      if (!this.group) {
        const index = this.findIndex(tag, this.suggestions);
        if (index != -1) {
          this.suggestions.splice(index, 1);
        }
      }
      if (this.groupItemsField) {
        this.suggestions.forEach((suggestion, i) => {
          const listForFilter = suggestion[this.groupItemsField];
          const indexToRemove = this.findIndex(tag, listForFilter);
          if (indexToRemove !== -1) {
            listForFilter.splice(indexToRemove, 1);
            //removing the title of group if it has no items
            if (!listForFilter.length) this.suggestions.splice(i, 1);
          }
        });
      }
    }
  }

  removeCreateNew() {
    if (this.createNewOption) {
      if (this.suggestions.length > 1) {
        if (this.suggestions[this.suggestions.length - 1]?.isNew === true) {
          this.suggestions.splice(this.suggestions.length - 1, 1);
        }
      } else {
        if (this.suggestions.length === 1 && this.suggestions[0]?.isNew) {
          setTimeout(() => {
            this.autoComplete.el.nativeElement.querySelector('.p-autocomplete-item')?.classList.add('remove-item');
          }, 0);
        }
      }
    }
  }

  findIndex(item: any, list: any[] = []) {
    return list.findIndex((e) => e[this.field] === item[this.field]);
  }

  openDropdown() {
    if (this.autoComplete.overlayVisible) return;
    setTimeout(() => {
      this.autoComplete.handleDropdownClick({} as Event);
    }, 0);
  }

  onDismiss() {
    this.onChange.emit(this.multiple ? [] : null);
    this.selectedItems = [];
    this.dismissButton = false;
    this.autoComplete.inputValue = null;
    this.clearInputValue();
    this.arrowButton = this.showArrow;
    this.autoComplete.hide();
    this.cdr.markForCheck();
  }

  clearInputValue() {
    if (this.multiple || this.viewMode === 'tag') {
      this.autoComplete.multiInputEl.nativeElement.value = '';
    } else {
      this.autoComplete.inputEL.nativeElement.value = '';
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeydown($event: KeyboardEvent) {
    if (this.autoComplete.overlayVisible || this.autoComplete.inputKeyDown) {
      if (['Tab', 'Escape', 'Enter', 'ArrowDown', 'ArrowUp'].includes($event.code)) {
        event.stopPropagation();
      }
    }
  }

  onChangeEvent(event: any) {
    const selected = this.multiple || (this.viewMode === 'tag' && !event?.isNew) ? event[event.length - 1] : event;

    if (selected?.disable) {
      if (this.viewMode === 'searchInput') {
        this.selectedItems = null;
        this.autoComplete.inputValue = null;
        this.clearInputValue();
        this.autoComplete.cd.markForCheck();
        return;
      } else {
        this.selectedItems.pop();
        const input = this.autoComplete.el.nativeElement.querySelector('input');
        const query = typeof this.query === 'string' ? this.query : this.query['query'] ?? '';
        input?.setRangeText(query);
        input?.setSelectionRange(query?.length, query?.length);
        return;
      }
    }
    if (Object.prototype.hasOwnProperty.call(this.multiple && event.length > 0 ? event[event.length - 1] : event, this.groupItemsField)) {
      this.selectedItems.pop();
    }

    this.onChange.emit(this.viewMode === 'tag' ? selected : event);
    this.dismissButton = event && event.length === 0 ? false : !!event;
    if (this.showArrow) {
      this.arrowButton = !this.dismissButton;
    }
    if (this.closeOnChange) {
      this.autoComplete.hide();
    }
    this.cdr.markForCheck();
  }

  onFocusAutoComplete($event) {
    this.onFocus.emit($event);
  }

  onBlurAutoComplete(event) {
    this.onBlur.emit(event);
  }

  onHideAutoComplete(event) {
    this.onHide.emit(event);
  }

  createNew() {
    if (this.emptyState && !this.query) {
      return;
    }
    const newItem: any = {
      [this.field]: this.query,
      isNew: true,
    };
    if (!this.multiple) {
      this.onChangeEvent(newItem);
      this.createNewItem = newItem;
      if (!this.openListOnClick) {
        this.suggestions.push(this.createNewItem);
        this.selectedItems = this.createNewItem;
      }
    } else {
      this.onChangeEvent([newItem]);
    }
    this.query = '';
    this.emptyState = false;
    this.autoComplete.inputValue = null;
    this.clearInputValue();
    this.autoComplete.cd.markForCheck();
    this.cdr.markForCheck();
  }
}
