<div class="quick-links-popup-container">
  <ng-container *ngIf="quickLinks && !state">
    <div class="quick-links-header">
      <div class="quick-links-title">Quick Links</div>
      <u-icon
        class="quick-links-add-icon"
        data-cy="quick-links-add-icon"
        (click)="createQuickLink()"
        [model]="{ type: 'font', value: 'icon-plus' }"
        pTooltip="Create New"
        tooltipPosition="top"
        tooltipStyleClass="u-tooltip"
      >
      </u-icon>
    </div>
    <div class="quick-links-content">
      <div
        *ngFor="let quickLink of quickLinks; index as i"
        class="quick-link"
        [ngClass]="{ focus: focusIndex === i }"
        (click)="openUrl(quickLink, 'context_menu_click')"
        (auxclick)="openContextMenu($event, quickLink, i)"
      >
        <u-icon
          class="icon"
          *ngIf="quickLink.imageUrl; else defaultIcon"
          [model]="{ type: 'img', value: { lightUrl: quickLink.imageUrl } }"
          [fallback]="DEFAULT_ICON"
        ></u-icon>
        <ng-template #defaultIcon>
          <u-icon class="default-icon" [model]="{ type: 'font', value: 'icon-link2' }"></u-icon>
        </ng-template>
        <div class="name" tooltipPosition="bottom" [escape]="false" [pTooltip]="getTooltip(quickLink)" tooltipStyleClass="u-tooltip">
          {{ quickLink.name }}
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="state === 'empty'">
    <div class="quick-links-empty-state" data-cy="quick-links-empty-state">
      <div class="title">Quick Links</div>
      <div class="message">Add links for your colleagues to quickly access important apps and pages</div>
      <u-button [label]="'New Link'" [styles]="{ width: '94px' }" type="primary" (click)="createQuickLink()"></u-button>
    </div>
  </ng-container>

  <ng-container *ngIf="state === 'loading'">
    <loader-overlay class="quick-links-loading"> </loader-overlay>
  </ng-container>
</div>
