<div [class]="'assistant-container ' + type">
  <ng-container>
    <u-tab-view
      [tabsStatic]="assistantTabs"
      (onChangeActiveTab)="setActiveTab($event.tab)"
      [selectedTabId]="selectedTab.id"
      [openLeftMenuBar]="true"
      id="assistant-tabs"
    ></u-tab-view>
  </ng-container>

  <ng-container [ngSwitch]="selectedTab?.id">
    {{ track() }}
    <ng-container *ngSwitchCase="'summary'">
      <ng-container *ngTemplateOutlet="summary"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'recommendations'">
      <ng-container *ngTemplateOutlet="recommendations; context: { tab: selectedTab }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'knowledge'">
      <ng-container *ngTemplateOutlet="recommendations; context: { tab: selectedTab }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'tickets'">
      <ng-container *ngTemplateOutlet="recommendations; context: { tab: selectedTab }"></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #summary>
  <div class="ticket-summary-tab">
    <ng-container *ngIf="ticketSummaryState === 'Loading'">
      <result-ghost class="summary-popup-loading" [showAvatar]="false" [ghostingLine]="7"></result-ghost>
    </ng-container>
    <app-empty-state
      class="ticket-summary-empty-state"
      *ngIf="ticketSummaryState === 'Empty'"
      [title]="emptySummaryText"
      [svgUrl]="'./assets/bar/calendar/empty-state.svg'"
    ></app-empty-state>
    <div class="ticket-summary" *ngIf="ticketSummaryState === 'Completed'">
      <div class="ticket-summary-text">
        <ng-container *ngTemplateOutlet="summaryTextContent; context: { textContent: ticketSummary.data }"></ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #summaryTextContent let-textContent="textContent">
  <div class="text-context" [innerHTML]="textContent.summary | richTextFormatted"></div>
  <div class="text-context">
    <ng-container *ngIf="textContent.customerSentiment">
      <div class="title">Customer Sentiment:</div>
      {{ textContent.customerSentiment }}
    </ng-container>
  </div>
  <ng-container *ngIf="textContent.keyDetails?.length">
    <div class="title">Key Details:</div>
    <div *ngFor="let detail of textContent.keyDetails">
      <span>{{ detail }} </span>
    </div>
  </ng-container>
</ng-template>

<ng-template #recommendations let-tab="tab">
  <div class="ticket-recommendations-tab">
    <div class="assistant-result-container">
      <div class="no-results" *ngIf="tab.emptyResults !== false && !loading; else resultsList">
        <ng-container *ngIf="tab.id === 'recommendations'; else resourcesEmptyState">
          <span *ngIf="isForceButtonClicked" class="no-results-title">{{ ANSWER_EMPTY_MESSAGE }}</span>
          <u-button
            class="show-resolution-button"
            label="Show resolution"
            type="secondary"
            fontIcon="answer"
            [styles]="{ width: '224px', height: '40px' }"
            (click)="refreshIgnoreCache(true)"
          >
          </u-button>
        </ng-container>
        <ng-template #resourcesEmptyState>
          <span class="no-results-title">{{ emptyResourcesText }}</span>
          <u-button
            class="refresh-button"
            label="Refresh"
            type="secondary-light"
            fontIcon="upgrade"
            (click)="refreshIgnoreCache(tab.id === 'tickets')"
          ></u-button>
        </ng-template>
      </div>
      <ng-template #resultsList>
        <search-results-list
          [loading]="loading && tab.name !== 'Resolution'"
          [isLoadingAnswer]="loading && tab.name === 'Resolution'"
          [disableAnswerTyping]="disableAnswerTyping"
          [displayedContext]="displayedContext"
          [items]="!loading ? tab.items : []"
          [listName]="'assistant-incontext'"
          pageType="assistant-incontext"
          [searchId]="searchId"
          [layoutMode]="list"
          [defaultResultSections]="{
            showSubtitleInSecondLine: true,
            showFavoriteAction: false,
            showAddToCollectionAction: false,
            showFeedback: true,
            showFavoriteContextMenuItem: false,
            showAddToCollectionContextMenuItem: false
          }"
          [showSearchResults]="false"
          [listView]="{ hideFollowUp: true }"
        >
        </search-results-list>
      </ng-template>
    </div>
  </div>
</ng-template>

<error-overlay *ngIf="error" [displayText]="errorMessage" (again)="tryAgain()" (back)="back()"> </error-overlay>
