import { Config } from '@environments/config';
import { resultHeaderCountKey } from '@shared/utils/header-builder.util';
import {
  CalculatorSourceSettings,
  GoLinksSourceSettings,
  GoToSourceSettings,
  LinkResourcesSourceSettings,
  LocalActionSourceSettings,
  RecentSearchesSourceSettings,
  SourceSettings,
  WebSourceSettings,
} from '../../services/search/client';
import { BrowserHistorySourceSettings } from '../../services/search/client/browser-history';
import { BrowserTabSourceSettings } from '../../services/search/client/browser-tabs';
import { CollectionsSourceSettings } from '../../services/search/client/collections';
import { FavoritesSourceSettings } from '../../services/search/client/favorites';
import { PeopleSourceSettings } from '../../services/search/client/people/people-source-settings';
import { QueriesSourceSettings } from '../../services/search/client/queries/queries-source-settings';
import { QuestionnaireSourceSettings } from '../../services/search/client/questionnaire';
import { ResourcesFavoritesSourceSettings } from '../../services/search/client/resources-favorites/resources-favorites-source-settings';
import { SuggestionsSourceSettings } from '../../services/search/client/suggestions';
import { VisitsSourceSettings } from '../../services/search/client/visits';
import { WikiCollectionItemSourceSettings } from '../../services/search/client/wiki-collection-items';

export class HomeSearchSettings {
  public static readonly TOTAL_COUNT = 5;
  private static readonly RECENT_SEARCH_COUNT = 3;
  private static readonly CLOUD_SEARCH_COUNT = 7;
  private static readonly LOCAL_SEARCH_CLOUD = 3;
  private static readonly GO_LINKS_COUNT = 15;

  private static readonly calculator: CalculatorSourceSettings = {
    type: 'calculator',
    minQueryLength: 3,
  };

  public static readonly linkResources: LinkResourcesSourceSettings = {
    id: 'link-resources',
    type: 'link-resources',
    debounce: Config.search.app.debounce,
    requestMaxCount: HomeSearchSettings.CLOUD_SEARCH_COUNT,
    cloudMaxCount: HomeSearchSettings.CLOUD_SEARCH_COUNT,
    localMaxCount: HomeSearchSettings.LOCAL_SEARCH_CLOUD,
    // noHeader: true,
    header: {
      title: 'Results',
      titleEnd: resultHeaderCountKey,
    },
    disableCloudGroup: true,
    groupsFirst: true,
    sorting: {
      by: 'Timestamp',
      order: 'Descending',
      type: 'Time',
    },
    ignoreDateHeaders: true,
    shortSnippet: true,
    forceFooter: true,
    showGroupHeader: true,
    disableAggregations: true,
    filters: { excludeFilters: ['Wiki', 'SlackMessage', 'PeopleLink'] },
  };

  private static readonly goto: GoToSourceSettings = {
    type: 'goto',
    maxCount: HomeSearchSettings.TOTAL_COUNT,
    showHeaderButton: true,
    forceFooter: true,
    header: {
      title: 'Go to',
      titleEnd: resultHeaderCountKey,
      clickable: true,
      group: {
        name: 'goto',
        title: 'Go To',
      },
    },
  };

  private static readonly goLinksFavorites: GoLinksSourceSettings = {
    type: 'go-links',
    useMemSearch: true,
    withVisits: false,
    filters: { postFilters: { favorite: ['true'] } },
  };

  private static readonly collectionsFavorites: CollectionsSourceSettings = {
    type: 'collection',
    filters: { postFilters: { favorite: ['true'] } },
  };

  private static readonly resourcesFavorites: ResourcesFavoritesSourceSettings = {
    type: 'resources-favorites',
    cache: 'only',
    filters: { excludeFilters: ['SlackMessage'] },
  };

  private static readonly favorites: FavoritesSourceSettings = {
    type: 'favorites',
    maxCount: HomeSearchSettings.TOTAL_COUNT,
    forceFooter: true,
    header: {
      title: 'Favorites',
      titleEnd: resultHeaderCountKey,
      clickable: true,
      group: { name: 'favorites', title: 'Favorites', type: 'active-page', value: 'favorites' },
    },
    sources: {
      goLinks: HomeSearchSettings.goLinksFavorites,
      collections: HomeSearchSettings.collectionsFavorites,
      resources: HomeSearchSettings.resourcesFavorites,
    },
  };

  private static readonly collections: CollectionsSourceSettings = {
    id: 'collections',
    type: 'collection',
    maxCount: HomeSearchSettings.TOTAL_COUNT,
    showHeaderButton: true,
    forceFooter: true,
    header: {
      title: 'Collections',
      titleEnd: resultHeaderCountKey,
      clickable: true,
      group: {
        name: 'collections',
        title: 'Collections',
        type: 'active-page',
        value: 'collections',
      },
    },
    filters: {
      preFilters: {
        'collection-type': ['Static', 'Live'],
      },
    },
    showGroupHeader: true,
  };

  private static readonly wikis: CollectionsSourceSettings = {
    id: 'wikis',
    type: 'collection',
    maxCount: HomeSearchSettings.TOTAL_COUNT,
    showGroupHeader: true,
    forceFooter: true,
    header: {
      title: 'Wikis',
      titleEnd: resultHeaderCountKey,
      clickable: true,
      group: {
        name: 'wikis',
        title: 'Wikis',
        type: 'active-page',
        value: 'wikis',
      },
    },
    filters: {
      preFilters: {
        'collection-type': ['Wiki'],
      },
    },
  };

  private static readonly localActions: LocalActionSourceSettings = {
    type: 'local-action',
    // noHeader: true,
    header: {
      title: 'Quick Actions',
      titleEnd: resultHeaderCountKey,
      clickable: true,
      group: {
        name: 'actions',
        title: 'Quick Actions',
      },
    },
    forceFooter: true,
    withAppActions: true,
    maxCount: HomeSearchSettings.TOTAL_COUNT,
    withGoLinksAction: true,
    filters: { excludeFilters: ['SlackMessage'] },
  };

  private static readonly queries: QueriesSourceSettings = {
    type: 'queries',
    maxCount: HomeSearchSettings.TOTAL_COUNT,
  };

  private static readonly browserhistory: BrowserHistorySourceSettings = {
    type: 'browser-history',
    maxCount: HomeSearchSettings.TOTAL_COUNT,
  };

  private static readonly webSearch: WebSourceSettings = {
    type: 'web-search',
    maxCount: HomeSearchSettings.TOTAL_COUNT,
  };

  private static readonly goLinks: GoLinksSourceSettings = {
    type: 'go-links',
    maxCount: HomeSearchSettings.TOTAL_COUNT,
    title: 'Go Links',
    withVisits: false,
    showHeaderButton: true,
    forceFooter: true,
    header: {
      title: 'Go links',
      titleEnd: resultHeaderCountKey,
      clickable: true,
      group: {
        name: 'golinks',
        title: 'Go Links',
        type: 'active-page',
        value: 'golinks',
      },
    },
  };

  private static readonly goLinksGroup: GoLinksSourceSettings = {
    ...HomeSearchSettings.goLinks,
    maxCount: HomeSearchSettings.GO_LINKS_COUNT,
    noHeader: true,
  };

  private static readonly people: PeopleSourceSettings = {
    type: 'people',
    maxCount: HomeSearchSettings.TOTAL_COUNT,
    minQueryLength: 0,
    forceFooter: true,
    header: {
      title: 'People',
      titleEnd: resultHeaderCountKey,
      clickable: true,
      group: { name: 'people', title: 'People', type: 'active-page', value: 'people' },
    },
    viewMode: 'line',
  };

  private static readonly browserTabs: BrowserTabSourceSettings = {
    type: 'browser-tabs',
    maxCount: HomeSearchSettings.TOTAL_COUNT,
    forceFooter: true,
    header: {
      title: 'Open tabs',
      titleEnd: resultHeaderCountKey,
      clickable: true,
      selectable: false,
      group: {
        name: 'browserTabs',
        title: 'Open Tabs',
      },
    },
  };

  private static readonly visits: VisitsSourceSettings = {
    type: 'visits',
    cache: 'only',
    includeInteractions: 'cache',
    includeTraits: 'cache',
    defaultSort: 'frequent-first',
    displayOneLine: true,
    forceFooter: true,
    maxCount: HomeSearchSettings.TOTAL_COUNT,
    header: {
      title: 'Recently viewed',
      titleEnd: resultHeaderCountKey,
      clickable: true,
      group: { name: 'visits', title: 'Recently Viewed' },
    },
    disableSnippet: true,
    filters: { excludeFilters: ['SlackMessage', 'PeopleLink'] },
  };

  private static readonly suggestions: SuggestionsSourceSettings = {
    type: 'suggestions',
    header: { title: 'Suggestions' },
    noFooter: true,
  };

  private static readonly recentSearch: RecentSearchesSourceSettings = {
    type: 'recent-search',
    maxCount: HomeSearchSettings.RECENT_SEARCH_COUNT,
    header: {
      title: 'Recent searches',
    },
  };

  private static readonly cards: WikiCollectionItemSourceSettings = {
    type: 'wiki-collection-items',
    withIcon: true,
    showHeaderButton: true,
    forceFooter: true,
    maxCount: HomeSearchSettings.TOTAL_COUNT,
    cache: 'only',
    header: {
      title: 'Cards',
      titleEnd: resultHeaderCountKey,
      clickable: true,
      group: {
        name: 'cards',
        title: 'Cards',
        type: 'active-page',
        value: 'cards',
      },
    },
    searchView: true,
  };

  private static readonly questionnaire: QuestionnaireSourceSettings = {
    type: 'fill-questionnaire',
  };

  static settings: { default: SourceSettings[]; search: SourceSettings[]; [view: string]: SourceSettings[] } = {
    default: [HomeSearchSettings.recentSearch, HomeSearchSettings.suggestions],
    search: [
      // TODO: add those sources back once new homepage is ready
      HomeSearchSettings.questionnaire,
      HomeSearchSettings.people,
      HomeSearchSettings.goto,
      HomeSearchSettings.localActions,
      HomeSearchSettings.favorites,
      // HomeSearchSettings.queries,
      HomeSearchSettings.goLinks,
      HomeSearchSettings.collections,
      HomeSearchSettings.wikis,
      HomeSearchSettings.cards,
      HomeSearchSettings.visits,
      HomeSearchSettings.linkResources,
    ],
    goLinks: [HomeSearchSettings.goLinksGroup],
  };
}
