import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Assistants, Chats, Filters, Search } from '@local/client-contracts';
import { observable } from '@local/common';
import { generateId, isEmbed } from '@local/common-web';
import { EmbedService } from '@shared/embed.service';
import { LogService, ServicesRpcService, WindowService } from '@shared/services';
import { RouterService } from '@shared/services/router.service';
import { Logger } from '@unleash-tech/js-logger';
import { isEmpty, isEqual, pickBy } from 'lodash';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest } from 'rxjs';
import { FiltersService } from 'src/app/bar/services/filters.service';
import { HubService } from 'src/app/bar/services/hub.service';
import { ChatsRpcInvoker } from 'src/app/bar/services/invokers/chats.rpc-invoker';
import { CHAT_PAGE_PATH } from 'src/app/bar/utils/constants';
import { AnswerSearchItem } from '../../results';
import { ChatResourcesService } from './chat-resources.service';
@Injectable()
export class ChatsService {
  private logger: Logger;
  private service: Chats.Service;
  private _all$ = new ReplaySubject<Chats.Chat[]>(1);
  private _currentChat$ = new BehaviorSubject<Chats.Chat>(null);
  private isEmbed = isEmbed();

  @observable
  get currentChat$(): BehaviorSubject<Chats.Chat> {
    return this._currentChat$;
  }

  private set currentChat(assistant: Chats.Chat) {
    this._currentChat$.next(assistant);
  }

  private get currentChat(): Chats.Chat {
    return this._currentChat$.value;
  }

  constructor(
    services: ServicesRpcService,
    logger: LogService,
    private routerService: RouterService,
    private windowService: WindowService,
    private filtersService: FiltersService,
    private hubService: HubService,
    private embedService: EmbedService,
    private chatResourcesService: ChatResourcesService
  ) {
    this.logger = logger.scope('ChatsService');
    this.service = services.invokeWith(ChatsRpcInvoker, 'chats');
    combineLatest([this.all$, this.routerService.activeRoute$]).subscribe(([chats, currentRoute]) => {
      if (this.resetCurrentChat(currentRoute)) {
        return;
      }
      const chatId = currentRoute?.snapshot?.params?.id;
      if (!chatId) {
        const id = 'new';
        const emptyChat: Chats.Chat = {
          id,
          chatHistory: [],
        };
        this.currentChat = emptyChat;
        return;
      }
      const updatedChat = chats.find((b) => b.id === chatId);
      if (updatedChat) {
        const chatChanged = this.currentChat?.id !== updatedChat?.id;
        if (chatChanged) {
          this.currentChat = { ...updatedChat };
          this.updateAnswersResources();
        }
      }
    });

    this.service.all$.subscribe((all) => this._all$.next(all));
  }

  @observable
  get all$(): Observable<Chats.Chat[]> {
    return this._all$.asObservable();
  }

  create(chat: Chats.Chat): Promise<void> {
    return this.service.create(chat);
  }

  update(id: string, actions: Chats.UpdateAction[]): Promise<void> {
    return this.service.update(id, actions);
  }

  refresh(): Promise<void> {
    return this.service.refresh();
  }

  isEqual(originalItem: Chats.Chat, updateItem: Chats.Chat): Partial<Chats.Chat> {
    return pickBy(updateItem, (v, k) => !isEqual(originalItem[k], v));
  }
  async createTempChatSession(query: string, filtersToQuestion: Filters.Values) {
    const id = generateId();
    const chatHistory: Chats.ChatHistory = {
      question: {
        query,
        filters: filtersToQuestion,
      },
      createdTime: Date.now(),
    };
    const chat: Chats.Chat = {
      id,
      chatHistory: [chatHistory],
    };
    this.currentChat = chat;
    await this.openChat(id);
  }

  async goToChatPage(answerItem: AnswerSearchItem) {
    const { state, text, formattedAnswer, query, searchId, resources, intent } = answerItem;
    const answer: Chats.ChatAnswerMessage = this.createAnswer(state, text, formattedAnswer, resources, intent);
    const currentFilters: Filters.Values = this.filtersService.allFilters;
    const filtersToQuestion: Filters.Values = await this.filtersService.transformFiltersForDisplay(currentFilters);
    this.createChatSession(query, filtersToQuestion, answer, null, searchId, true);
    this.chatResourcesService.updateResourceInCache(resources);
  }
  async createChatSession(
    query: string,
    filtersToQuestion: Filters.Values,
    answer?: Chats.ChatAnswerMessage,
    id?: string,
    searchId?: string,
    isTempSession = false
  ) {
    if (!isTempSession && !id) {
      this.logger.error('failed to create chat session. ID is required for non-temporary chat sessions');
    }
    const chatId = isTempSession || !id ? generateId() : id;
    const chatHistory: Chats.ChatHistory = {
      question: {
        query,
        filters: filtersToQuestion,
      },
      createdTime: Date.now(),
      ...(answer && { answer }),
      ...(searchId && { searchId }),
    };
    const chat: Chats.Chat = {
      id: chatId,
      chatHistory: [chatHistory],
    };
    if (isTempSession) {
      this.currentChat = chat;
      await this.openChat(chatId);
    }
    this.create(chat);
  }

  convertToAnswerResources(resources: Search.ResultResourceItem[]): Chats.ChatAnswerResource[] {
    const updatedResources: Chats.ChatAnswerResource[] = (resources || []).map((r) => ({
      appId: r.resource?.appId,
      externalId: r.resource?.externalId,
    }));
    return updatedResources;
  }

  createAnswer(
    answerState: Assistants.AnswerStatusType,
    answerText: string,
    formattedAnswer: string,
    resources: Search.ResultResourceItem[],
    intent?: Chats.AnswerIntent
  ): Chats.ChatAnswerMessage {
    if (answerState === 'NoResults') {
      return { state: Chats.ChatAnswerState.NoResultsFound };
    }
    const updatedResources = this.convertToAnswerResources(resources);
    const answer: Partial<Chats.ChatAnswerMessage> = {
      content: { text: answerText, formattedAnswer },
      state: answerState === 'RephraseRequired' ? Chats.ChatAnswerState.RephraseRequired : Chats.ChatAnswerState.ResultsAvailable,
      resources: updatedResources,
    };

    if (intent) {
      answer.intent = intent;
    }
    return answer as Chats.ChatAnswerMessage;
  }
  async openChat(chatId?: string) {
    let chatUrl = `/${CHAT_PAGE_PATH}`;
    if (chatId) {
      chatUrl += `/${chatId}`;
    }
    const currentFilters = this.filtersService.allFilters;
    if (!isEmpty(currentFilters)) {
      const filtersUrl = this.filtersService.getFiltersAsUrlParams(currentFilters);
      chatUrl += `?${filtersUrl}`;
    }
    const isLauncher = await this.hubService.getIsLauncher();
    if (this.isEmbed) {
      const isExternalWebSite = await this.embedService.isExternalWebSite();
      if (isExternalWebSite) {
        if (isLauncher) {
          return this.hubService.openStandardEmbed(chatUrl, true);
        }
        return this.routerService.navigateByUrl(chatUrl, { replaceUrl: true });
      }
      return this.embedService.openUrl(chatUrl);
    }
    if (isLauncher) {
      return this.windowService.switchToStandard(chatUrl);
    }
    return this.routerService.navigateByUrl(chatUrl);
  }

  private updateAnswersResources() {
    const externalResources = (this.currentChat.chatHistory || [])
      .map((h) => h?.answer?.resources?.map((r) => r?.externalId) || [])
      .filter((r) => !!r)
      .flat();
    this.chatResourcesService.updateRemoteResources(externalResources);
  }
  private resetCurrentChat(currentRoute: ActivatedRoute): boolean {
    const currentPage = currentRoute?.snapshot?.routeConfig.path;
    if (currentPage !== CHAT_PAGE_PATH && currentPage !== `${CHAT_PAGE_PATH}/:id`) {
      this.currentChat = null;
      return true;
    }
    return false;
  }
}
