<div
  (click)="previewHandlerService.checkPreviewVisibility($event); $event.stopPropagation()"
  class="results-wrapper search-results-list-wrapper"
  [class.preview-mode]="isPreviewMode"
>
  <!-- TODO: transfer this "div" to live collection || unite with static and wiki -->
  <div
    class="search-results-header"
    *ngIf="((items?.length > 0 && items[0]?.type === 'header') || searchModel?.length > 0) && showSearchResults"
    [class.have-preview-btn]="previewHandlerService.displayTogglePreview"
  >
    <result-header
      #resultItem
      class="result-item"
      [model]="emptyResults && searchModel?.length > 0 ? { title: 'Showing 0 results' } : items[0]"
      [class.preview-header-mode]="previewHandlerService.previewViewModel.previewState === 'side'"
      [index]="0"
      [attr.data-index]="0"
      [showSort]="true"
      [view]="'live-collection'"
    ></result-header>
    <search-expandable-button [size]="'large'" (onChange)="formInputChanged.next($event)"></search-expandable-button>
    <u-button
      (click)="changeLayout()"
      type="secondary"
      class="icon-button toggle-preview toggle-layout"
      elementSize="small"
      [onlyIcon]="true"
      [pTooltip]="layoutMode === 'list' ? 'Gallery layout' : 'List layout'"
      tooltipPosition="top"
      tooltipStyleClass="u-tooltip"
      ><u-icon
        [model]="{ type: 'font', value: layoutMode === 'list' ? 'icon-gallery-view' : 'icon-list-view' }"
        [styles]="{ fontSize: '17px', display: 'flex' }"
      ></u-icon>
    </u-button>
    <u-button
      *ngIf="previewHandlerService.displayTogglePreview"
      (click)="previewHandlerService.toggleResultsPreview(searchId, 'mouse_click'); updateResultsWidthAndGridColumns()"
      type="secondary"
      class="icon-button toggle-preview"
      [onlyIcon]="true"
      [ngClass]="{
        active: previewHandlerService.previewViewModel.previewState === 'side',
        focused: previewHandlerService.componentFocused?.compId === previewHandlerService.PreviewFocusEnum.PreviewToggle
      }"
      [pTooltip]="previewHandlerService.getTogglePreviewTooltip()"
      tooltipPosition="left"
      tooltipStyleClass="u-tooltip"
    >
      <u-icon [model]="{ type: 'font', value: 'icon-toggle-preview-light' }" [styles]="{ fontSize: '32px' }"></u-icon>
    </u-button>
  </div>
  <preview-results
    [resultsTemplate]="resultsTemplate"
    [smallView]="smallView"
    [scrollHeight]="scrollHeight"
    [displayedContext]="displayedContext"
    [searchId]="searchId"
    [previewHeight]="previewHeight"
    [selectedIndex]="selectedIndex"
    [isPreviewMode]="isPreviewMode"
    [previewWidth]="previewHandlerService.previewViewModel.previewWidth"
    [maxPreviewWidth]="previewHandlerService.splitAreaPreviewModel.maxPreviewWidth"
    [minPreviewWidth]="previewHandlerService.splitAreaPreviewModel.minPreviewWidth"
    [visiblePreview]="previewHandlerService.splitAreaPreviewModel.visiblePreview"
    [visibleResults]="previewHandlerService.splitAreaPreviewModel.visibleResults"
    [hiddenPreview]="hiddenPreview"
    [previewType]="previewHandlerService.selected?.action?.type"
    [previewViewModel]="previewHandlerService.previewViewModel"
    [selected]="previewHandlerService.selected$ | async"
    (invoke)="onInvoke($event, 'result')"
    (invokeDirty)="onInvokeDirty($event)"
    (previewDragEnd)="previewHandlerService.previewDragEnd($event); updateResultsWidthAndGridColumns()"
    (sizeChanged)="previewHandlerService.updatePreviewState($event)"
  ></preview-results>
</div>

<ng-template #ghost>
  <div *ngFor="let ghost of ghostItems">
    <result-ghost
      [ngClass]="{ 'loading-result-ghost': !supportedInGalleryView }"
      [ghostingLine]="3"
      [galleryView]="supportedInGalleryView"
      [class.gallery]="supportedInGalleryView"
    ></result-ghost>
  </div>
</ng-template>

<ng-template #resultsTemplate>
  <div class="drop-group" cdkDropListGroup>
    <div #placeholder cdkDropList (cdkDropListEntered)="onDropListEntered($event)" (cdkDropListDropped)="onDropListDropped()"></div>
    <div
      #resultsSplitArea
      #contextContainer
      class="stop-boundary scrollbar-wrapper results-container"
      [ngClass]="{ border: previewHandlerService.previewViewModel.previewState === 'side' }"
    >
      <ng-scrollbar [autoHeightDisabled]="true" class="infinite-scroll" visibility="hover">
        <div
          class="search-results"
          scrollViewport
          infiniteScroll
          [infiniteScrollDistance]="1"
          [infiniteScrollThrottle]="0"
          [scrollWindow]="false"
          (scroll)="onScrollStart($event)"
          (scrolled)="updateResultsLimit()"
        >
          <div class="infinite-scroll-container">
            <ng-container *ngFor="let item of items | slice : 0 : resultsLimit; index as i; let first = first; trackBy: itemIdTrack">
              <div *ngIf="ghostItems?.length && loadingItem?.position === i">
                <ng-container *ngTemplateOutlet="ghost"></ng-container>
              </div>

              <ng-container [ngSwitch]="item?.type">
                <ng-container *ngIf="!first || !showSearchResults">
                  <div
                    #resultWrapper
                    *ngSwitchCase="'header'"
                    [ngClass]="{
                      'header-in-gallery': supportedInGalleryView
                    }"
                  >
                    <result-header
                      #resultItem
                      class="result-item"
                      [selected]="selectedIndex === i && item.selectable"
                      [model]="item"
                      [index]="i"
                      [attr.data-index]="i"
                      [showSort]="false"
                      [isCollectionHeader]="false"
                      (focusGroupClick)="resultHeaderClick.emit(item)"
                    ></result-header>
                  </div>
                </ng-container>
                <div
                  #resultWrapper
                  *ngIf="item?.type !== 'header'"
                  cdkDropList
                  (cdkDropListEntered)="onDropListEntered($event)"
                  (cdkDropListDropped)="onDropListDropped()"
                  [ngClass]="{
                    'header-in-gallery': supportedInGalleryView && item?.type === 'collection-header'
                  }"
                >
                  <div
                    [ngClass]="{
                      'result-item-container': listView.isEditable || listView.isDraggable,
                      'result-in-gallery': supportedInGalleryView && item?.type !== 'collection-header',
                      'search-result-item': supportedInListView
                    }"
                    [style.margin-top]="supportedInListView && item?.type === 'collection-header' ? '8px' : '2px'"
                    cdkDrag
                    cdkDragBoundary=".stop-boundary"
                    [cdkDragDisabled]="!listView.isDraggable"
                    (cdkDragStarted)="dragInprogress = true"
                    (cdkDragEnded)="dragInprogress = false"
                    [cdkDragData]="item?.id"
                  >
                    <u-checkbox
                      *ngIf="listView.isEditable && !isSearchInProgress && (supportedInListView || item?.type === 'collection-header')"
                      class="checkbox-edit"
                      [class.show-checkbox]="resultListOptions?.checkboxAlwaysVisible"
                      [checked]="checkedItems.has(item.id)"
                      [class.selected]="checkedItems.has(item.id)"
                      [isNoColor]="checkboxIsNoColor"
                      [binary]="true"
                      (onChange)="toggleChecked($event, item.id)"
                    ></u-checkbox>
                    <u-button
                      cdkDragHandle
                      *ngIf="listView.isDraggable && (supportedInListView || item?.type === 'collection-header')"
                      class="icon-button-clean dragging-button drag-handler"
                      fontIcon="dragging-ind"
                      type="secondary"
                      [styles]="{ padding: '0', width: '12px', height: '12px', fontSize: '12px' }"
                      pTooltip="Drag to move 
                      Click to open menu"
                      tooltipPosition="bottom"
                      tooltipStyleClass="u-tooltip"
                      (click)="openContextMenuFromDrag({ event: $event, source: 'actions', itemId: item.id })"
                      [class.show]="openedDragContextMenuId === item.id"
                    >
                    </u-button>
                    <ng-container *ngSwitchCase="'result'">
                      <result-item
                        #resultItem
                        [style.background-color]="displayBg ? 'var(--color-bg)' : 'transparent'"
                        [class.disabled]="item.deleted"
                        [class.selected]="isSelected(i)"
                        [class.hovered]="!dragInprogress && hoveredIndex === i"
                        [class.keyboardOn]="keyboardOn"
                        [class.preview-mode]="previewHandlerService.previewViewModel.previewState === 'side'"
                        [class.summary]="onOpenSummaryPopup"
                        [class.isCard]="isCard(item)"
                        [previewState]="previewHandlerService.previewViewModel.previewState"
                        [previewable]="isPreviewClickAction(item.action)"
                        [sessionId]="displayedContext.sessionId"
                        [summaryResourceId]="item.resourceId"
                        [clientSearchId]="displayedContext.clientSearchId"
                        [contextMenu]="contextMenu"
                        [selected]="selectedIndex === i"
                        tabindex="0"
                        [index]="i"
                        [resultsIndex]="item.resultIndex"
                        class="result-item"
                        (invoke)="updateSelectedIndex(i, $event); onInvoke($event, 'result')"
                        (click)="updateSelectedIndex(i)"
                        [attr.data-index]="i"
                        [listName]="listName"
                        [model]="item"
                        (contextOpen)="openContextMenu(i, item)"
                        (contextClose)="closeContextMenu()"
                        (mouseover)="hoveredIndex = i"
                        (mouseout)="!onOpenContextMenu && !onOpenSummaryPopup ? (hoveredIndex = null) : null"
                        [hovered]="hoveredIndex === i"
                        (titleChanged)="titleChangedSub.next({$event,item})"
                        [pageType]="pageType"
                        [showResultSections]="getShowResultSections(item) || { calcBullets: !!item.resource }"
                        [resultsWidth]="resultsRef?.nativeElement?.offsetWidth"
                        [layoutMode]="layoutMode"
                        [checked]="checkedItems.has(item.id)"
                        (changeSelection)="toggleChecked($event, item.id)"
                      >
                        <div *ngIf="supportedInGalleryView" cdkDragHandle class="drag-ind"></div>
                      </result-item>
                    </ng-container>
                    <ng-container *ngSwitchCase="'go-link'">
                      <go-links-view-item
                        [style.background-color]="displayBg ? 'var(--color-bg)' : 'transparent'"
                        #resultItem
                        class="result-item"
                        [class.disabled]="item.deleted"
                        [class.selected]="isSelected(i)"
                        [class.hovered]="!dragInprogress && hoveredIndex === i"
                        [class.preview-mode]="previewHandlerService.previewViewModel.previewState === 'side'"
                        [previewState]="previewHandlerService.previewViewModel.previewState"
                        [sessionId]="displayedContext.sessionId"
                        [clientSearchId]="displayedContext.clientSearchId"
                        [selected]="selectedIndex === i"
                        [model]="item"
                        [visit]="item.visit"
                        tabindex="-1"
                        [index]="i"
                        [resultsIndex]="item.resultIndex"
                        (invoke)="updateSelectedIndex(i); onInvoke($event, 'go-link')"
                        (click)="updateSelectedIndex(i)"
                        [attr.data-index]="i"
                        [query]="null"
                        [listName]="listName"
                        (contextOpen)="updateSelectedIndex(i); openContextMenu(i, item)"
                        (contextClose)="closeContextMenu()"
                        (mouseover)="hoveredIndex = i"
                        (mouseout)="!onOpenContextMenu ? (hoveredIndex = null) : null"
                        [pageType]="pageType"
                        [showResultSections]="getShowResultSections(item)"
                        [layoutMode]="layoutMode"
                        [checked]="checkedItems.has(item.id)"
                        (changeSelection)="toggleChecked($event, item.id)"
                        [actionsReadOnly]="actionsReadOnly"
                      >
                        <div *ngIf="supportedInGalleryView" cdkDragHandle class="drag-ind"></div>
                      </go-links-view-item>
                    </ng-container>
                    <ng-container *ngSwitchCase="'person'">
                      <people-view-item
                        [style.background-color]="displayBg ? 'var(--color-bg)' : 'transparent'"
                        #resultItem
                        class="result-item"
                        [ngClass]="{
                          'preview-mode': previewHandlerService.previewViewModel.previewState === 'side',
                          hovered: hoveredIndex === i
                        }"
                        [contextMenu]="contextMenu"
                        [previewState]="previewHandlerService.previewViewModel.previewState"
                        [previewable]="isPreviewClickAction(item.action)"
                        [class.disabled]="item.deleted"
                        [class.selected]="isSelected(i)"
                        [class.hovered]="!dragInprogress && hoveredIndex === i"
                        [sessionId]="displayedContext.sessionId"
                        [clientSearchId]="displayedContext.clientSearchId"
                        [selected]="selectedIndex === i"
                        [model]="item"
                        tabindex="-1"
                        [index]="i"
                        [resultsIndex]="item.resultIndex"
                        (invoke)="updateSelectedIndex(i); onInvoke($event, 'person')"
                        (click)="updateSelectedIndex(i)"
                        [attr.data-index]="i"
                        [listName]="listName"
                        (contextOpen)="updateSelectedIndex(i); openContextMenu(i, item)"
                        (contextClose)="closeContextMenu()"
                        (mouseover)="hoveredIndex = i"
                        (mouseout)="!onOpenContextMenu ? (hoveredIndex = null) : null"
                        class="result-item"
                        [pageType]="pageType"
                        [showResultSections]="getShowResultSections(item)"
                        [layoutMode]="layoutMode"
                        [checked]="checkedItems.has(item.id)"
                        (changeSelection)="toggleChecked($event, item.id)"
                      >
                        <div *ngIf="supportedInGalleryView" cdkDragHandle class="drag-ind"></div>
                      </people-view-item>
                    </ng-container>
                    <ng-container *ngSwitchCase="'collection'">
                      <collection-item
                        #resultItem
                        [style.background-color]="displayBg ? 'var(--color-bg)' : 'transparent'"
                        class="result-item"
                        [attr.data-index]="i"
                        tabindex="-1"
                        [index]="i"
                        [class.selected]="isSelected(i)"
                        [class.hovered]="!dragInprogress && hoveredIndex === i"
                        [collection]="item"
                        [selected]="i === selectedIndex"
                        [viewMode]="supportedInGalleryView ? 'gallery-card' : 'collection-search-result'"
                        [contextMenu]="contextMenu"
                        [isClickable]="!supportedInGalleryView"
                        (click)="updateSelectedIndex(i)"
                        [class.disabled]="collectionsService.deleteCollectionId === item?.id"
                        (contextOpen)="updateSelectedIndex(i); hoveredIndex = index"
                        (mouseover)="hoveredIndex = i"
                        (onClickEvent)="openCollection(item, 'collections-view')"
                        (mouseout)="!onOpenContextMenu ? (hoveredIndex = null) : null"
                        (invoke)="updateSelectedIndex(i); onInvoke($event, 'collection')"
                        [showResultSections]="getShowResultSections(item)"
                        [checked]="checkedItems.has(item.id)"
                        (changeSelection)="toggleChecked($event, item.id)"
                        (contextClose)="closeContextMenu()"
                        [actionsReadOnly]="actionsReadOnly"
                      >
                        <div *ngIf="supportedInGalleryView" cdkDragHandle class="drag-ind"></div>
                      </collection-item>
                    </ng-container>
                    <ng-container *ngSwitchCase="'collection-url'">
                      <static-result-item
                        #resultItem
                        [style.background-color]="displayBg ? 'var(--color-bg)' : 'transparent'"
                        class="result-item"
                        tabindex="-1"
                        [model]="item"
                        [index]="i"
                        [class.selected]="isSelected(i)"
                        [class.hovered]="!dragInprogress && hoveredIndex === i"
                        [attr.data-index]="i"
                        [showResultSections]="
                          getShowResultSections(item) || {
                            isEditable: true,
                            showBullets: true,
                            calcBullets: true,
                            showContextMenu: true
                          }
                        "
                        [contextMenu]="contextMenu"
                        (invoke)="updateSelectedIndex(i, $event); onInvoke($event, 'collection-url')"
                        (click)="updateSelectedIndex(i)"
                        [selected]="selectedIndex === i"
                        (titleChanged)="titleChangedSub.next({$event,item})"
                        (contextOpen)="selectedIndex = i; hoveredIndex = index"
                        (mouseover)="hoveredIndex = i"
                        (mouseout)="!onOpenContextMenu ? (hoveredIndex = null) : null"
                        [resultsWidth]="resultsRef?.nativeElement?.offsetWidth"
                        [layoutMode]="layoutMode"
                        [checked]="checkedItems.has(item.id)"
                        (changeSelection)="toggleChecked($event, item.id)"
                        (contextClose)="closeContextMenu()"
                        (onRenameFlow)="onRenameFlow.emit($event)"
                      >
                        <div *ngIf="supportedInGalleryView" cdkDragHandle class="drag-ind"></div>
                      </static-result-item>
                    </ng-container>
                    <ng-container *ngSwitchCase="'collection-file'">
                      <static-result-item
                        #resultItem
                        [style.background-color]="displayBg ? 'var(--color-bg)' : 'transparent'"
                        class="result-item"
                        [previewable]="!!item.previewType"
                        [ngClass]="{
                          'preview-mode': previewHandlerService.previewViewModel.previewState === 'side',
                          hovered: hoveredIndex === i
                        }"
                        tabindex="-1"
                        [index]="i"
                        [previewState]="previewHandlerService.previewViewModel.previewState"
                        [model]="item"
                        [class.selected]="isSelected(i)"
                        [class.hovered]="!dragInprogress && hoveredIndex === i"
                        [attr.data-index]="i"
                        [showResultSections]="
                          getShowResultSections(item) || {
                            isEditable: true,
                            showBullets: true,
                            calcBullets: true,
                            showContextMenu: true,
                            showPreviewIcon: !smallView,
                            showSummaryIcon: !smallView
                          }
                        "
                        [contextMenu]="contextMenu"
                        (invoke)="updateSelectedIndex(i, $event); onInvoke($event, 'collection-url')"
                        (click)="updateSelectedIndex(i)"
                        [selected]="selectedIndex === i"
                        (titleChanged)="titleChangedSub.next({$event,item})"
                        (contextOpen)="updateSelectedIndex(i); onOpenContextMenu = true"
                        (contextClose)="hoveredIndex = null; onOpenContextMenu = false"
                        (mouseover)="hoveredIndex = i"
                        (mouseout)="!onOpenContextMenu ? (hoveredIndex = null) : null"
                        [resultsWidth]="resultsRef?.nativeElement?.offsetWidth"
                        [pageType]="pageType"
                        [layoutMode]="layoutMode"
                        [checked]="checkedItems.has(item.id)"
                        (changeSelection)="toggleChecked($event, item.id)"
                        (contextClose)="closeContextMenu()"
                      >
                        <div *ngIf="supportedInGalleryView" cdkDragHandle class="drag-ind"></div>
                      </static-result-item>
                    </ng-container>
                    <ng-container *ngSwitchCase="'collection-header'">
                      <static-result-item
                        #resultItem
                        [style.background-color]="displayBg ? 'var(--color-bg)' : 'transparent'"
                        class="result-item header-item"
                        [class.widget-mode]="smallView"
                        [model]="item"
                        [lightTitle]="true"
                        [inputPlaceholder]="'Header'"
                        [attr.data-index]="i"
                        [index]="i"
                        [showResultSections]="getShowResultSections(item) || { showContextMenu: false, isEditable: true }"
                        [contextMenu]="contextMenu"
                        (titleChanged)="titleChangedSub.next({$event,item})"
                        [resultsWidth]="resultsRef?.nativeElement?.offsetWidth"
                        (invoke)="updateSelectedIndex(i); onInvoke($event, 'collection-header')"
                        (click)="updateSelectedIndex(i)"
                        (contextOpen)="updateSelectedIndex(i); hoveredIndex = index"
                        [checked]="checkedItems.has(item.id)"
                        (changeSelection)="toggleChecked($event, item.id)"
                        (contextClose)="closeContextMenu()"
                        (onRenameFlow)="onRenameFlow.emit($event)"
                      >
                        <div *ngIf="supportedInGalleryView" cdkDragHandle class="drag-ind"></div>
                      </static-result-item>
                    </ng-container>
                    <ng-container *ngSwitchCase="'error'">
                      <error-item
                        #resultItem
                        [selected]="selectedIndex === i"
                        [class.selected]="isSelected(i)"
                        [model]="item"
                        (invoke)="onInvoke($event)"
                        tabindex="0"
                        class="result-item"
                        (click)="updateSelectedIndex(i)"
                        [attr.data-index]="i"
                        [index]="i"
                      ></error-item>
                    </ng-container>
                    <ng-container *ngSwitchCase="'answer'">
                      <result-answer-item
                        #resultItem
                        [isLauncher]="hubService.isLauncher"
                        [selected]="selectedIndex === i"
                        [class.selected]="isSelected(i)"
                        [class.keyboardOn]="keyboardOn"
                        [class.preview-mode]="isPreviewMode"
                        [model]="item"
                        class="result-item"
                        [attr.data-index]="i"
                        [searchId]="searchId"
                        [index]="i"
                        [disableTyping]="disableAnswerTyping"
                        [sessionId]="displayedContext.sessionId"
                        [clientSearchId]="displayedContext.clientSearchId"
                        [hideFollowUp]="listView?.hideFollowUp"
                        [isFeedbackOpen]="isAnswerFeedbackOpen"
                        (feedbackShow)="showAnswerFeedback($event, i)"
                      ></result-answer-item>
                    </ng-container>
                    <ng-container *ngSwitchCase="'answer-feedback'">
                      <div #answerFeedback>
                        <answer-feedback
                          #resultItem
                          [model]="item"
                          [searchId]="searchId"
                          (closeFeedback)="hideAnswerFeedback(i)"
                        ></answer-feedback>
                      </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'time-action'">
                      <time-action-item #resultItem [model]="item" class="result-item" [attr.data-index]="i"></time-action-item>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="loading">
              <result-ghost
                *ngFor="let i of 20 | fill"
                [galleryView]="supportedInGalleryView"
                [class.gallery]="supportedInGalleryView"
              ></result-ghost>
            </ng-container>

            <ng-container *ngIf="isLoadingAnswer">
              <result-answer-ghost [showLoadingText]="true"></result-answer-ghost>
            </ng-container>

            <div class="no-results" *ngIf="emptyResults">
              <h1 class="no-results-title headline-1">{{ emptyResultsText }}</h1>
              <q class="headline-1 bold" *ngIf="query">
                {{ query | truncate : 30 }}
              </q>
            </div>
          </div>
          <refine-search-message *ngIf="resultsLimit === 100"></refine-search-message>
        </div>
      </ng-scrollbar>
    </div>
  </div>
</ng-template>
