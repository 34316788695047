import { ElementRef, Injectable } from '@angular/core';
import { Workspace } from '@local/client-contracts';
import { observable } from '@local/common';
import { PopupRef, PopupService } from '@local/ui-infra';
import { ContextMenuComponent, ContextMenuData, ContextMenuItem, ContextMenuService } from '@shared/components';
import { EventsService } from '@shared/services';
import { RouterService } from '@shared/services/router.service';
import { TitleBarService } from '@shared/services/title-bar.service';
import { Logger } from '@unleash-tech/js-logger';
import { Observable, ReplaySubject, combineLatest, take } from 'rxjs';
import { WorkspaceInviteComponent } from 'src/app/onboarding-web/containers/workspace-invite/workspace-invite.component';
import { HELP_MENU_ITEMS } from '../views/hub/shared/sidebar/menu-items';
import { PricingService } from './pricing.service';
import { ShowToasterService } from './show-toaster.service';
import { WorkspacesService } from './workspaces.service';
@Injectable()
export class HelpPopupService {
  helpMenuRef: PopupRef<ContextMenuComponent, ContextMenuData>;
  workspaceInvitePopupRef: PopupRef<WorkspaceInviteComponent, any>;

  private isOwnerOrAdmin: boolean;
  private helpItems: ContextMenuItem[];
  protected logger: Logger;
  private _active$: ReplaySubject<boolean> = new ReplaySubject(1);
  private workspace: Workspace.Workspace;

  @observable
  get active$(): Observable<boolean> {
    return this._active$.asObservable();
  }

  private set active(value: boolean) {
    this._active$.next(value);
  }
  constructor(
    private titleBarService: TitleBarService,
    private contextMenuService: ContextMenuService,
    private eventsService: EventsService,
    private routerService: RouterService,
    public popupService: PopupService,
    private workspaceService: WorkspacesService,
    private pricingService: PricingService,
    private showToasterService: ShowToasterService
  ) {
    this.helpItems = HELP_MENU_ITEMS.filter((h) => !h.disabled);
    combineLatest([this.workspaceService.current$, this.workspaceService.ownerOrAdmin$]).subscribe(([workspace, isOwnerOrAdmin]) => {
      this.isOwnerOrAdmin = isOwnerOrAdmin;
      this.workspace = workspace;
      if ((!this.isOwnerOrAdmin && !this.workspace?.settings?.invites?.membersCanInvite) || this.workspace?.settings?.invites?.blockAll) {
        this.helpItems = this.helpItems.filter((item) => item.id !== 'invite');
      }
    });
  }

  ngOnDestroy() {
    if (this.helpMenuRef) {
      this.helpMenuRef.destroy();
      this.helpMenuRef = null;
    }
    this.workspaceInvitePopupRef?.destroy();
  }

  open(helpButtonRef: ElementRef, menuWidth: number) {
    const location = { title: this.titleBarService.locationTitle };
    const { x, y } = helpButtonRef.nativeElement.getBoundingClientRect();
    const position = { left: x + menuWidth, top: this.isOwnerOrAdmin ? y - 130 : y - 110 };

    this.active = true;

    this.helpMenuRef = this.contextMenuService.open(
      position,
      {
        items: this.helpItems,
        onInvoke: (item, trigger) => {
          this.eventsService.event('header.help_menu', {
            target: item.id.replace('-', '_'),
            name: 'help_menu',
            location: { title: this.titleBarService.locationTitle },
          });
          this.onHelpInvoke({ item, trigger });
        },
      },
      { position: [{ originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'top' }] }
    );
    this.helpMenuRef.destroy$.pipe(take(1)).subscribe(() => {
      this.helpMenuRef = null;
      this.active = false;
    });
    this.eventsService.event('header.help_menu', { location });
  }

  private onHelpInvoke({ item, trigger }: { item: ContextMenuItem; trigger?: string }) {
    if (!item.data?.page && !item.data?.url && !item.data?.popup) {
      this.logger.warn(`No page/url/popup set for ${item.id}`);
      return;
    }

    const { page, url, popup } = item.data || {};

    if (page) {
      this.routerService.navigateByUrl(page);
      return;
    }

    if (url) {
      this.routerService.navigate(['external-link'], { queryParams: { url } }, false);
    }

    if (popup) {
      if (this.workspaceInvitePopupRef) {
        this.workspaceInvitePopupRef.destroy();
      }
      this.workspaceInvitePopupRef = this.popupService.open(
        'center',
        popup,
        {},
        {
          position: 'center',
          backdropStyle: 'blur-2',
        }
      );
      this.workspaceInvitePopupRef.compInstance.componentType = 'popup';
      this.workspaceInvitePopupRef.compInstance.inviteEvent.subscribe((emails: string[]) => {
        this.workspaceService.invite(emails);
        this.showToasterService.showToaster({
          id: 'workspace-invite',
          content: `Invitation sent`,
        });
      });
      this.workspaceInvitePopupRef.compInstance.cancelEvent.subscribe(() => {
        this.workspaceInvitePopupRef?.destroy();
      });
      this.workspaceInvitePopupRef.compInstance.upgradeButtonClicked.subscribe(() => {
        this.pricingService.navigateToPricingPage();
      });
    }
  }
}
