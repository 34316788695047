import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'u-edit-text',
  templateUrl: './u-edit-text.component.html',
  styleUrls: ['./u-edit-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UEditTextComponent implements AfterViewInit {
  readonly MAX_NUMBER = 524288;

  @Output() onBlur: EventEmitter<void> = new EventEmitter<void>();
  @Output() onTextChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() onKeydown: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();
  @Output() onEnterPressed: EventEmitter<void> = new EventEmitter<void>();

  @Input() readonly: boolean = false;
  @Input() focusNoColor: boolean = false;
  @Input() textSelected = false;
  @Input() maxLength: number = this.MAX_NUMBER;
  @Input() ignoreEnter = false;

  _placeholder: string;
  @Input() set placeholder(v: string) {
    if (this.placeholder !== v && this.contenteditable) {
      this.setCursorAtEnd();
    }
    this._placeholder = v;
  }

  get placeholder(): string {
    return this._placeholder;
  }

  _allowFocus: boolean = true;

  @Input() set allowFocus(v: boolean) {
    const prev = this._allowFocus;
    this._allowFocus = v;

    if (v && !prev) {
      this.showFocus();
    }
  }

  get allowFocus(): boolean {
    return this._allowFocus;
  }

  @ViewChild('contenteditable') contenteditable: ElementRef;

  isFirstInit: boolean = true;

  // for testing purposes
  private text: string;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    if (this.allowFocus) {
      this.showFocus();
    }
  }

  showFocus() {
    setTimeout(() => {
      this.contenteditable.nativeElement.focus();
      if (this.textSelected) {
        this.selectText();
      } else {
        this.setCursorAtEnd();
      }
      this.cdr.markForCheck();
    }, 250);
  }

  private selectText() {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(this.contenteditable.nativeElement);
    selection.removeAllRanges();
    selection.addRange(range);
  }

  setCursorAtEnd() {
    window.getSelection().selectAllChildren(this.contenteditable.nativeElement);
    window.getSelection().collapseToEnd();
    this.cdr.markForCheck();
  }

  onInputChange($event) {
    this.text = $event.textContent;
    this.onTextChange.emit($event.textContent);
  }

  onClickEvent() {
    if (this.readonly) return;
    this.isFirstInit = false;
    this.cdr.markForCheck();
    this.onClick.emit();
  }

  onKeydownMain(event: KeyboardEvent) {
    this.isFirstInit = false;
    event.stopPropagation();
    if (!this.isKeyAllowed(event)) {
      return;
    }
    if (event.key === 'Enter') {
      this.onEnterPressed.emit();
      if (this.ignoreEnter) {
        event.preventDefault();
      }
    }

    this.cdr.markForCheck();
    this.onKeydown.emit(event);
  }

  isKeyAllowed(event) {
    /* Any Shortcut except Ctrl + V */
    const isValidShortcut = event.ctrlKey && event.keyCode != 86;

    /* Backspace - Delete - Arrow Keys - Ctrl - Shift */
    const isValidKeyCode = [8, 16, 17, 37, 38, 39, 40, 46].includes(event.keyCode);

    const text = event.srcElement.innerText;

    if (text.length >= this.maxLength && !isValidKeyCode && !isValidShortcut) {
      event.preventDefault();
      return false;
    }
    return true;
  }
}
