import { Injectable } from '@angular/core';
import { capitalCase } from '@local/ts-infra';
import * as markdownItAttrs from '@marked-it/markdown-it-attrs';
import { copyToClipboard } from '@shared/utils';
import hljs from 'highlight.js';
import MarkdownIt from 'markdown-it';
import * as markdownItBracketedSpan from 'markdown-it-bracketed-spans';

@Injectable({
  providedIn: 'root',
})
export class ResultMarkdownService {
  private markdownIt: MarkdownIt;

  constructor() {
    this.init();
  }

  render(markdown: string): string {
    return this.markdownIt.render(markdown);
  }
  async copyHighlightCode(button: HTMLElement) {
    const codeBlock = button?.parentElement.nextElementSibling.querySelector('code');
    if (codeBlock) {
      const code = codeBlock.innerText;
      await copyToClipboard(code);
      button.textContent = 'Copied!';
      setTimeout(() => (button.textContent = 'Copy code'), 2000);
    }
  }

  private init() {
    this.markdownIt = new MarkdownIt({
      breaks: true,
      html: true,
      linkify: true,
      typographer: true,
      highlight: (str, lang) => {
        if (lang && hljs.getLanguage(lang)) {
          try {
            const highlighted = hljs.highlight(lang, str, true).value;
            const languageLabel = `<div class="code-header">
                                 <span class="language-name">${capitalCase(lang)}</span>
                                 <button class="copy-code-btn" onclick="___copyHighlightCode(this)">Copy code</button>
                               </div>`;
            return `${languageLabel}<div class="code-content"><pre class="hljs language-${lang}"><code class="hljs language-${lang} ${lang}">${highlighted}</code></pre></div>`;
          } catch (__) {}
        }
        const esc = this.markdownIt.utils.escapeHtml;
        return lang ? '<pre class="hljs"><code class="hljs">' + esc(str) + '</code></pre>' : esc(str);
      },
    }).enable(['link']);

    const defaultRender =
      this.markdownIt.renderer.rules.link_open ||
      function (tokens, idx, options, _env, self) {
        return self.renderToken(tokens, idx, options);
      };
    this.markdownIt.use(markdownItAttrs).use(markdownItBracketedSpan);
    this.markdownIt.renderer.rules.link_open = (tokens, idx, options, env, self) => {
      const hrefIndex = tokens[idx].attrIndex('target');

      if (hrefIndex < 0) {
        tokens[idx].attrPush(['target', '_blank']);
      } else {
        tokens[idx].attrs[hrefIndex][1] = '_blank';
      }
      return defaultRender(tokens, idx, options, env, self);
    };
    window['___copyHighlightCode'] = this.copyHighlightCode.bind(this);
  }
}
