<div
  class="card-header-line"
  ghost
  [ghostUntil]="!!wikiCard"
  [ghostStyle]="{ width: '200px', height: '20px', marginLeft: showVerificationIndications ? '115px' : '0' }"
>
  <div class="header-top">
    <breadcrumbs *ngIf="['popup', 'window'].includes(pageMode)" class="breadcrumbs" [popupModeItems]="breadcrumbsItems"></breadcrumbs>
    <ng-container *ngTemplateOutlet="headerActions"></ng-container>
  </div>
</div>

<!-- TEMPLATES -->
<ng-template #headerActions>
  <wiki-card-header-actions
    (openExternal)="openExternal.emit($event); changeFocus('card-external')"
    (closePopup)="closePopup.emit()"
    (contextOpen)="changeFocus('card-context-menu')"
    (switchView)="switchView.emit($event)"
    (onInvoke)="onInvoke($event)"
    [wikiCard]="wikiCard"
    [permissionRole]="wikiCardPermissionRole"
    [pageMode]="pageMode"
    [viewMode]="viewMode"
    [updatedVerification]="updatedVerification"
    [showVerificationIndications]="showVerificationIndications"
    [showCloseButton]="showCloseButton"
  ></wiki-card-header-actions>
</ng-template>
