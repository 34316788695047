import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { isMac } from '@local/common-web';
import { PopupRef } from '@local/ui-infra';
import { UntilDestroy } from '@ngneat/until-destroy';
import { KeyboardService } from '@shared/services/keyboard.service';
import { isEnterKey, KeyName } from '@local/ts-infra';
import { Tooltip } from 'primeng/tooltip';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { CollectionItem, ScrollService } from 'src/app/bar/views/results/services/scroll.service';
import { NgScrollbar } from 'ngx-scrollbar';
import { KeyboardHelperService } from '@shared/helper/keyboard-helper.service';
import { Commands } from '@local/client-contracts';
import { CommandsService } from 'src/app/bar/services/commands/commands.service';
import { Recipient } from 'src/app/bar/views/results-views/mail/mail-build-result-view.service';

type scrollRecipient = CollectionItem & { item: Recipient };
@UntilDestroy()
@Component({
  selector: 'mail-preview-recipients',
  templateUrl: './mail-preview-recipients.component.html',
  styleUrls: ['./mail-preview-recipients.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MailPreviewRecipientsComponent implements OnInit, OnDestroy, AfterViewInit {
  recipients: Recipient[];
  tooltipText: string = 'Click to copy';
  widthRecipients: number;
  @ViewChildren(Tooltip) private tooltips: QueryList<Tooltip>;

  //keyboard
  selectedIndex: number = 0;
  private keyHandlerId: string;
  private keyboardHelperService: KeyboardHelperService;

  //scroll
  scrollHeight: number;
  readonly itemSize: number = 42;
  private scrollRecipient: scrollRecipient[];
  private scrollService: ScrollService<scrollRecipient>;
  @ViewChild(NgScrollbar) scrollbarRef: NgScrollbar;
  @ViewChild(CdkVirtualScrollViewport) scrollViewport: CdkVirtualScrollViewport;
  @ViewChildren('recipientItem', { read: ElementRef }) recipientsElements: QueryList<ElementRef>;

  get scrollViewEl(): HTMLElement {
    return this.scrollViewport?.elementRef?.nativeElement;
  }

  constructor(
    private ref: PopupRef<MailPreviewRecipientsComponent, Recipient[]>,
    private cdr: ChangeDetectorRef,
    private commandsService: CommandsService,
    private keyboardService: KeyboardService,
    protected ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.initData();
    this.initKeyboard();
  }

  ngAfterViewInit(): void {
    this.initScrollService();
  }

  ngOnDestroy() {
    this.scrollService?.destroy();
    if (this.keyHandlerId) this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
  }

  private initData() {
    this.recipients = this.ref.data;
    this.selectedIndex = 0;
    if (this.recipients.length > 5) {
      this.widthRecipients = 250;
      this.scrollHeight = 200;
    } else {
      this.widthRecipients = 240;
      this.scrollHeight = this.recipients.length * this.itemSize + 2;
    }
    this.scrollRecipient = this.recipients.map((r, i) => {
      return { displayIndex: i, item: r };
    });
  }

  private initKeyboard() {
    this.scrollService = new ScrollService(this.ngZone);
    this.keyboardHelperService = new KeyboardHelperService();
    this.keyboardHelperService.onInit(this.selectedIndex, this.scrollRecipient, this.scrollService);
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys, event) => {
      this.keysHandler(keys, event);
      this.keyboardHelperService.handleArrows(keys, event);
    }, 10);
  }

  private initScrollService() {
    this.scrollService.items = this.scrollRecipient;
    this.scrollService.itemHeights = Array(this.scrollRecipient.length).fill(this.itemSize);
    this.scrollService.init(this.scrollbarRef, this.recipientsElements, this.scrollViewport);
    if (!this.scrollViewport?.elementRef?.nativeElement) {
      this.scrollService?.destroy();
    }
    this.cdr.markForCheck();
  }

  copyMail(recipient: Recipient, index: number) {
    this.commandsService.executeCommand({
      type: 'copy-clipboard',
      value: recipient.mail,
    } as Commands.CopyClipboard);
    this.tooltipText = 'Copied!';
    setTimeout(() => {
      this.tooltips.get(index).show();
      setTimeout(() => {
        this.tooltips.get(index).hide();
        this.tooltipText = 'Click to copy';
        this.cdr.markForCheck();
      }, 500);
    }, 0);
  }

  private keysHandler(keys: Array<KeyName>, event): void {
    if (isMac()) keys = keys.map((k) => (k = k === 'control' ? 'command' : k));
    const key = keys[0];

    if (keys.length === 1) {
      if (key === 'ArrowDown') {
        this.selectedIndex < this.recipients?.length ? (this.selectedIndex += 1) : null;
        this.setEvent(event);
        return;
      }

      if (key === 'ArrowUp') {
        this.selectedIndex > -1 ? (this.selectedIndex -= 1) : null;
        this.setEvent(event);
        return;
      }

      if (key === 'escape') {
        this.ref.destroy();
        this.setEvent(event);
        return;
      }

      if (isEnterKey(key) && this.selectedIndex > -1 && this.selectedIndex < this.recipients?.length) {
        this.copyMail(this.recipients[this.selectedIndex], this.selectedIndex);
        this.setEvent(event);
        return;
      }
      event.stopPropagation();
      this.cdr.markForCheck();
    }
  }

  private setEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    this.cdr.markForCheck();
  }
}
