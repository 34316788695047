import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { isIconEmoji } from '@local/ts-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TreeDragDropService, TreeNode } from 'primeng/api';
import { Tree, TreeNodeDropEvent } from 'primeng/tree';
import { OptionalStyleService } from '../../services/optional-style.service';
import { Scheme } from '../../types/scheme';

@UntilDestroy()
@Component({
  selector: 'u-tree',
  templateUrl: './u-tree.component.html',
  styleUrls: ['./u-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [OptionalStyleService, TreeDragDropService],
})
export class UTreeComponent implements AfterViewInit, OnInit {
  @Input() value: TreeNode[];
  @Input() selectionMode: 'single' | 'multiple' | 'checkbox' | null = null;
  @Input() selection: TreeNode | TreeNode[];
  @Input() loading = false;
  @Input() virtualScroll = false;
  @Input() virtualNodeHeight: number;
  @Input() scrollHeight: string = null;
  @Input() styles: any = {};
  @Input() emptyMessage = 'No records found.';
  @Input() filter = false;
  @Input() filterPlaceholder: string = null;
  @Input() markOnFilter = false;
  @Input() disabledButton = false;
  @Input() nodeTemplate: any;
  @Input() emptyTemplate: any;
  @Input() dragAndDrop: boolean;
  @Input() dragAndDropScope: any;
  @Input() validateDrop = (_current, _newParent) => {
    return true;
  };

  @Output() onNodeUnselect: EventEmitter<any> = new EventEmitter<any>();
  @Output() onNodeSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onNodeExpand: EventEmitter<any> = new EventEmitter<any>();
  @Output() onNodeCollapse: EventEmitter<any> = new EventEmitter<any>();
  @Output() onNodeDrop: EventEmitter<TreeNodeDropEvent> = new EventEmitter<TreeNodeDropEvent>();
  @Output() onCancelDrop: EventEmitter<void> = new EventEmitter<void>();
  @Output() onDrag: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSearch: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('tree') treeRef: Tree;
  theme: Scheme = 'light';

  constructor(private cdr: ChangeDetectorRef, private optionalStyleService: OptionalStyleService) {}

  ngAfterViewInit(): void {
    this.treeRef?.filterViewChild?.nativeElement.focus();
    this.treeRef?.dragDropService.dragStart$.subscribe((event) => {
      event.node.style = { opacity: '0.5' };
      this.onDrag.emit(true);
    });
    this.treeRef?.dragDropService.dragStop$.subscribe((event) => {
      event.node.style = {};
      this.onDrag.emit(false);
    });
  }

  ngOnInit(): void {
    this.optionalStyleService.theme$.pipe(untilDestroyed(this)).subscribe((theme) => {
      this.theme = theme;
      this.cdr.markForCheck();
    });
  }

  markedText: string;

  isEmoji(icon: string): boolean {
    return isIconEmoji(icon);
  }

  onFilter(val) {
    const filter = val.filter;
    this.onSearch.emit(filter);
    if (!this.markOnFilter) return;
    this.markedText = filter;
    this.cdr.markForCheck();
  }

  nodeDrop(event: TreeNodeDropEvent) {
    const current = event.dragNode;
    let newParent: TreeNode = event.dropNode;
    let index: number;
    const isReordering = this.isReordering(event.originalEvent);
    if (isReordering) {
      newParent = event?.dropNode?.parent;
    } else {
      index = newParent.children.length - 1;
    }
    if (this.validateDrop(current, newParent)) {
      event.accept();
      if (isReordering) {
        index = newParent?.children?.findIndex((node) => node.data?.id === current?.data?.id);
      }
      const updatedEvent: TreeNodeDropEvent = { ...event, dropNode: newParent, index };
      this.onNodeDrop.emit(updatedEvent);
    } else {
      this.onCancelDrop.emit();
    }
  }

  private isReordering(dropEvent: any): boolean {
    // primeng applies this class to the <li> representing the drop zone between two sibling nodes
    const targetNodeClassName = dropEvent.target.className;
    return !!targetNodeClassName?.match(/p-treenode-droppoint-active/);
  }

  nodeExpand($event) {
    this.onNodeExpand.emit($event);
  }

  nodeCollapse($event) {
    this.onNodeCollapse.emit($event);
  }
}
