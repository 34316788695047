import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Config } from '@environments/config';
import { Style, Telemetry, Window, Workspace } from '@local/client-contracts';
import {
  AutoUpdateNativeRpcInvoker,
  Constants,
  Developer,
  Module,
  NativeAppLinkRpcInvoker,
  NativeAppRpcInvoker,
  performanceCheckpoint,
} from '@local/common';
import { isEmbed, isNativeWindow } from '@local/common-web';
import { getModifiers } from '@local/ts-infra';
import { PopupRef, PopupService, STYLE_SERVICE } from '@local/ui-infra';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { pushTag } from '@shared/analytics';
import { ArmEmulatorPopupComponent } from '@shared/components/arm-emulator-popup/arm-emulator-popup.component';
import { WorkerChangePopupComponent } from '@shared/components/worker-change-popup/worker-change-popup.component';
import { HEADER_BANNER_MESSAGES } from '@shared/consts/banner-messages';
import { EmbedService } from '@shared/embed.service';
import { LoaderService } from '@shared/loader.service';
import { LogService, NativeMainRpcService, TelemetryService, WindowService } from '@shared/services';
import { AppService } from '@shared/services/app.service';
import { ApplicationsService } from '@shared/services/applications.service';
import { BannerMessage, BannerService } from '@shared/services/banner.service';
import { BrowserExtensionService } from '@shared/services/browser-extension.service';
import { BrowserHistoryService } from '@shared/services/browser-history.service';
import { ClientStorageService } from '@shared/services/client-storage.service';
import { KeyboardService } from '@shared/services/keyboard.service';
import { LeadService } from '@shared/services/lead.service';
import { LinksService } from '@shared/services/links.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { PreferencesService } from '@shared/services/preferences.service';
import { RouterService } from '@shared/services/router.service';
import { NativeServicesRpcService, ServicesRpcService, componentServicesRpcProvider } from '@shared/services/rpc.service';
import { SessionService } from '@shared/services/session.service';
import { StyleService } from '@shared/services/style.service';
import { FlagsService } from '@shared/services/testim-flags.service';
import { TimerService } from '@shared/services/timer.service';
import { isProdEnv } from '@shared/utils';
import { RpcLogWriter } from '@unleash-tech/js-rpc';
import Cookies from 'js-cookie';
import { chain, cloneDeep } from 'lodash';
import { combineLatest, firstValueFrom } from 'rxjs';
import { applyUpdateWeb, platformUpdatePending$, servicesWorkerType } from 'src/services';
import { GlobalErrorHandler } from './global-error-handler';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: `./app.component.html`,
  styleUrls: [`./app.component.scss`],
  providers: [componentServicesRpcProvider],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, AfterViewInit {
  pinned: boolean;
  swPrefetch: string[] = [];
  ready: boolean;
  popupRef: PopupRef<WorkerChangePopupComponent, any>;
  pendingWorker: ServiceWorker = null;
  private readonly isEmbed = isEmbed();
  private readonly isNative = isNativeWindow();
  private isExtensionConnected: boolean;

  @ViewChild('testim', { static: false, read: ViewContainerRef }) testimContainer!: ViewContainerRef;

  constructor(
    private services: ServicesRpcService,
    nativeServices: NativeServicesRpcService,
    @Inject(STYLE_SERVICE) private styleService: StyleService,
    private keyboardService: KeyboardService,
    private windowService: WindowService,
    private telemetry: TelemetryService,
    private popupService: PopupService,
    private mainRpc: NativeMainRpcService,
    private loader: LoaderService,
    private cdr: ChangeDetectorRef,
    private routerService: RouterService,
    private apps: ApplicationsService,
    private timer: TimerService,
    private embedService: EmbedService,
    private globalError: GlobalErrorHandler,
    private appService: AppService,
    private flagsService: FlagsService,
    private bhService: BrowserHistoryService,
    private bannerService: BannerService,
    private linksService: LinksService,
    private extensionService: BrowserExtensionService,
    private clientStorageService: ClientStorageService,
    private sessionService: SessionService,
    private leadService: LeadService,
    private localStorageService: LocalStorageService,
    private prefService: PreferencesService,
    private logService: LogService
  ) {
    window.addEventListener('beforeunload', () => pushTag({ event: 'page_exit', track_label: 'unload' }));

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        pushTag({ event: 'page_exit', track_label: 'hidden' });
      }
    });

    this.handleAutoUpdate();

    this.keyboardService.disableDocumentEvents();

    performanceCheckpoint('main_module_app');

    if ((<any>window).__sandbox) {
      const devApps = services.invokeWith(Developer.DeveloperApplicationsRpcInvoker);
      for (const app of (<any>window).__sandbox.apps) {
        if (new URL(app).hostname != 'localhost') continue;
        devApps.add(app);
      }
    }

    this.sessionService.current$.subscribe((s) => {
      let taid: any = {};
      try {
        taid = JSON.parse(atob((<any>window).__taid));
      } catch {}

      if (!taid?.campaign && (!s || s.workspace?.type === 'Team' || this.isPlanExist(s.workspace))) {
        return;
      }
      if (!this.isNative && !this.isEmbed) {
        this.reportLead();
      }
    });
    if (!this.isNative) {
      // we are checking already index.html
      this.clientStorageService.set('last-update-check', Date.now());

      let tm = null;
      // prefetch linked apps
      combineLatest([this.linksService.visible$, this.apps.all$]).subscribe(([links, apps]) => {
        if (tm) clearTimeout(tm);
        tm = setTimeout(() => this.checkForAppsUpdates(), 5000); // give the apps and links some time to stabilize otherwise we will have multiple attempt
      });
      // let the service worker know that we are alive
      const KEEP_ALIVE = 30 * 1000;
      let keepSucceeded = 0,
        keepFailed = 0;
      if (servicesWorkerType == 'shared') {
        setInterval(async () => {
          const invokeTime = Date.now();
          let lastSuccess = 0,
            lastFail = 0;
          try {
            await this.services.invoke('clientwindow.keepalive');
            ++keepSucceeded;
            lastSuccess = Date.now();
          } catch (e) {
            ++keepFailed;

            this.logService.logger.error('Failed in client window keep', {
              exception: e,
              endTime: Date.now(),
              invokeTime,
              keepFailed,
              keepSucceeded,
              lastSuccess: Date.now() - lastSuccess,
              threadLastAlive: Date.now() - (<any>self).__servicesLastAlive,
              lastFail: Date.now() - lastFail,
              timeSinceLink: Date.now() - ((<any>self).webLoad.portLinked?.time || 0),
              servicesWorkerScript: (<any>window).__servicesWorkerScript,
              timeSinceCreateWorker: Date.now() - (<any>window).__servicesWorkerCreateTime,
              webLoad: (<any>self).webLoad,
              portTested: Date.now() - (<any>self).webLoad.portTest,
              threadStarted: Date.now() - ((<any>self).webLoad.portLinked?.threadStartTime || 0),
            });
            lastFail = Date.now();
          }
        }, KEEP_ALIVE);
      }

      this.timer.register(() => this.checkForClientUpdates(), { hiddenInterval: Config.autoUpdate.probeInterval });
      this.timer.register(() => this.checkForAppsUpdates(), { hiddenInterval: Config.autoUpdate.probeInterval });

      if (!this.isEmbed) {
        const taid = Cookies.get('taid'); // taid for traffic attribution
        const ulid = Cookies.get('ulid'); // unleash client id

        const nativeAppLink = this.services.invokeWith(NativeAppLinkRpcInvoker, 'nativeapplink');

        nativeAppLink.link({ taid, ulid });

        const blink = sessionStorage.getItem('blink') || localStorage.getItem('blink');

        combineLatest([this.prefService.current$, extensionService.current$]).subscribe(([pref, ext]) => {
          if (ext) {
            if (pref) {
              ext.invoke('syncPreferences', true, pref?.general?.theme);
            }
            ext.invoke('blink', true, blink);
          }
        });

        nativeAppLink.setBlink(blink);

        nativeAppLink.trackingIds$.pipe(untilDestroyed(this)).subscribe((x) => {
          const expires = Date.now() + 10 * 365 * 24 * 60 * 60 * 1000;

          if (x.deid) Cookies.set('deid', x.deid, { expires });
        });
      }
    } else {
      mainRpc
        .invokeWith(AutoUpdateNativeRpcInvoker, 'autoupdatenative')
        .isArmEmulator()
        .then((v) => {
          this.appService.windowStyle$.pipe(untilDestroyed(this)).subscribe((style: Window.WindowStyle) => {
            if (!v || style !== 'standard') return;
            const popup = this.popupService.open(
              'center',
              ArmEmulatorPopupComponent,
              {},
              {
                backdropStyle: 'blur-1',
                fullScreenDialog: true,
                closeOnClickOut: false,
              }
            );
            popup.compInstance.dismiss.subscribe(() => {
              if (popup) popup.destroy();
            });
          });
        });

      mainRpc
        .invokeWith(AutoUpdateNativeRpcInvoker, 'autoupdatenative')
        .platformUpdatePending$.pipe(untilDestroyed(this))
        .subscribe((v) => {
          console.log('received update ready from app');

          platformUpdatePending$.next(v);
        });
      nativeServices
        .invokeWith(NativeAppRpcInvoker, 'nativeapp')
        .trackingIds$.pipe(untilDestroyed(this))
        .subscribe((ids) => {
          const expires = Date.now() + 10 * 365 * 24 * 60 * 60 * 1000;

          if (ids.ulid) Cookies.set('ulid', ids.ulid, { expires });
          if (ids.deid) Cookies.set('deid', ids.deid, { expires });
          if (ids.taid) Cookies.set('taid', ids.taid, { expires });
        });
      mainRpc.handle('openUrl', (x) => {
        this.routerService.navigateByUrl(x);
      });
    }
    if (!this.isNative && !(<any>window).__isExtensionPage) {
      navigator.serviceWorker?.addEventListener('controllerchange', async () => {
        console.log('controller change event:', !!navigator.serviceWorker?.controller, navigator.serviceWorker?.controller?.state);

        const isDeveloper = location.pathname.startsWith('/developer');
        if (isDeveloper) return;

        const ver = await this.getControllerVersion();

        if (!ver || ver == Config.version) {
          console.log('silent replace due to same ver or no controller', ver, Config.version);
          return;
        }

        location.reload();
      });
    }

    keyboardService.registerKeyHandler((k, e) => {
      const modifiers = getModifiers(e);
      if (this.windowService && modifiers.includes('control') && modifiers.includes('shift') && k.includes('t')) {
        this.windowService.openDevTools();
        e.preventDefault();
        e.stopPropagation();
      }
      if (modifiers.includes('control') && modifiers.includes('shift') && k.includes('d')) {
        const url = this.isNative ? '/developer' : `${Config.baseUrl}/developer`;
        self.window.open(
          url,
          'unleash-dev',
          'titlebar=no,directories=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,height=800,width=1300'
        );
        e.preventDefault();
        e.stopPropagation();
      }
    }, 1000);

    if (!this.isNative) {
      const theme: Style.Theme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
      const screens: Telemetry.ScreensInfo = {
        colorDepth: screen.colorDepth,
        scale: window.devicePixelRatio,
        resolution: {
          width: screen.width,
          height: screen.height,
        },
        count: 1,
      };
      this.telemetry.setDisplay(theme, screens);
    }

    if (Config.log.viaThread) {
      const BUFFER_SIZE = 100;
      const BUFFER_TIMEOUT = 500;
      const rpcLog = new RpcLogWriter({ bufferSize: BUFFER_SIZE, bufferInterval: BUFFER_TIMEOUT }, nativeServices?.rpc || services?.rpc);
      (<Module>(<any>window).__module).logForwarder.bind(rpcLog);
    }
  }

  private async handleAutoUpdate() {
    let bc: BroadcastChannel;
    const msg: BannerMessage = cloneDeep(HEADER_BANNER_MESSAGES.newVersion);

    if (window.BroadcastChannel) {
      bc = new BroadcastChannel('auto_update');
      bc.addEventListener('message', (e) => {
        if (e.data == 'activity') {
          lastActivityTime = Date.now();
        } else if (e.data == 'banner') {
          this.bannerService.removeMessage(msg);
        }
      });
    }

    this.clientStorageService.get('current_app_version').then(async (current) => {
      let upgradeTime = 0;

      this.clientStorageService.set('current_app_version', Config.version);
      if (!current) {
        return;
      }

      const cparts = (<string>current).split('.');
      const nparts = Config.version.split('.');
      const isHotFix = nparts[0] == cparts[0] && nparts[1] == cparts[1];

      if (!isHotFix) {
        upgradeTime = Date.now();
        this.clientStorageService.set('app_upgrade_time', Date.now());
      } else {
        upgradeTime = (await this.clientStorageService.get('app_upgrade_time')) || 0;
      }

      const MAX_TIME = 1000 * 60 * 60 * 24;
      if (Date.now() - upgradeTime < MAX_TIME) {
        const handleBannerAction = () => {
          if (bc) {
            bc.postMessage('banner');
          }
          this.clientStorageService.set('app_upgrade_time', 0);
          this.bannerService.removeMessage(msg);
        };
        msg.onClick = () => {
          handleBannerAction();
          window.open(Constants.getReleaseNoteURL(Config.version), '_blank');
        };
        msg.onClose = handleBannerAction;
        this.bannerService.addMessage(msg);
      }
    });

    let lastActivityTime = Date.now();
    let activityTimer = null;

    function updateLastActive() {
      lastActivityTime = Date.now();
      if (bc) {
        bc.postMessage('activity');
      } else if (!activityTimer) {
        activityTimer = setTimeout(() => {
          activityTimer = null;
          this.clientStorageService.set('last_user_activity', lastActivityTime);
        }, 5000);
      }
    }

    document.addEventListener('visibilitychange', updateLastActive, { capture: true });
    window.addEventListener('mousedown', updateLastActive, { capture: true });
    window.addEventListener('mousemove', updateLastActive, { capture: true });
    window.addEventListener('keydown', updateLastActive, { capture: true });

    // check if lastActivityTime on this tab or any other open tab of unleash is greater than 10 seconds
    // force the update
    platformUpdatePending$.pipe(untilDestroyed(this)).subscribe(async () => {
      const interval = setInterval(async () => {
        const lat = Math.max(lastActivityTime, (await this.clientStorageService.get('last_user_activity')) || 0);
        const MAX_IDLE_TIME = Config.autoUpdate.maxIdleTime;
        if (Date.now() - lat > MAX_IDLE_TIME) {
          if (isNativeWindow()) {
            this.mainRpc.invokeWith(AutoUpdateNativeRpcInvoker, 'autoupdatenative').applyUpdate();
          } else {
            applyUpdateWeb();
          }
          clearInterval(interval);
        }
      }, 1000);
    });
  }
  private isPlanExist(workspace: Workspace.Workspace) {
    return !!workspace?.plan && workspace?.hasPurchased;
  }

  private async reportAppInstalled() {
    const reported = await this.clientStorageService.get('reported-app-lead');
    if (reported || !location.href.includes('r=desktop')) {
      return;
    }
    const info: any = { Action: 'App Installed' };

    try {
      await this.leadService.track(info);
      await this.clientStorageService.set('reported-app-lead', true);
    } catch {}
  }

  private async reportExtensionInstalled() {
    this.extensionService.current$.pipe(untilDestroyed(this)).subscribe(async (rpc) => {
      if (!rpc) return;

      const reported = await this.clientStorageService.get('lipro-reported');
      if (reported) return;

      let taid;
      const taidText = (<any>window).__taid;
      try {
        taid = JSON.parse(atob(taidText));
      } catch (e) {
        console.error('failed parsing taid', { error: e, taidText });
      }
      if (!taid?.campaign) return;

      const liprof = await rpc.invoke('liprof', false);
      await this.leadService.track({
        Action: 'Lipro',
        Liprof: liprof,
      });
      await this.clientStorageService.set('lipro-reported', true);
    });

    this.extensionService.version$.pipe(untilDestroyed(this)).subscribe(async (s) => {
      if (!s) return;

      const reported = await this.clientStorageService.get('ext-activated');
      if (reported == s) return;

      if (!reported) {
        await this.leadService.track({
          Action: 'Extension Installed',
        });

        pushTag({ event: 'extension-installed', version: s });
      } else {
        pushTag({ event: 'extension-upgraded', version: s, old: reported });
      }
      await this.clientStorageService.set('ext-activated', s);
    });
  }

  private async reportAccountActivated() {
    this.linksService.all$.pipe(untilDestroyed(this)).subscribe(async (links) => {
      if (!links?.length) return;
      const reported = await this.clientStorageService.get('links-rep');
      const connected = JSON.stringify(links.map((x) => ({ app: x.appId, name: x.name })));

      if (reported == connected) return;

      await this.leadService.track({ Action: 'Apps Connected', AppsConnected: connected });

      await this.clientStorageService.set('links-rep', connected);
    });

    this.sessionService.current$.pipe(untilDestroyed(this)).subscribe(async (s) => {
      if (!s || !s.user || (!s.user?.firstName && !s.user.lastName)) return;
      const reported = await this.clientStorageService.get('acc-activated');
      if (!s.workspace?.accountId || reported == s.workspace.accountId) return;

      await this.leadService.track({
        Action: 'Update Session',
        UserEmail: s.user.email,
        UserName: [s.user.firstName, s.user.lastName].filter((x) => x).join(' ') || 'N/A',
        UserId: s.workspace?.accountId,
        WorkspaceId: s.workspace?.id,
        WorkspaceName: s.workspace?.name,
      });

      await this.clientStorageService.set('acc-activated', s.workspace.accountId);
    });
  }

  private async reportInterestingApps() {
    this.bhService.ready$.pipe(untilDestroyed(this)).subscribe(async (r) => {
      if (!r) return;

      const reported = await this.clientStorageService.get<number>('reported-apps');

      if (reported && Date.now() - reported <= 24 * 14 * 1000 * 60 * 60) return;

      const appDomains = (await firstValueFrom(this.apps.all$)).map((x) => x.domains || []).flat();
      const intApps = await this.bhService.getInterestingApps(appDomains);
      const appsUsed = '{' + intApps.map((x) => '"' + x.name + '": ' + x.count).join(',\n') + '}';

      const info: any = {
        Action: 'Interesting Apps',
        AppsUsed: appsUsed,
      };

      await this.leadService.track(info);
      const data = chain(intApps)
        .keyBy((a) => a.name)
        .mapValues((a) => a.count)
        .value();
      this.telemetry.insight('apps_used', data);
      await this.clientStorageService.set('reported-apps', Date.now());
    });
  }

  private async reportLead() {
    const reported = await this.clientStorageService.get('reported-lead');
    if (reported) {
      await this.reportAppInstalled();
      await this.reportAccountActivated();
      await this.reportExtensionInstalled();
      await this.reportInterestingApps();
      return;
    }

    try {
      const taidText = (<any>window).__taid;
      const taid = JSON.parse(atob(taidText));
      const info: any = { Action: 'Web App Started' };
      const geoFetch = fetch('https://reallyfreegeoip.org/json/');

      Object.assign(info, {
        LandingPage: taid.landingPage,
        Campaign: taid.campaign,
        ClickId: taid.clickId,
        Device: taid.device,
        SubNetwork: taid.subNetwork,
        Network: taid.network,
        Keyword: taid.keyword,
        Placement: taid.placement,
        Referrer: taid.referrer,
        Taid: taidText,
        UserAgent: taid.ua,
      });

      try {
        const geoResp = await geoFetch;
        if (geoResp.status == 200) {
          const j = await geoResp.json();
          info.Country = j.country_name;
          info.Region = j.region;
          info.City = j.city;
        }
      } catch {}

      await this.leadService.track(info);
      await this.clientStorageService.set('reported-lead', true);
      await this.reportAppInstalled();
      await this.reportAccountActivated();
      await this.reportExtensionInstalled();
      await this.reportInterestingApps();
    } catch {}
  }

  private async checkForAppsUpdates() {
    if (this.isNative) {
      return;
    }
    const allApps = await firstValueFrom(this.apps.all$);
    if (!allApps) {
      return;
    }

    const links = await firstValueFrom(this.linksService.all$);
    if (!links) {
      return;
    }

    const appsIds = [...new Set(links.map((l) => l.appId))];
    if (!(<any>window).__isExtensionPage) {
      navigator.serviceWorker?.getRegistrations().then((regs) => {
        (<any>window).__serviceWorkerAction(regs, async () => {
          const mc = new MessageChannel();
          mc.port2.onmessage = (m) => {
            const updatedApps = allApps.filter((x, i) => m.data.results[i] === 'installed');
            if (updatedApps.length) {
              this.services.invoke('appshost.reload', updatedApps);
            }
          };
          regs[0].active.postMessage({ type: 'unleash:sw:update-apps', port: mc.port1, apps: appsIds }, [mc.port1]);
          mc.port1.start();
          mc.port2.start();
        });
      });
    }
  }

  async getControllerVersion(): Promise<string> {
    if (!navigator.serviceWorker?.controller) {
      console.log('controller version resolved to none, no controller');
      return;
    }

    try {
      const r = await fetch('/__controller');
      if (r.status != 200) throw new Error('bad response from controller version' + r.status);
      const ver = await r.json();
      console.log('controller version resolved', ver.version, ver.source);
      return ver.version;
    } catch (e) {
      console.error('controller version resolved null due to error', e);
    }
  }

  async ngAfterViewInit() {
    if (!isProdEnv() && !this.isNative) {
      const { TestimComponent } = await import('./testim-module/components/testim.component');
      this.testimContainer.createComponent(TestimComponent);
    }
  }

  async ngOnInit() {
    let first = true;
    this.loader.ready$.pipe(untilDestroyed(this)).subscribe(async (s) => {
      const shouldReport = first;
      if (first) {
        performanceCheckpoint('first_app_ready');
        pushTag({ event: 'ng-page-load-end', track_time: performance.now() - (<any>self).__START_TIME });
        document.getElementById('__initialLoader').remove();
        this.appService.setReadyTime();
      }

      first = false;
      setTimeout(async () => {
        this.ready = s;
        this.cdr.detectChanges();
        if (shouldReport) {
          return this.reportLoadingTime();
        }
      }, 0);

      if (this.embedService && !(await firstValueFrom(this.embedService.verified$))) {
        this.globalError.error =
          'Invalid origin for embed, Please make sure the origin is added to the list of allowed origins for the given embedded';
        this.routerService.navigateByUrl('/error');
        return;
      }
    });

    this.styleService.init();
    this.handleCaptureEvents();

    if (location.hostname != 'localhost' && !this.isNative && !this.isEmbed && !Config.customDomain) {
      this.swPrefetch.push('https://' + location.hostname.replace('app.', '') + '/install-sw.html');
      this.swPrefetch.push('https://' + location.hostname.replace('app.', 'www.') + '/install-sw.html');
    }
  }

  private async reportLoadingTime() {
    const loadTime = Date.now() - this.appService.startTime;
    const session = await firstValueFrom(this.sessionService.current$);
    const extensionInstalled = !!(await firstValueFrom(this.extensionService.current$));
    const isLauncher = location.pathname == '/bar' || (await firstValueFrom(this.appService.windowStyle$)) != 'standard';
    this.telemetry.insight('loading-time', {
      loadTime,
      url: location.href,
      browser: this.appService.browser,
      platform: this.appService.platform,
      version: Config.version,
      workspace: session?.workspace?.id,
      accountId: session?.workspace?.accountId,
      extensionInstalled,
      mode: isLauncher ? 'Launcher' : 'Search-Page',
    });
  }

  private async checkForClientUpdates() {
    if (this.isNative) return;

    const last = (await this.clientStorageService.get<number>('last-update-check')) || 0;
    let time = Config.autoUpdate.probeInterval;

    if (this.isEmbed && !(await this.embedService.isExternalWebSite())) time = Config.autoUpdate.extensionProbeInterval || time;

    if (Date.now() - last < Config.autoUpdate.probeInterval) return;
    await this.clientStorageService.set('last-update-check', Date.now());
    navigator.serviceWorker?.getRegistrations().then((regs) => {
      const workerUpdate = (<any>window).__serviceWorkerUpdate;
      if (workerUpdate) {
        workerUpdate(regs);
      }
    });
  }

  private handleCaptureEvents() {
    const hkey = (e) => {
      let key = e.key;
      if (e.ctrlKey && key != 'Control') key = 'CTRL+' + key;
      if (e.shiftKey && key != 'Shift') key = 'SHIFT+' + key;
      if (e.altKey && key != 'Alt') key = 'ALT+' + key;
      this.services.invoke('capture.event', 'key', key);
    };
    const rclick = (e) => {
      this.services.invoke('capture.event', 'rightclick', e.clientX, e.clientY);
    };

    const mmove = () => {
      this.services.invoke('capture.event', 'move');
    };

    const mclick = (e) => {
      this.services.invoke('capture.event', 'click', e.clientX, e.clientY);
    };

    const focus = (e) => {
      this.services.invoke('capture.event', 'focus');
    };

    const blur = (e) => {
      this.services.invoke('capture.event', 'blur');
    };

    this.services.handle('capture.disable', () => {
      window.removeEventListener('keydown', hkey, { capture: true });
      window.removeEventListener('click', mclick, { capture: true });
      window.removeEventListener('contextmenu', rclick, { capture: true });
      window.removeEventListener('mousemove', mmove, { capture: true });
      window.removeEventListener('focus', focus, { capture: true });
      window.removeEventListener('blur', blur, { capture: true });
    });

    this.services.handle('capture.enable', () => {
      window.addEventListener('keydown', hkey, { capture: true });
      window.addEventListener('click', mclick, { capture: true });
      window.addEventListener('contextmenu', rclick, { capture: true });
      window.addEventListener('mousemove', mmove, { capture: true });
      window.addEventListener('focus', focus, { capture: true });
      window.addEventListener('blur', blur, { capture: true });
    });
  }
}
