import { EventInfo } from './event-info';

export const categories = {
  interaction_click: 'interaction.click',
  interaction_doubleclick: 'interaction.doubleclick',
  interaction_scroll: 'interaction.scroll',
  push_notification: 'push_notification.app',
  pageview: 'pageview',
  preview: 'preview',
  api_signup: 'api.signup',
  interaction: 'interaction',
  search: 'search',
  resources: 'resources',
  siteLinks: 'siteLinks',
  onboarding: 'onboarding',
  focus: 'focus',
  links: 'links',
  keyboard: 'keyboard',
  suggestions: 'suggestion_box',
  suggestion_tags: 'suggestion_tags',
  fyi_item: 'fyi_item',
  notification_popup: 'notification_popup',
  web_search: 'web_search',
  calculator: 'calculator',
  action_list: 'action_list',
  window: 'window',
  walkthrough: 'walkthrough',
  command_bar: 'command_bar',
  status_view: 'status_view',
  workspace_members: 'workspace_members',
  subscribe_to_plan: 'subscribe_to_plan',
  app: 'app',
  collections: 'collections',
  hub_error_modal: 'hub_error_modal',
  upload: 'upload',
  drag_drop: 'drag_drop',
  filter_suggestion: 'filter_suggestion',
  assistant: 'assistant',
  commands_menu: 'commands_menu',
};

export const eventsMap: { [key: string]: Partial<EventInfo> | { [prop: string]: Partial<EventInfo> } } = {
  launch: {
    category: categories.app,
    name: 'activate',
    web: {
      target: 'web',
      label: 'web',
    },
    embed: {},
  },
  pageview: {
    category: categories.pageview,
    name: 'pageview',
  },
  status_view: {
    category: categories.status_view,
    name: 'show',
  },
  interaction_click: {
    category: categories.interaction_click,
    name: 'status_view',
  },
  full_app: {
    category: categories.interaction_click,
    name: 'full_app',
  },
  select_plan: {
    category: categories.interaction_click,
    name: 'select_plan',
    location: {
      title: 'plan_selection',
    },
  },
  side_panel: {
    category: categories.interaction_click,
    name: 'side_panel',
  },
  link_visibility: {
    category: categories.interaction_click,
    name: 'link_visibility',
  },
  set_subscription: {
    category: categories.interaction_click,
    name: 'set_subscription',
    location: {
      title: 'plan_selection',
    },
  },
  subscribe_to_plan: {
    category: categories.subscribe_to_plan,
  },
  upgrade_plan: {
    category: categories.interaction_click,
    name: 'upgrade',
  },
  window: {
    category: categories.window,
  },
  push_notification: {
    category: categories.push_notification,
  },
  onboarding: {
    category: categories.interaction_click,
    name: 'onboarding_action',
    suggested_apps: {
      category: categories.onboarding,
      nonInteraction: true,
      name: 'suggested_apps',
    },
    interesting_apps: {
      category: categories.onboarding,
      nonInteraction: true,
      name: 'interesting_apps',
    },
    toggle_app: {
      name: 'toggle_app',
    },
    connect_start: {
      name: 'connect_start',
    },
    cancel_apps_auth: {
      target: 'cancel_apps_auth',
    },
    connect_skip: {
      target: 'connect_skip',
    },
    open_unleash: {
      target: 'open_unleash',
    },
    back_to_apps: {
      target: 'back_to_apps',
    },
    open_video: {
      target: 'open_video',
    },
    close_video: {
      target: 'close_video',
    },
    back_to_previous: {
      name: 'user_details',
      label: 'back',
    },
    user_answer: {
      category: 'onboarding',
      name: 'user_details',
      label: 'question',
      target: 'position: value',
      jsonData: { the_user_answer: {} },
    },
  },
  apps_linking: {
    category: categories.onboarding,
    name: 'apps_linking',
    start: {
      label: 'start',
    },
    connect: {
      label: 'connect',
    },
    skip: {
      label: 'skip',
    },
    next: {
      label: 'next',
    },
    connect_another: {
      label: 'connect_another',
    },
    error: {
      label: 'error',
    },
    connected: {
      label: 'connected',
    },
    ready: {
      label: 'ready',
    },
  },
  search_bar: {
    category: categories.interaction_click,
    name: 'search_bar',
    pin: {},
    hub: {
      target: 'hub',
    },
    search_bar: {
      target: 'search_bar',
    },
    feedback: {
      target: 'feedback',
    },
    clear: {
      target: 'clear',
    },
    filter: {},
    placeholder: {
      name: 'bar_placeholder',
    },
  },
  hub_error_modal: {
    category: categories.hub_error_modal,
  },
  hub_error: {},
  hub_error_retry: {
    category: categories.interaction_click,
    target: 'retry',
    name: 'data_error',
  },
  hub_side_menu: {
    category: categories.interaction_click,
    name: 'menu_panel',
    shut_down: {
      target: 'shut_down',
    },
    profile_button_open: {
      name: 'navigation_tree',
      target: 'profile_menu',
    },
    profile_button_close: {
      name: 'profile_menu',
      target: 'close_menu',
    },
    profile_select_workspace: {
      name: 'profile_menu',
      target: 'select_profile',
    },
    preferences: {
      name: 'menu_panel',
      target: 'preferences',
    },
    log_out: {
      name: 'profile_menu',
      target: 'log_out',
    },
    apps: {
      target: 'apps',
    },
    feedback: {
      target: 'feedback',
    },
    release_notes: {
      target: 'release_notes',
    },
    fyi: {
      target: 'fyi',
    },
    calendar: {
      target: 'calendar',
    },
    shared_files: {
      target: 'shared_files',
    },
    favorites: {
      target: 'favorites',
    },
    recently_viewed: {
      target: 'recently_viewed',
    },
    collections: {
      target: 'collections',
    },
    collection_view: {
      target: 'collection_view',
    },
    assistant: {
      target: 'assistant',
    },
    assistants: {
      target: 'assistants',
    },
    assistants_slack: {
      target: 'assistants_slack',
    },
    assistants_zendesk: {
      target: 'assistants_zendesk',
    },
  },
  apps_scroll: {
    category: categories.interaction_scroll,
    name: 'app_actions',
    location: {
      title: 'apps',
    },
  },
  title_bar: {
    category: categories.interaction_click,
    name: 'header',
    close: {
      target: 'close',
    },
    back: {
      target: 'back',
    },
    doubleclick: { category: categories.interaction_doubleclick },
  },
  tree: {
    category: categories.interaction_click,
    name: 'navigation_tree',
    click: {
      target: 'select',
    },
    toggle: {},
  },
  header: {
    category: categories.interaction_click,
    name: 'header',
    minimize: {
      target: 'minimize',
    },
    close: {
      target: 'close',
    },
    maximize: {
      target: 'maximize',
    },
    unmaximize: {
      target: 'unmaximize',
    },
    pin: {
      target: 'pin',
    },
    unpin: {
      target: 'unpin',
    },
    fyi: {
      target: 'fyi',
    },
    help_menu: {
      target: 'help_menu',
    },
  },

  desktop_signup: {
    location: {
      title: 'sign up desktop',
    },
    click: {
      name: 'go_to_signup',
      category: categories.interaction_click,
    },
    pageview: {
      name: 'pageview',
      category: categories.pageview,
    },
  },
  signup: {
    category: categories.interaction_click,
    location: {
      title: 'sign_up',
    },
    legal_terms: {
      name: 'legal_terms',
    },
    privacy_policy: {
      name: 'privacy_policy',
    },
    provider: {
      name: 'signup_button',
    },
    switch_mode: {
      name: 'switch_signup_mode',
    },

    focus: {
      category: categories.focus,
      name: 'lost_focus',
      nonInteraction: true,
    },
  },
  pending_invite: {
    category: categories.interaction_click,
    name: 'pending_inv_action',
    mail_support: {
      target: 'mail_support',
    },
    go_back: {
      target: 'go_back',
    },
  },
  app_item: {
    category: categories.interaction_click,
    name: 'app_actions',
    connect: {
      target: 'connect',
    },
    view_links: {
      target: 'view_links',
    },
    connect_pc: {
      label: 'pc',
      location: { title: 'connect' },
    },
  },
  context_menu: {
    name: 'resource_actions',
    target: 'mouse_click',
    open: {
      category: categories.interaction_click,
    },
    open_from_preview: {
      category: categories.interaction_click,
      target: categories.preview,
    },
    download: {
      category: categories.resources,
      label: 'download',
    },
  },
  apps_suggestion_box: {
    category: categories.interaction_click,
    name: 'apps_suggestion_box',
    label: 'send',
  },
  app_actions: {
    category: categories.interaction_click,
    name: 'app_actions',
    target: 'view_links',
  },
  auth_note: {
    category: categories.interaction_click,
    name: 'authorization_note',
    cancel: {
      target: 'cancel',
    },
  },
  auth_note_impression: {
    name: 'authorization_note',
    category: 'display_modal',
  },
  workspace: {
    category: categories.interaction_click,
    delete_workspace: {
      name: 'delete_workspace',
    },
    delete_workspace_prompt: {
      name: 'delete_workspace_prompt',
    },
    leave_workspace_prompt: {
      name: 'delete_workspace_prompt',
    },
    leave_workspace: {
      name: 'leave_workspace',
    },
    members_lookup: {
      category: categories.workspace_members,
      name: 'lookup',
    },
    upload_photo: {
      name: 'upload_photo',
    },
    upload_photo_failed: {
      category: categories.status_view,
      name: 'show',
      label: 'upload_failed',
    },
    upload: {
      category: categories.upload,
    },
    rename: {
      name: 'rename',
    },
    member_access_level: {
      name: 'member_row',
      target: 'member_access_menu',
    },
    member_access_level_change: {
      name: 'member_access_menu',
    },
    remove_workspace_member_prompt: {
      name: 'remove_workspace_member_prompt',
    },
  },
  settings: {
    category: categories.interaction_click,
    go_to_settings: {
      name: 'go_to_settings',
      location: { title: 'people_empty_state' },
    },
    hris_integration: {
      name: 'hris_integration',
    },
    pageview: {
      category: categories.pageview,
      name: 'pageview',
    },
    settings_menu: {
      name: 'settings_menu',
    },
    delete_account: {
      name: 'delete_account',
    },
    delete_account_prompt: {
      name: 'delete_account_prompt',
    },
    popup_clicks: {},
    upgrades: {
      name: 'download_button',
    },
    start_editing: {
      name: 'shortcut_keys_combination',
    },
    synonyms_file_upload: {
      category: categories.upload,
      name: 'show',
      label: 'synonyms_file_upload',
    },
    synonyms_file_process_error: {
      category: categories.status_view,
      name: 'show',
      label: 'upload_failed',
    },
    set_auto_hide_key_combination: {},
    show_on_dock: {},
    appearance: {},
    pin_to_taskbar: {},
    startup_launch: {},
    auto_hide: {},
  },
  release_notes: {
    category: categories.interaction_click,
    location: {
      title: 'release_notes',
    },
    load: {
      name: 'go_to_release_notes',
    },
  },
  feedback: {
    category: categories.interaction_click,
    name: 'feedback_box',
    label: 'send',
    location: {
      title: 'feedback',
    },
  },
  link_action: {
    category: categories.interaction_click,

    name: 'link_actions',
    add_link: {
      target: 'add_link',
    },
    edit_link: {
      target: 'edit_link',
    },
    disconnect: {
      target: 'disconnect',
    },
    reconnect: {
      target: 'reconnect',
    },
    cancel_link_prompt: {
      name: 'cancel_link_prompt',
    },
    no_member_selected: {
      name: 'no_member_selected_link_prompt',
    },
    update_link_access: {
      name: 'update_link_access',
    },
    delete_link_prompt: {
      name: 'delete_link_prompt',
    },
    shared_link_members: {
      name: 'shared_link_members',
    },
    link_access: {
      name: 'link_access',
    },
  },
  edit_link: {
    category: categories.interaction_click,
    name: 'edit_link',
    manage_access: {
      target: 'manage_access',
    },
    link_name: {
      target: 'link_name',
    },
    delete_link: {
      target: 'delete_link',
    },
    link_color: {
      target: 'link_color',
    },
  },
  links: {
    category: categories.links,
    connect_start: {
      name: 'connect_start',
    },
    oauth_start: {
      name: 'oauth_start',
    },
    oauth_success: {
      name: 'oauth_success',
    },
    oauth_fail: {
      name: 'oauth_fail',
    },
    cancel: {
      name: 'cancel',
    },
    success: {
      name: 'success',
    },
    error: {
      name: 'error',
    },
    refresh_stale: {
      name: 'refresh_stale',
    },
  },
  preferences_keyboard: {
    category: categories.interaction,

    set_keys_combination: {
      name: 'set_keys_combination',
    },
  },
  apps_no_results: {
    category: 'status_view',
    name: 'show',
    label: 'no_results',
  },
  resync_data_suggestion: {
    category: 'push_notification.app',
    name: 'show',
    target: 'remote:resync_data',
    label: 'resync_data_suggestion',
  },
  resync_data_suggestion_click: {
    category: 'push_notification.app',
    name: 'sync | no_sync',
    target: 'remote:resync_data',
    label: 'resync_data_suggestion_click',
  },
  resync_click_interaction: {
    category: 'interaction.click',
    name: 'push_notification',
    target: 'sync | no_sync',
    label: 'resync_data_suggestion',
  },
  app_bar_click: {
    category: 'interaction.click',
    name: 'filter_suggestion',
  },
  fyi_action: {
    category: 'interaction.click',
    name: 'fyi_item',
  },
  fyi_dismiss_all: {
    category: 'interaction.click',
    name: 'fyi_dismiss_all',
  },
  fyi_dismiss: {
    name: 'fyi_dismiss',
  },
  fyi_impression: {
    category: categories.fyi_item,
    name: 'impression',
  },
  apps_view_mode: {
    category: categories.interaction_click,
    name: 'apps_view_mode',
    location: {
      title: 'apps',
    },
    my_apps: {
      target: 'my_apps',
      location: {
        title: 'apps.all',
      },
    },
    all_apps: {
      target: 'all_apps',
      location: {
        title: 'apps.linked',
      },
    },
  },
  signup_result: {
    category: categories.api_signup,
    nonInteraction: true,
    completed: {
      name: 'completed',
    },
    error: {
      name: 'error',
    },
  },
  search_apps: {
    category: categories.search,
    target: 'search_apps',
    name: 'app_search',
  },
  search: {
    category: categories.search,
    start: {
      name: 'start',
    },
    end: {
      name: 'end',
    },
    erased: {
      name: 'erased',
    },
    stopped: {
      name: 'stopped_search',
    },
    filtered: {
      name: 'search_filtered',
    },
    clear: {
      name: 'clear',
    },
    remove_filter: {
      category: categories.filter_suggestion,
      name: 'remove',
    },
  },
  filters: {
    add: {
      category: categories.interaction_click,
      name: 'filter_suggestion',
    },
    remove: {
      category: categories.filter_suggestion,
      name: 'remove',
    },
    set: {
      category: categories.interaction_click,
      name: 'filter_suggestion',
    },
    clearall: {
      category: categories.filter_suggestion,
      name: 'remove',
    },
  },
  resources: {
    category: categories.resources,
    impression: {
      name: 'impression',
    },
  },
  launcher: {
    category: categories.interaction_click,
    avatar: {
      name: 'profile_menu',
    },
  },
  results: {
    category: categories.resources,
    impression: {
      name: 'impression',
    },
    redirect: {
      name: 'redirect',
    },
    action: {
      name: 'action',
    },
    openSiteLink: {
      category: categories.siteLinks,
      name: 'open_site_link',
    },
    openWith: {
      category: categories.interaction_click,
      name: 'open_with',
    },
    preview_box: {},
    share_menu: {
      category: categories.interaction_click,
      name: 'share_menu',
    },
  },
  local_actions: {
    open: {
      category: categories.action_list,
      name: 'open',
    },
    look_up: {
      category: categories.action_list,
      name: 'look_up',
      location: {
        title: 'action_list',
      },
    },
  },
  calendar: {
    category: categories.resources,
    impression: {
      name: 'impression',
    },
    redirect: {
      name: 'redirect',
    },
    action: {
      name: 'action',
    },
    interaction_click: {
      category: categories.interaction_click,
      name: 'resource_actions',
    },
  },
  home: {
    category: categories.resources,
    impression: {
      name: 'impression',
    },
    redirect: {
      name: 'redirect',
    },
    action: {
      name: 'action',
    },
    location: {
      title: 'home',
    },
    interaction_click: {
      category: categories.interaction_click,
      name: 'resource_actions',
    },
  },
  home_page: {
    category: categories.interaction_click,
    widget_title_see_all: {
      name: 'home_widget',
      target: 'see_all',
      location: {
        title: 'home',
      },
    },
    widget_calender_date_selection: {
      name: 'home_widget',
      target: 'see_all',
      label: 'calendar',
      location: {
        title: 'home',
      },
    },
    drag_widget: {
      category: 'home_widget',
      name: 'drag',
      location: {
        title: 'home',
      },
    },
    resize_widget: {
      category: 'home_widget',
      name: 'resize',
      location: {
        title: 'home',
      },
    },
    search_bar: {
      name: 'search_bar',
      target: 'clear',
      location: {
        title: 'home',
      },
    },
  },
  shared_files: {
    category: categories.resources,
    impression: {
      name: 'impression',
    },
    redirect: {
      name: 'redirect',
    },
    action: {
      name: 'action',
    },
    location: {
      title: 'shared files',
    },
    interaction_click: {
      category: categories.interaction_click,
      name: 'resource_actions',
    },
  },
  favorites: {
    category: categories.resources,
    impression: {
      name: 'impression',
    },
    redirect: {
      name: 'redirect',
    },
    action: {
      name: 'action',
    },
    location: {
      title: 'favorites',
    },
    interaction_click: {
      category: categories.interaction_click,
      name: 'resource_actions',
    },
  },
  command_bar: {
    category: categories.command_bar,
    open: { name: 'open' },
    cancel: { name: 'cancel' },
    expend_group: { name: 'expend_group' },
    look_up: { name: 'look_up' },
    commands: {
      category: 'commands',
      name: 'redirect',
    },
    click: { category: categories.interaction_click, name: 'command_bar' },
  },
  recently_viewed: {
    category: categories.resources,
    impression: {
      name: 'impression',
    },
    redirect: {
      name: 'redirect',
    },
    action: {
      name: 'action',
    },
    location: {
      title: 'recently viewed',
    },
    interaction_click: {
      category: categories.interaction_click,
      name: 'resource_actions',
    },
  },
  signup_button: {
    category: categories.interaction_click,
    name: 'signup_button',
  },
  schedule_demo: {
    category: categories.interaction_click,
    name: 'schedule_demo',
  },
  keypress: {
    category: categories.keyboard,
    name: 'key_down',
  },
  suggestion_tags: {
    category: categories.suggestion_tags,
  },
  suggestions: {
    category: categories.suggestions,
  },
  notification_popup: {
    impression: {
      category: categories.notification_popup,
      name: 'impression',
    },
    click: {
      category: categories.interaction_click,
    },
  },
  web_search: {
    category: categories.web_search,
    start: {
      name: 'start',
    },
    end: {
      name: 'end',
    },
    conversion: {
      name: 'redirect',
    },
  },
  calculator: {
    category: categories.calculator,
    results: {
      name: 'results',
    },
    interaction: {
      name: 'interaction',
    },
  },
  drag_drop: {
    interaction: {
      name: 'interaction',
    },
    conversion: {
      name: 'conversion',
    },
    clicks: {
      name: 'clicks',
    },
  },
  walkthrough: {
    show: {
      category: categories.walkthrough,
      name: 'show',
    },
    interaction: {
      category: categories.interaction_click,
      name: categories.walkthrough,
    },
    conversion: {
      category: categories.walkthrough,
    },
  },
  people_view: {
    name: 'resource_actions',
    target: 'people_view',
    category: categories.interaction_click,
    copy_email: {
      name: 'person_details',
      target: 'email',
      location: { title: 'people' },
    },
    copy_phone: {
      name: 'person_details',
      target: 'phone_number',
      location: { title: 'people' },
    },
    open: {
      label: 'open_url',
      location: {
        title: 'people',
      },
    },
    open_dropdown: {
      name: 'people_dropdown',
      label: 'open',
      location: { title: 'people' },
      target: undefined,
    },
  },
  pagination: {
    category: categories.resources,
    name: 'pagination',
  },
  welcome_to_unleash_popup: {
    category: 'interaction.click',
    name: 'welcome_to_unleash',
    target: 'download | continue_in_browser | show_me_around',
    location: { title: 'home' },
  },
  search_page: {
    category: categories.interaction_click,
    recent_searches: {
      name: 'recent_searches',
      target: 'recent_search',
      location: { title: 'search' },
    },
    recent_searches_clear: {
      name: 'recent_searches',
      target: 'clear_all',
      location: { title: 'search' },
    },
  },
  results_tags: {
    category: categories.resources,
    action: {
      name: 'action',
      target: 'mouse_click',
    },
    download: {
      category: categories.interaction_click,
      label: 'download',
      name: 'resources_tags',
    },
    open: {
      category: categories.interaction_click,
      label: 'open',
      name: 'resources_tags',
    },
  },
  go_links: {
    screen: {
      category: 'display_modal',
    },
    action: {
      category: categories.interaction_click,
    },
    errors: {
      category: categories.status_view,
      name: 'show',
    },
    open_menu: {
      category: categories.interaction_click,
      name: 'resource_actions',
      label: 'open_menu',
    },
  },
  quick_links: {
    screen: {
      category: 'display_modal',
    },
    menu: {
      category: categories.interaction_click,
      name: 'quick_link_menu',
    },
    action: {
      category: categories.interaction_click,
    },
    errors: {
      category: categories.status_view,
      name: 'show',
    },
  },
  collections: {
    category: categories.interaction_click,
    search_bar: {
      name: 'search_bar',
      target: 'save_as_collection',
    },
    open_collection: {
      name: 'status_view',
      target: 'open',
      label: 'collection_saved_notification',
    },
    open_menu: {
      name: 'collection_actions',
      target: 'open_menu',
    },
    collection_actions: {
      category: categories.interaction_click,
      name: 'collection_actions',
    },
    menu_action: {
      category: categories.collections,
      name: 'action',
      target: 'context_menu_click',
    },
    menu_action_preview: {
      target: 'preview',
      name: 'resource_actions',
    },
    create: {
      name: 'collections_screen_actions',
    },
    sort: {
      name: 'collections_screen_actions',
      target: 'sort_collection',
    },
    delete_prompt: {
      name: 'delete_collection_prompt',
    },
    delete_resource_prompt: {
      name: 'delete_resource_prompt',
    },
    title: {
      name: 'edit_collection',
      target: 'collection_name',
    },
    description: {
      name: 'edit_collection',
      target: 'collection_description',
    },
    more_filters: {
      name: 'filter_suggestion',
      target: 'more_filters',
    },
    clear_filters_prompt: {
      name: 'clear_filters_prompt',
    },
    collection_emoji: {
      name: 'edit_collection',
      target: 'collection_emoji',
    },
    collection_tag: {
      name: 'edit_collection',
      target: 'collection_tags',
    },
    collection_save: {
      name: 'edit_collection',
    },
    collection_share_open: {
      name: 'edit_collection',
      target: 'collection_share',
    },
    collection_share: {
      name: 'collection_share',
    },
    collection_share_prompt: {
      name: 'collection_share_prompt',
    },
    drag_collection: {
      category: 'edit_collection',
      name: 'drag',
      target: 'resource',
    },
    remove_item_collection: {
      name: 'edit_collection',
      target: 'collection_link_remove',
      label: 'remove',
    },
    select_remove_item_collection: {
      name: 'edit_collection',
      target: 'collection_link_remove',
    },
    add_collection: {
      name: 'add_collection',
    },
    collection_link: {
      name: 'edit_collection',
      target: 'collection_link',
    },
    save_changes_live_collection: {
      name: 'edit_collection',
    },
    new_card: {
      name: 'new_card',
    },
    new_folder: {
      name: 'new_folder',
    },
    card_commands_menu: {
      category: categories.collections,
      name: 'action',
      target: 'commands_menu',
    },
    drag_drop: {
      category: categories.drag_drop,
      name: 'edit_collection',
      target: 'resource',
      label: 'add',
    },
    upload: {
      category: categories.upload,
      name: 'edit_collection',
      target: 'resource',
      label: 'add',
    },
    layout: {
      category: categories.interaction_click,
      name: 'layout',
    },
    redirect: {
      category: categories.resources,
      name: 'redirect',
    },
    full_size: {
      name: 'collection_actions',
      target: 'full_size',
    },
  },
  wikis: {
    category: categories.interaction_click,
    empty_state_pageview: {
      category: categories.pageview,
      name: 'pageview',
      location: {
        title: 'wikis_empty_state',
      },
    },
    empty_state_new_wiki: {
      name: 'create_new_wiki',
      location: {
        title: 'wikis_empty_state',
      },
    },
    publish_card: {
      name: 'edit_card',
      target: 'publish',
    },
  },
  assistant: {
    category: categories.interaction_click,
    remove_collection: {
      name: 'remove_collection_prompt',
    },
    leave_page: {
      name: 'leave_page_prompt',
    },
    assistant_actions: {
      category: categories.interaction_click,
      name: 'assistant_actions',
    },
    menu_action: {
      category: categories.assistant,
      name: 'action',
      target: 'context_menu_click',
    },
    open_menu: {
      name: 'assistant_actions',
      target: 'open_menu',
    },
    empty_state: {
      category: categories.pageview,
      name: 'pageview',
    },
    add_to: {
      category: categories.interaction_click,
    },
  },
  banner: {
    category: categories.interaction_click,
    click: {},
    close: {
      target: 'close',
    },
  },
  preview: {
    category: categories.interaction_click,
    column_open: {
      label: 'open_column_mode',
      name: 'columns_button',
    },
    column_close: {
      label: 'close_column_mode',
      name: 'columns_button',
    },
    resource: {
      category: categories.resources,
      name: 'preview',
    },
    resource_action: {
      name: 'resource_actions',
      target: 'preview',
    },
    open_preview: {
      target: 'preview',
      label: 'open_preview',
    },
    close_preview: {
      target: 'preview',
      label: 'close_preview',
    },
  },
  enter_name: {
    category: categories.interaction_click,
    name: 'enter_name',
    target: 'continue',
    location: { title: 'enter_name' },
  },
  invite_to_workspace: {
    category: categories.interaction_click,
    name: 'invite_to_workspace',
  },
  invite_to_workspace_link: {
    category: categories.interaction_click,
    name: 'invite_to_workspace_link',
    label: 'copy',
  },
  enter_workspace_name: {
    category: categories.interaction_click,
    name: 'enter_workspace_name',
  },
  join_workspace: {
    category: categories.interaction_click,
    name: 'join_workspace',
    target: 'continue',
    location: {
      title: 'join_workspace',
    },
  },
  summary: {
    impression: {
      category: 'summary',
      name: 'impression',
    },
    action: {
      category: categories.interaction_click,
      name: 'resource_actions',
      target: 'summary',
    },
    conversion: {
      category: categories.resources,
      name: 'action',
      label: 'summary',
    },
    redirect: {
      category: categories.resources,
      name: 'redirect',
    },
    copy: {
      category: categories.resources,
      name: 'action',
      label: 'copy',
    },
  },
  sort: {
    category: categories.interaction_click,
    name: 'sort',
  },
  home_tabs: {
    create: {
      category: categories.interaction_click,
      name: 'multiple_homepages',
      target: 'new_tab',
      label: 'create_tab',
    },
    drag: {
      category: 'multiple_homepages',
      name: 'drag',
    },
    edit: {
      category: categories.interaction_click,
      name: 'multiple_homepages',
      label: 'edit_tab',
    },
    delete: {
      category: categories.interaction_click,
      name: 'delete_homepage_prompt',
    },
    switch: {
      category: categories.interaction_click,
      name: 'multiple_homepages',
      target: 'switch_tab',
    },
    share: {
      category: categories.interaction_click,
      name: 'homepage_share',
    },
    pin: {
      category: categories.interaction_click,
      name: 'collection_actions',
      target: 'pin',
    },
    selected_tab: {
      category: categories.interaction_click,
      name: 'collection_pin',
    },
  },
  cards_verifications: {
    verify_card_prompt: {
      category: categories.interaction_click,
      name: 'verify_card_prompt',
    },
  },
  commands_menu: {
    category: categories.commands_menu,
    open: {
      name: 'open',
    },
  },
};
