import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UTypingTypographyComponent } from './u-typing-typography.component';

@NgModule({
  declarations: [UTypingTypographyComponent],
  imports: [CommonModule],
  exports: [UTypingTypographyComponent],
})
export class UTypingTypographyModule {}
