<ng-container>
  <main #appsMain>
    <section class="filter-container">
      <tabs
        #tabs
        [tabChange]="tabChange$ | async"
        [(ngModel)]="activeTab"
        [initialValue]="activeTab"
        (ngModelChange)="onTabChange($event)"
        class="tabs"
      >
        <tab [title]="'All Apps'" [id]="'all'"> </tab>
        <tab [title]="'My Apps'" [id]="'linked'"> </tab>
        <tab [title]="'Local'" *ngIf="local?.length" [id]="'local'"> </tab>
      </tabs>
    </section>

    <ng-scrollbar
      *ngIf="!(loading$ | async); else loader"
      visibility="hover"
      class="scroll-area apps-container"
      [ngClass]="{ 'empty-state-scroll': !(selectedTabItems || [] | apps: { query: searchQuery }).length > 0 }"
    >
      <ng-container *ngIf="!searchQuery; else searchResults" [ngSwitch]="activeTab">
        <section class="app-list-container content-container" *ngSwitchCase="'all'">
          <app-list *ngIf="recommended?.length" class="app-list" [items]="recommended" [title]="'Top picks for You'"></app-list>
          <app-list
            *ngIf="notRecommended?.length"
            class="app-list"
            [items]="notRecommended"
            [title]="notRecommendedTitle.title"
            [titleEnd]="notRecommendedTitle.titleEnd"
          ></app-list>
        </section>

        <section class="app-list-container content-container" *ngSwitchCase="'linked'">
          <app-list
            *ngIf="linked?.length !== 0; else myAppsEmptyState"
            class="app-list"
            [searchDetector]="searchQuery"
            [items]="linked"
            [title]="linkedTitle.title"
            [titleEnd]="linkedTitle.titleEnd"
          ></app-list>
          <ng-template #myAppsEmptyState>
            <app-empty-state
              class="empty-state-container"
              *ngIf="filter.linked && showFilteredList"
              (actionClick)="connectApps()"
              clickTrack="link_action.add_link"
              [title]="'No apps connected'"
              [subtitle]="'Press the button below to browse apps'"
              buttonText="Connect"
              [icon]="emptyStateIcon"
            ></app-empty-state>
          </ng-template>
        </section>

        <section class="app-list-container content-container" *ngSwitchCase="'local'">
          <app-list *ngIf="local?.length" class="app-list" [items]="local" [title]="'Local'"></app-list>
        </section>
      </ng-container>

      <ng-template #searchResults>
        <section
          class="app-list-container content-container"
          *ngIf="(selectedTabItems || [] | apps: { query: searchQuery }).length > 0; else noResults"
        >
          <app-list
            class="app-list"
            [filter]="filter"
            [searchDetector]="searchQuery"
            [items]="selectedTabItems | apps: { query: searchQuery }"
            [title]="searchTitle"
          ></app-list>
        </section>
        <ng-template #noResults>
          <section class="no-results">
            <h2 class="no-results-title">
              We're Sorry! <br />
              No results found.
            </h2>
            <u-icon class="icon" [model]="{ type: 'img' }" [inSvg]="'./assets/bar/apps/no-results-hub.svg'"></u-icon>
          </section>
        </ng-template>
      </ng-template>

      <section class="apps-container-bottom content-container">
        <p class="apps-container-bottom-message">
          Don't see the app you're looking for? <br />
          Let us know which apps you would like us to support.
        </p>
        <div class="input-container-wrapper">
          <chat-input
            class="chat-input"
            #chatInput
            [placeholder]="'Type your request here..'"
            (send)="send($event)"
            [minLength]="SUGGESTIONS_MIN_LENGTH"
            [maxLength]="SUGGESTIONS_MAX_LENGTH"
            [name]="'apps_suggestion_box'"
            [location]="location"
          ></chat-input>
        </div>
      </section>
    </ng-scrollbar>
  </main>
</ng-container>

<ng-template #loader>
  <div class="app-list-ghost">
    <div class="app-list-ghost-item" intersect *ngFor="let i of 20 | fill"></div>
  </div>
</ng-template>
