import { Config } from '@environments/config';
import { Filters, Search } from '@local/client-contracts';
import { ManualPromise, isSearchCancelledError } from '@local/common';
import { EventInfo, EventInfoSearch, EventsService, InternetService, LogService } from '@shared/services';
import { ApplicationsService } from '@shared/services/applications.service';
import { LinksService } from '@shared/services/links.service';
import { NativeAppLinkService } from '@shared/services/native-app-link.service';
import { SessionService } from '@shared/services/session.service';
import { DateFormat, getDateFormat } from '@shared/utils/date-format.util';
import { getDisplayHeader } from '@shared/utils/header-builder.util';
import { flatten, isMatrix } from '@shared/utils/utils';
import { Logger } from '@unleash-tech/js-logger';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { Observable, Subject, Subscription, firstValueFrom, shareReplay, switchMap, takeUntil } from 'rxjs';
import { HeaderItem, RendererResults, SearchResults, TelemetryTrigger } from 'src/app/bar/views';
import { RecentSearchesService } from 'src/app/bar/views/results/recent-searches.service';
import { GlobalErrorHandler } from 'src/app/global-error-handler';
import * as uuid from 'uuid';
import {
  isHeader,
  isLocalResult,
  isResourceResult,
  isResult,
  mergeResponseFilters,
  pcAppName,
} from '../../../../views/results/utils/results.util';
import { FiltersService } from '../../../filters.service';
import { HubService } from '../../../hub.service';
import { ResultsService } from '../../../results.service';
import { SearchParamsService } from '../../../search-params.service';
import { SearchClient } from '../search-client';
import { SearchRequest } from '../search-request';
import { SearchResponse } from '../search-response';
import { SearchResponseType } from '../search-response-type';
import { LinkResourcesResultExtra } from './link-resources-result-extra';
import { LinkResourcesSourceSettings } from './link-resources-source-settings';
import { delay, getExtensionByFileName, getIconByExtension } from '@local/ts-infra';

export class LinkResourcesSearchClient implements SearchClient<LinkResourcesSourceSettings> {
  private logger: Logger;
  private recentSerachTimeout: NodeJS.Timeout;
  private isInternalUser: boolean;
  private destroy$ = new Subject<void>();
  private updateSubscription: Subscription;
  private readonly PC_APP_NAME = pcAppName();
  private readonly BEST_MATCH_GROUP = 'best-match';
  private readonly LOCAL_MAX_TOTAL = 500;

  constructor(
    private logService: LogService,
    private eventsService: EventsService,
    private recentSearches: RecentSearchesService,
    private filtersService: FiltersService,
    private linksService: LinksService,
    private errorHandler: GlobalErrorHandler,
    private applicationsService: ApplicationsService,
    private session: SessionService,
    private resultsService: ResultsService,
    private nativeAppLinkService: NativeAppLinkService,
    private hubService: HubService,
    private internetService: InternetService,
    private searchParamsService: SearchParamsService
  ) {
    this.logger = this.logService.scope('link-resources');
    this.session.current$.pipe(takeUntil(this.destroy$)).subscribe((s) => {
      this.isInternalUser = s?.user.internal;
    });
  }

  supportsSort(): boolean {
    return true;
  }

  supportsFilters(): boolean {
    return true;
  }

  search(request: SearchRequest<LinkResourcesSourceSettings>, response: SearchResponse): SearchResponseType {
    const debounce = request.sourceSettings?.debounce;
    if (request.trigger != 'user_query' || !debounce) {
      return this.innerSearch(request, response);
    } else {
      return delay(request.sourceSettings.debounce).then(() => {
        request.sourceSettings.debounce = 0;
        return this.innerSearch(request, response);
      });
    }
  }

  nextPage(request: SearchRequest<LinkResourcesSourceSettings>, response: SearchResponse, trigger: TelemetryTrigger): Promise<void> {
    return this.innerNextPage(request, response, trigger);
  }

  destroy(): void {
    this.destroy$.next();
  }

  private async innerSearch(request: SearchRequest<LinkResourcesSourceSettings>, response: SearchResponse): Promise<Observable<void>> {
    const sourceSettings = request.sourceSettings;
    const preFilters = sourceSettings.useSourceFilters ? sourceSettings.filters?.preFilters : this.filtersService.getPreFilters(true);
    const postFilters = sourceSettings.filters?.postFilters;
    const maxCount = sourceSettings.requestMaxCount;
    const bestMatchOnly = sourceSettings.group?.name === this.BEST_MATCH_GROUP;
    let groupsOptions = sourceSettings.groupsOptions;
    if (sourceSettings.localOnly) {
      groupsOptions = undefined;
    } else if (!bestMatchOnly && (sourceSettings.group?.value || groupsOptions?.scope)) {
      groupsOptions = groupsOptions || {};
      groupsOptions.scope = sourceSettings.group?.value || groupsOptions?.scope;
    }

    const searchRequest: Search.Request = {
      query: request.query,
      sessionId: sourceSettings.sessionId || request.sessionId,
      timestamp: Date.now(),
      pageSize: maxCount,
      preFilters,
      postFilters,
      aggregations: sourceSettings.aggregations,
      contentSearch: sourceSettings.contentSearch,
      advancedSearch: sourceSettings.advancedSearch,
      groupsOptions,
      groupsFilters: sourceSettings.groupsFilters,
      caching: sourceSettings.caching || { strategy: 'cache-and-source' },
      sources: {
        remote: !sourceSettings.localOnly,
        local: this.nativeAppLinkService.canSearchPc(),
      },
      preventRTF: sourceSettings.preventRTF,
      lastSyncTimes: sourceSettings.lastSyncTimes,
      shortSnippet: sourceSettings.shortSnippet,
      excludeFilters: sourceSettings.filters.excludeFilters,
      innerQuery: this.searchParamsService.innerQuery,
      tag: request.sourceSettings.tag,
      includeHiddenLinks: sourceSettings.includeHiddenLinks,
      experienceId: sourceSettings.assistantId,
    };

    if (sourceSettings.sorting) {
      searchRequest.sorting = sourceSettings.sorting;
    }
    if (sourceSettings.disableAggregations) {
      searchRequest.disableAggregations = true;
    }

    const query = request.query;
    this.addRecentSearch(query, this.filtersService.tagFilters, sourceSettings.node?.id, request.sessionId, response);

    this.logger.info('sending search', { q: query });
    const searchStarted = new ManualPromise<void>();
    this.initResultItemUpdates(request, response, searchStarted);
    const search$ = await this.resultsService.search$(searchRequest);
    if (response.cancelled) {
      return;
    }
    const observable = search$.pipe(
      switchMap(async (searchContext) => {
        if (response.cancelled) {
          return;
        }
        const local = searchContext?.origins?.Local;
        const cloud = searchContext?.origins?.Cloud;

        if (!response.extra) {
          response.extra = {
            sentEndEvents: {},
            searchRequest,
          } as LinkResourcesResultExtra;
        }
        const extra = response.extra as LinkResourcesResultExtra;
        extra.search = searchContext;

        const remoteResults: Search.ResultResourceItem[] = <Search.ResultResourceItem[]>cloud.response?.results || [];

        // if the query was dropped by servers and this is still the active query.. retry,m,m
        if (local.status == 'dropped' || cloud.status == 'dropped') {
          this.logger.error('needed search was drop', {
            context: searchContext,
            searchRequest,
            localSearchDropped: local.status == 'dropped',
            remoteSearchDropped: cloud.status == 'dropped',
          });
          response.complete();
          return;
        }

        if (local.status == 'failed') {
          this.errorHandler.error = local.error;
        }
        if (cloud.status == 'failed' && !cloud.error.isInternetError) {
          this.errorHandler.error = cloud.error;
        }

        // add the id of the search into each item, so we can use them while rendering
        for (const group of cloud.response?.groups || []) {
          for (const r of group.results) {
            (<any>r).searchId = cloud.response.id;
          }
        }
        for (const r of local.response?.results || []) {
          (<any>r).searchId = local.response.id;
        }
        for (const r of remoteResults || []) {
          (<any>r).searchId = cloud.response.id;
        }

        const remoteResultsShown = remoteResults;

        let items: RendererResults[] = [...remoteResultsShown].filter((i) => i);

        const searchDone = local.status !== 'active' && cloud.status !== 'active';

        const noResults =
          local.status != 'active' &&
          (local.skipResults || local.status != 'succeeded' || !local.response.totalResults) &&
          (cloud.skipResults || cloud.status != 'succeeded' || !cloud.response.totalResults);

        let filters = extra?.postFilters;

        if (cloud.status == 'succeeded') {
          filters = mergeResponseFilters(cloud.response?.filters || {}, local.response?.filters || {});
        } else {
          filters = local.response?.filters;
        }

        const resultsCount =
          (searchContext.origins.Cloud.response?.totalResults || 0) + (searchContext.origins.Local.response?.totalResults || 0);
        const showFilters =
          (!request.newSearchSession && resultsCount > 0) || resultsCount > 5 || searchContext.origins.Local.response?.results?.length > 1;
        if (!showFilters) {
          filters = null;
        }

        if (searchDone && !Object.keys(filters || {}).some((k) => filters[k]?.length) && !this.hasPostFilters()) {
          extra.postFilters = null;
        } else if (!noResults) {
          extra.postFilters = filters;
        }
        const suggestedFilters = [cloud, local].map((s) => s.response?.suggestedFilters).filter((s) => !!s);
        extra.isSingleApp =
          suggestedFilters.every((s) => s.appId?.values?.length <= 1) && (searchRequest.postFilters?.app?.length || 0) <= 1;

        const typeSuggestedFilters = {};
        for (const type of suggestedFilters.map((s) => s.type?.values || []).flat()) {
          if (!typeSuggestedFilters[type.name]) {
            typeSuggestedFilters[type.name] = type || 0;
            continue;
          }
          typeSuggestedFilters[type.name].count += type.count;
        }
        extra.typeSuggestedFilters = Object.values(typeSuggestedFilters);

        let lastHeaderIndex = 0;

        if (!cloud.skipResults && !sourceSettings.noHeader) {
          if (cloud.status == 'failed') {
            const header: HeaderItem = {
              type: 'header',
              origin: 'remote',
              clickable: false,
              title: 'Failed to search the cloud.',
            };
            items.push(header);
            lastHeaderIndex = Math.max(lastHeaderIndex, items.length - 1);
          } else if (cloud.status == 'succeeded' && items[0]?.type != 'header') {
            if (items?.length) {
              const resultItem = <Search.ResourceItem>cloud.response?.results[0];

              const singleAppName =
                extra.isSingleApp && resultItem?.resource?.appId ? this.applicationsService.apps[resultItem.resource.appId]?.name : null;

              const index = items.findIndex((r) => !isLocalResult(r) && !isHeader(r));
              const showFromLocal = cloud.source === 'Lucene';
              const source = this.isInternalUser ? ` (${cloud.source})` : '';

              const { title, titleEnd } = getDisplayHeader(
                { title: sourceSettings.header?.title, titleEnd: sourceSettings.header?.titleEnd },
                cloud?.response?.totalResults
              );

              const header: HeaderItem = {
                type: 'header',
                clickable: sourceSettings.disableCloudGroup ? false : true,
                title,
                titleEnd: titleEnd ? titleEnd + source : source,
                icon: showFromLocal ? 'icon-lightning font-icon' : undefined,
                origin: singleAppName || 'cloud',
                group: sourceSettings.disableCloudGroup
                  ? undefined
                  : {
                      name: this.BEST_MATCH_GROUP,
                      title: 'All results',
                    },
              };
              items.splice(index, 0, header);
              lastHeaderIndex = Math.max(lastHeaderIndex, index);
            } else if (items.length) {
              const { title, titleEnd } = getDisplayHeader(
                { title: sourceSettings.header?.title, titleEnd: sourceSettings.header?.titleEnd },
                cloud?.response?.totalResults
              );
              const header: HeaderItem = {
                type: 'header',
                clickable: false,
                origin: 'cloud',
                title: title || 'Best match',
                titleEnd: titleEnd,
              };
              items.push(header);
              lastHeaderIndex = Math.max(lastHeaderIndex, items.length - 1);
            }
          }
        }
        const skipCloud = extra.search.origins.Cloud.status == 'skipped' || cloud.skipResults;
        const src: Search.Origin = skipCloud ? 'Local' : 'Cloud';

        extra.lastPageInfo = { startIndex: 0, endIndex: items.length - 1, last: !extra.search.origins[src].response?.pageToken };

        extra.cloudTotalResults = cloud?.response?.totalResults;

        extra.localDone = extra.search.origins.Local.status !== 'active';
        const groups: SearchResults[] = !bestMatchOnly ? this.getGroupsResults(searchContext, sourceSettings) : [];
        extra.ignoreDateHeaders = sourceSettings.ignoreDateHeaders;
        extra.sort = searchContext.origins?.Cloud?.response?.sort;
        if (items.length > 4 && this.shouldSortByDate(searchContext?.origins, extra)) {
          const results = this.insertDateFormatHeaders(items, extra, true);
          response.extra.dateHeadersInserted = results.length !== items.length;
          items = results;
        }

        if (sourceSettings.lastSyncTimes) {
          extra.lastSyncTime = cloud?.response?.lastSyncTime;
        }
        response.extra = extra;

        response.items = sourceSettings.groupsFirst ? [groups, items] : [items, groups];
        for (const item of response.items.flat()) {
          item.action = await this.resultsService.getResultAction(item);
        }

        const localOnly = cloud.status == 'skipped' || cloud.skipResults;
        const adjustItems = localOnly ? groups : items;

        await this.adjustItemIcons(adjustItems, searchRequest, sourceSettings);

        extra.searchId = cloud.response?.id;

        const updateState = !searchContext.cache?.hit || sourceSettings.caching?.strategy != 'cache-and-source';
        if (searchDone) {
          response.complete(updateState);
        } else if (updateState) {
          response.notifyUpdated();
        }
      }),
      shareReplay(1)
    );
    searchStarted.resolve();
    return observable;
  }

  private getGroupsResults(searchContext: Search.Context, sourceSettings: LinkResourcesSourceSettings) {
    const local = searchContext?.origins?.Local?.response;
    const localResults = local?.results || [];
    const cloud = searchContext?.origins?.Cloud || { status: 'skipped' };
    const cloudGroups = cloud.response?.groups || [];

    const groups: SearchResults[] = [];
    const resultGroups = cloudGroups;
    const groupTypes = Array(resultGroups.length).fill('link-resources');
    let addFooter = false;
    if (localResults.length) {
      const localOnly = cloud.status == 'skipped' || cloud.skipResults;
      addFooter = sourceSettings.forceFooter && localResults.length > sourceSettings.localMaxCount;
      let localResultsShown = localResults;
      if (!localOnly && sourceSettings.localMaxCount) {
        localResultsShown = localResultsShown.slice(0, sourceSettings.localMaxCount);
      }
      const localGroup = {
        count: local.totalResults,
        name: this.PC_APP_NAME,
        value: this.PC_APP_NAME,
        results: localResultsShown,
      } as Search.Group;
      resultGroups.unshift(localGroup);
      groupTypes.unshift('local');
    }

    for (let index = 0; index < resultGroups.length; index++) {
      const groupResults = [];
      const group = resultGroups[index];
      const groupType = groupTypes[index];

      if (!group.results.length) {
        continue;
      }

      const groupSizeLimit = sourceSettings.groupsOptions?.groupSize;
      if (groupSizeLimit && sourceSettings?.id !== 'group-resources') {
        group.results = group.results.slice(0, groupSizeLimit);
      }

      groupResults.push(...group.results);

      if (!sourceSettings.noHeader) {
        const filterName = this.getGroupFilterName(group.name);
        const title = group.name;
        const addPlus = title === this.PC_APP_NAME && group.count >= this.LOCAL_MAX_TOTAL;

        const header: HeaderItem = {
          type: 'header',
          title,
          titleEnd: `${group.count}${addPlus ? '+' : ''}`,
          clickable: sourceSettings.showGroupHeader,
          origin: group.name,
          group: sourceSettings.showGroupHeader
            ? {
                name: groupType,
                type: filterName ? 'filter' : 'active-page',
                value: filterName ? group.value : group.value.toLowerCase(),
                title: group.name,
                filterName,
              }
            : null,
        };

        groupResults.unshift(header);

        if (sourceSettings?.id === 'group-resources' && group.count > groupSizeLimit) {
          const footer: HeaderItem = {
            type: 'header',
            clickable: true,
            origin: `footer-${group.name}`,
            title: 'See All',
            isFooter: true,
            selectable: true,
            group: {
              name: groupType,
              type: filterName ? 'filter' : 'active-page',
              value: filterName ? group.value : group.value.toLowerCase(),
              title: group.name,
              filterName,
              isTagFilter: false,
            },
          };
          groupResults.push(footer);
        }
      }

      groups.push(...groupResults);
    }

    return groups;
  }

  private getGroupFilterName(groupName: string) {
    if (groupName === this.PC_APP_NAME) {
      return 'app';
    }
    if (groupName !== 'People') {
      return 'type';
    }
  }

  private async innerNextPage(request: SearchRequest<LinkResourcesSourceSettings>, response: SearchResponse, trigger: TelemetryTrigger) {
    const prevItems = cloneDeep(response.items)?.flat();
    const extra = response.extra;
    if (!extra) {
      const sourceSettings = request.sourceSettings as LinkResourcesSourceSettings;
      this.logger.warn('innerNextPage - no search extra', { settings: sourceSettings });
      return;
    }
    const searchCtx = extra?.search;
    if (extra?.nextPagePromise || !searchCtx) {
      return;
    }
    const localStatus = searchCtx.origins.Local.status;
    const cloud = searchCtx.origins.Cloud;
    if (localStatus == 'active' || (cloud.status == 'active' && !cloud.skipResults)) {
      return;
    }

    const localOnly = !searchCtx || cloud.status == 'skipped' || cloud.skipResults;
    const origin: Search.Origin = localOnly ? 'Local' : 'Cloud';

    response.extra = { ...response.extra, searchId: cloud.response?.id, source: cloud.response?.source, origin };

    const prevResponse = searchCtx?.origins[origin]?.response; // || workCtx.actionsSearch?.origins?.Local?.response; todo

    const pageToken = prevResponse?.pageToken;

    if (!pageToken) {
      return;
    }
    const source = request.sourceSettings;
    const record = this.initSearchTelemetry(request, extra, 'pagination', uuid.v4());
    const searchEnd: EventInfo = {
      ...record,
      category: 'search',
      name: 'end',
      search: { ...record.search, cacheHit: false },
    };
    try {
      const searchStart: EventInfo = {
        ...record,
        category: 'search',
        name: 'start',
      };
      this.eventsService.event('search.start', searchStart);
      response.notifyUpdated();
      extra.nextPagePromise = this.resultsService.nextPage(origin, pageToken);

      const nextPageResponse = await extra.nextPagePromise;
      if (response.cancelled) {
        return;
      }

      const targetCtx = searchCtx;

      targetCtx.origins[origin].response = nextPageResponse;
      const prevEndIndex = extra.lastPageInfo?.endIndex || 0;
      if (extra.lastPageInfo) {
        if (extra.lastPageInfo.startIndex) {
          if (!extra.fetchedPages) extra.fetchedPages = 0;
          const paginationTriggered: EventInfo = {
            ...record,
            category: 'resources',
            name: 'pagination',
            target: trigger,
            label: (++extra.fetchedPages).toString(),
          };
          this.eventsService.event('pagination', paginationTriggered);
        }
      }
      extra.lastPageInfo = {
        startIndex: prevEndIndex,
        endIndex: prevEndIndex + nextPageResponse.results.length - 1,
        last: !nextPageResponse.pageToken,
      };
      if (this.shouldSortByDate(searchCtx.origins, extra)) {
        const results = this.insertDateFormatHeaders(nextPageResponse.results, extra);
        if (!extra?.dateHeadersInserted) {
          extra.dateHeadersInserted = results.length !== nextPageResponse.results.length;
        }
        nextPageResponse.results = results;
      }

      const items = prevItems.concat(<SearchResults[]>nextPageResponse.results);
      await this.adjustItemIcons(items as SearchResults[], extra?.searchRequest, source);
      response.extra = extra;
      response.items = items;
      for (const item of items) {
        item.action = await this.resultsService.getResultAction(item);
      }
    } catch (e) {
      searchEnd.exception = e.message;
      searchEnd.search.responseStatus = 'failed';
      this.logger.error('failed passing the next page', { searchRequest: extra.searchRequest, trigger: request.trigger });
    } finally {
      if (!response.cancelled) {
        extra.nextPagePromise = null;
        this.eventsService.event('search.end', searchEnd);
        response.notifyUpdated();
      }
    }
  }

  getTelemetryEndEvent(response: SearchResponse) {
    const extra = response.extra;
    const searchContext = extra?.search as Search.Context;
    const events: Partial<EventInfo>[] = [];
    for (const s of Object.entries(searchContext.origins)) {
      const searchEnd: Partial<EventInfoSearch> = { cacheHit: searchContext.cache?.instantHit };

      const origin = s[0];
      const resp = s[1];

      if (resp.status == 'active' || extra.sentEndEvents[origin]) continue;

      extra.sentEndEvents[origin] = true;
      searchEnd.responseStatus = resp.status;
      searchEnd.searchId = resp.response?.id;
      searchEnd.origin = origin;
      searchEnd.source = resp.source;
      if (resp.duration) {
        searchEnd.stepDuration = Math.floor(resp.duration);
      }
      if (resp.status == 'succeeded') {
        searchEnd.resultsCount = resp.response.totalResults;
      }

      const event: Partial<EventInfo> = { search: searchEnd };
      if (resp.status == 'failed') {
        event.exception = resp.error.message;
      }
      events.push(event);
    }
    return events;
  }

  private addRecentSearch(q: string, tagFilters: Filters.Values, nodeId: string, sessionId: string, response: SearchResponse) {
    const query = q?.trim();
    if (!query || query.length < 2) return;
    if (this.recentSerachTimeout) {
      clearTimeout(this.recentSerachTimeout);
      this.recentSerachTimeout = undefined;
    }
    this.recentSerachTimeout = setTimeout(() => {
      if (response.cancelled) {
        return;
      }
      this.recentSearches.add(query, tagFilters, nodeId || 'search', sessionId);
    }, Config.recentSearches.debounce);
  }

  private async adjustItemIcons(items: SearchResults[], searchRequest: Search.Request, sourceSettings: LinkResourcesSourceSettings) {
    const iconAdjustments = sourceSettings.iconAdjustments || 'Default';
    const hasSingleAppFilter = await this.hasSingleAppFilter(searchRequest);
    if (iconAdjustments === 'Skip') return;

    for (const i of items.filter((i) => i.type == 'result')) {
      const resultItem = <Search.ResultItem>i;
      if (i.action?.type === 'files') {
        const extension = getExtensionByFileName(resultItem.view.title.text);
        const overlayIcon = extension ? getIconByExtension(extension) : undefined;
        if (overlayIcon) {
          resultItem.view.iconOverlay = { lightUrl: getIconByExtension(extension) };
        }
      }
      if (!hasSingleAppFilter) {
        continue;
      }

      if (iconAdjustments === 'Remove-Icon') {
        resultItem.view.icon = null;
        continue;
      }
      if (resultItem?.view?.iconOverlay) {
        resultItem.view.icon = resultItem.view.iconOverlay;
        resultItem.view.iconOverlay = null;
      }
    }
  }

  /** @return if the displayed result has one app (by postFilters / preFilters / response) return the app name for display */
  private async hasSingleAppFilter(request: Search.Request, response?: Search.Response): Promise<string | void> {
    if (response) {
      if (response.filters?.appId?.length === 1) {
        return response.filters?.appId[0].title;
      }
    }

    const allLinks = await firstValueFrom(this.linksService.visible$);
    const allAppIds = (allLinks || []).map((a) => this.applicationsService.apps[a.appId]).filter((app) => app && !app.state.ignore);
    if (new Set(allAppIds).size === 1) {
      const appId = allLinks[0].appId;
      return this.applicationsService.apps[appId]?.name;
    }

    let filter: string;

    if (request.postFilters?.app?.length === 1) {
      filter = request.postFilters.app[0];
    }

    if (!filter && request.preFilters?.app?.length === 1) {
      filter = request.preFilters.app[0];
    }

    let name: string;

    if (filter) {
      const idx = Object.values(this.applicationsService.apps)
        .map((a) => a.name)
        .indexOf(filter);
      if (idx !== -1) {
        name = Object.values(this.applicationsService.apps)[idx]?.name;
      }
    }

    if (filter && !name) {
      this.logger.warn(`No app name found for ${filter}`);
    }

    return name;
  }

  private hasPostFilters(): boolean {
    return Object.keys(this.filtersService.postFilters).length > 0;
  }

  private initResultItemUpdates(
    request: SearchRequest<LinkResourcesSourceSettings>,
    response: SearchResponse,
    searchStarted: Promise<void>
  ) {
    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
      this.updateSubscription = null;
    }
    this.updateSubscription = this.resultsService.updates$.pipe(takeUntil(this.destroy$)).subscribe(async (items) => {
      try {
        await searchStarted;
        await this.onUpdate(request, response, [].concat(...items));
      } catch (error) {
        if (isSearchCancelledError(error)) {
          this.logger.info('search cancelled - dropping update state', { requestId: request.id });
          return;
        }
        this.logger.error('got error while trying to update search results', error);
      }
    });
  }

  private async onUpdate(
    request: SearchRequest<LinkResourcesSourceSettings>,
    response: SearchResponse,
    newItems: SearchResults[]
  ): Promise<void> {
    const sourceSettings = request.sourceSettings;
    if (!sourceSettings) return;
    const ctxItems = response.items;
    const items: SearchResults[] = isMatrix(ctxItems) ? flatten(ctxItems) : ctxItems;
    if (!items?.length) {
      return;
    }

    let dirty = false;
    for (const item of newItems) {
      if (!isResult(item)) continue;
      const index = items.findIndex((i) => (<Search.ResultItem>i).id === item.id);
      if (index === -1) {
        continue;
      }

      // we don't have the entire view in this case..
      if (isResourceResult(item) && item.deleted) {
        items[index] = {
          ...items[index],
          deleted: true,
          action: await this.resultsService.getResultAction(item),
        } as SearchResults;
        dirty = true;
      } else {
        if (JSON.stringify(items[index]) !== JSON.stringify(item)) {
          item.action = await this.resultsService.getResultAction(item);
          items[index] = item;
          dirty = true;
        }
      }
    }

    if (dirty) {
      const extra: LinkResourcesResultExtra = response.extra;
      await this.adjustItemIcons(items, extra?.searchRequest, sourceSettings);
      response.items = items;
      response.notifyUpdated();
    }
  }

  private shouldSortByDate(origins: any, extra: LinkResourcesResultExtra): boolean {
    const onlyCloud = (origins.Local?.skipResults || !origins.Local?.response?.totalResults) && origins.Cloud?.response?.totalResults;
    return !extra.ignoreDateHeaders && extra.sort?.type === 'Time' && onlyCloud;
  }

  private insertDateFormatHeaders(items: SearchResults[], extra: LinkResourcesResultExtra, reset = false): SearchResults[] {
    const itemsWithHeaders: SearchResults[] = [];
    let prevDateFormat = reset ? undefined : extra.prevSortDateFormat;
    let prevDate = reset ? undefined : extra.prevSortDate;
    items.forEach((item) => {
      const itemDate = this.getSortValue(item, extra.sort);
      if (!itemDate) {
        itemsWithHeaders.push(item);
        return;
      }
      const dateFormat = getDateFormat(itemDate);
      if (
        !prevDateFormat ||
        dateFormat !== prevDateFormat ||
        (dateFormat === 'this_year' && prevDate?.getMonth() !== itemDate?.getMonth())
      ) {
        prevDateFormat = dateFormat;
        prevDate = itemDate;
        const headerTitle = this.setHeaderTitle(dateFormat, itemDate);
        const header: HeaderItem = {
          type: 'header',
          clickable: false,
          origin: 'header-date',
          title: headerTitle,
        };
        itemsWithHeaders.push(header);
      }
      itemsWithHeaders.push(item);
    });
    extra.prevSortDate = prevDate;
    extra.prevSortDateFormat = prevDateFormat;
    return itemsWithHeaders;
  }

  private getSortValue(item: SearchResults, sortSettings: Search.Sort): Date {
    if (sortSettings.by === 'Trait') {
      const sortNumber = (item as Search.ResultResourceItem).resource?.traits[sortSettings.key];
      if (!sortNumber || sortNumber < 0) return;
      return new Date(sortNumber);
    }
    if (sortSettings.by === 'Timestamp') {
      const sortNumber = (item as Search.ResultResourceItem).sortingTimestamp;
      if (!sortNumber || sortNumber < 0) return;
      return new Date(sortNumber);
    }
  }

  private setHeaderTitle(dateFormat: DateFormat, date: Date): string {
    switch (dateFormat) {
      case 'today':
        return 'Today';
      case 'yesterday':
        return 'Yesterday';
      case 'this_month':
        return `Earlier in ${moment(date).format('MMMM')}`;
      case 'this_year':
        return moment(date).format('MMMM');
      default:
        return moment(date).format('MMMM YYYY');
    }
  }

  private initSearchTelemetry(
    request: SearchRequest<LinkResourcesSourceSettings>,
    extra: LinkResourcesResultExtra,
    trigger: string,
    clientSearchId: string
  ): Partial<EventInfo> {
    const sourceSettings = request.sourceSettings;
    const preFilters = sourceSettings.filters?.preFilters;
    const postFilters = sourceSettings.filters?.postFilters;

    return {
      location: { title: this.hubService.currentLocation },
      search: {
        offline: this.internetService.status === 'offline',
        clientSearchId,
        query: request.query,
        scope: Object.entries(preFilters || {})
          .filter((e) => e[1] && e[1].length)
          .map((e) => ({ type: e[0], values: e[1] })),
        filter: Object.entries(postFilters || {})
          .filter((e) => e[1] && e[1].length)
          .map((e) => ({ type: e[0], values: e[1] })),
        sessionId: request.sessionId,
        trigger,
        searchId: extra.searchId,
        origin: extra.origin,
        source: extra.source,
      },
    };
  }
}
