import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Accounts, Permissions, User, Workspace } from '@local/client-contracts';
import { CollectionsService } from '../../services/collections.service';
import { KeyName } from '@local/ts-infra';
import { cloneDeep, isEqual } from 'lodash';
import { EventsService } from '@shared/services';
import { KeyboardService, CustomKeyboardEvent } from '@shared/services/keyboard.service';
import { HubService } from '../../services/hub.service';
import { PopupRef } from '@local/ui-infra';
import {
  AvatarListService,
  PermissionDictionary,
  WorkspaceMemberDetails,
  WorkspaceMemberDetailsType,
} from '../../services/avatar-list.service';
import { OptionsPopupComponent } from '../../views/hub/components/launcher-avatar-popup/options-popup.component';
import { randomColor } from '@shared/utils';
import { SuggestionsList } from '../../services/identities-suggestions.service';
import { AvatarItemModel } from '../../models/avatar-item.model';

export interface ShareOptionsPermissionsModel {
  accountId?: string;
  isShared?: boolean;
  shareOptions?: Permissions.ShareOptions;
  scopePermissionOptions?: ScopePermissions[];
  warningMessage?: string;
  title?: string;
  displayPermissionOption: boolean;
  descriptionShareGroups?: string;
  telemetryName: string;
  specialMessage?: string;
  specialMessageIcon?: string;
  selectedOwnerOrAdminByRole?: {
    enabled: boolean;
    label: string;
    updateRolesWhenSave?: boolean;
  };
  isOwner?: boolean;
  updateGroupAndMembers?: string;
  searchTypesArray?: string[];
  inputPlaceholder?: string;
}

export type ViewModelInvited = {
  invited: WorkspaceMemberDetails;
  scopePermissions: Permissions.ShareScope;
  backgroundColor?: string;
  type: WorkspaceMemberDetailsType;
};

export type ScopePermissions = 'Viewer' | 'Editor';

export type WorkspacePermission = { shared: boolean; scope: Permissions.ShareScope };

export type ScopePermissionsOptions = { label: ScopePermissions; value: boolean };

export type WorkspaceMember = WorkspaceMemberDetails & { type: WorkspaceMemberDetailsType };

export type OwOrAdminsSettings = { display: boolean; label: string; avatarList: AvatarItemModel[]; count: number };
@UntilDestroy()
@Component({
  selector: 'share-options-permissions',
  templateUrl: './share-options-permissions.component.html',
  styleUrls: ['./share-options-permissions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareOptionsPermissionsComponent implements OnInit, OnDestroy {
  private keyHandlerId: string;
  private workspace: Workspace.Workspace;
  private isBlurInput: boolean;
  private initialInvited: ViewModelInvited[] = [];
  private initialWorkspacePermission: WorkspacePermission = { shared: false, scope: 'read' };
  workspacePermission: WorkspacePermission = { shared: false, scope: 'read' };
  suggestions: SuggestionsList = [];
  owOrAdmins: OwOrAdminsSettings;
  selectedSuggestions: WorkspaceMember[] = [];
  invitedSuggestions: ViewModelInvited[] = [];
  model: ShareOptionsPermissionsModel;
  creator: Accounts.WorkspaceAccount | User.Info;

  @Output() closePopup = new EventEmitter<{ target: string; shareOptions: Permissions.ShareOptions }>();

  @ViewChild('shareOptionsPermissionsPopup') shareOptionsPermissionsPopupRef: ElementRef;

  get heightPopup(): number {
    return this.shareOptionsPermissionsPopupRef?.nativeElement?.offsetHeight;
  }

  private get isModelChanged(): boolean {
    if (this.invitedSuggestions.length !== this.initialInvited.length) return true;
    return !isEqual(this.invitedSuggestions, this.initialInvited) || !isEqual(this.initialWorkspacePermission, this.workspacePermission);
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private keyboardService: KeyboardService,
    private collectionsService: CollectionsService,
    private eventsService: EventsService,
    private hubService: HubService,
    private avatarListService: AvatarListService,
    @Optional() private ref: PopupRef<OptionsPopupComponent, ShareOptionsPermissionsModel>
  ) {}

  ngOnInit(): void {
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys, event) => this.handleKeys(keys, event), 10);
    this.initData();
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    if (this.keyHandlerId) {
      this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
    }
  }

  private async initData(): Promise<void> {
    this.model = this.ref?.data;
    this.workspace = this.avatarListService.workspace;

    const dicScopes: PermissionDictionary = await this.avatarListService.initPermissionDictionary(
      this.model.accountId,
      this.model.shareOptions
    );

    const members = this.workspace.accounts.map((a) => {
      if (
        dicScopes[a.id] ||
        (this.model.selectedOwnerOrAdminByRole?.enabled && (a.isOwner || a.isAdmin)) ||
        this.model?.accountId === a.id
      ) {
        return { ...a, disable: true, type: 'account' };
      }
      return { ...a, type: 'account' };
    });

    const groups = [
      ...this.workspace.groups.map((a) => {
        if (dicScopes[a.id]) {
          return { ...a, disable: true, type: 'group' };
        }
        return { ...a, type: 'group' };
      }),
      ...Object.values(dicScopes)
        .filter((obj) => obj.type === 'external-group')
        .map((value) => {
          return {
            ...value.details,
            type: 'external-group',
            disable: true,
          };
        }),
    ];

    this.suggestions = [
      {
        name: 'Members',
        items: members,
      },
      {
        name: 'Groups',
        items: groups,
      },
    ];

    const allowedTypes = this.model.searchTypesArray;
    if (allowedTypes) {
      this.suggestions = this.suggestions.filter((suggestion) => {
        return allowedTypes.length === 0 || allowedTypes.includes(suggestion.name.toLowerCase());
      });
    }

    if (this.model.selectedOwnerOrAdminByRole?.enabled) {
      const ownerOrAdmin = (this.workspace.accounts || []).filter((a) => a.isOwner || a.isAdmin);
      ownerOrAdmin.sort((a, b) => (b.isOwner ? 1 : 0) - (a.isOwner ? 1 : 0));
      const avatarList = await this.avatarListService.createOwOrAdminsAvatarList();
      this.owOrAdmins = {
        display: true,
        label: this.model.selectedOwnerOrAdminByRole.label,
        avatarList,
        count: ownerOrAdmin.length,
      };
    }

    this.creator = this.workspace.accounts.find((w) => w.id === this.model?.accountId);

    if (!this.model.isShared) return;

    const workspacePermission = dicScopes[this.workspace.id];

    if (workspacePermission) {
      this.workspacePermission = { shared: true, scope: workspacePermission.scope };
      this.initialWorkspacePermission = cloneDeep(this.workspacePermission);
    }

    this.invitedSuggestions.push(
      ...Object.values(dicScopes)
        .filter((obj) => obj.type !== 'workspace')
        .map((value) => {
          return {
            invited: value.details,
            scopePermissions: value.scope,
            backgroundColor: randomColor(),
            type: value.type,
          } as ViewModelInvited;
        })
    );
    this.initialInvited = this.invitedSuggestions = cloneDeep(this.invitedSuggestions);
  }

  updateInvitedSuggestions(event: ViewModelInvited[]) {
    this.invitedSuggestions = cloneDeep(event);
  }

  updateWorkspacePermission(event: WorkspacePermission) {
    this.workspacePermission = cloneDeep(event);
  }

  onClickClosePopup(event, target: string): void {
    event.stopPropagation();
    this.sendImpressionEvent(target);
    if (!this.isModelChanged) {
      this.ref.destroy();
    }
    this.save(target);
  }

  private save(target: string) {
    let privateCollection: boolean = !this.workspacePermission.shared && !this.invitedSuggestions.length && this.model.isOwner;
    if (privateCollection && this.hasRolesPermissions()) {
      privateCollection = false;
    }
    const shareOptions: Permissions.ShareOptions = {
      level: privateCollection ? 'Private' : 'Protected',
      permissions: privateCollection ? [] : this.buildPermissions(),
    };
    this.closePopup.emit({ target, shareOptions });
    this.ref.destroy();
  }

  private buildPermissions(): Permissions.ShareOptionsItem[] {
    const scopeRead: Permissions.ShareOptionsItem = { scope: 'read', accountIds: [], groupIds: [], workspaceIds: [] };
    const scopeWrite: Permissions.ShareOptionsItem = { scope: 'write', accountIds: [], groupIds: [], workspaceIds: [] };
    let hasReadScope = false;

    scopeWrite.accountIds.push(this.model.accountId);

    this.invitedSuggestions.forEach((v) => {
      if (v.scopePermissions === 'read') {
        hasReadScope = true;
        this.addInvitedToScope(scopeRead, v.invited, v.type);
      } else if (v.scopePermissions === 'write') {
        this.addInvitedToScope(scopeWrite, v.invited, v.type);
      }
    });

    if (this.workspacePermission.shared) {
      if (this.workspacePermission.scope === 'read') {
        hasReadScope = true;
        scopeRead.workspaceIds.push(this.workspace.id);
      }
      if (this.workspacePermission.scope === 'write') {
        scopeWrite.workspaceIds.push(this.workspace.id);
      }
    }
    if (this.model.selectedOwnerOrAdminByRole?.updateRolesWhenSave) {
      const writeRoles = this.model.shareOptions.permissions?.find((a) => a.scope === 'write')?.roles;
      const readRoles = this.model.shareOptions.permissions?.find((a) => a.scope === 'read')?.roles;
      if (writeRoles?.length) {
        scopeWrite.roles = writeRoles;
      }
      if (readRoles?.length) {
        scopeRead.roles = readRoles;
        hasReadScope = true;
      }
    }
    const permissions = [scopeWrite];
    if (hasReadScope) {
      permissions.push(scopeRead);
    }

    return permissions;
  }

  private hasRolesPermissions(): boolean {
    if (!this.model.selectedOwnerOrAdminByRole?.updateRolesWhenSave) {
      return false;
    }
    const hasWriteRoles = !!this.model.shareOptions.permissions?.find((a) => a.scope === 'write')?.roles?.length;
    const hasReadRoles = !!this.model.shareOptions.permissions?.find((a) => a.scope === 'read')?.roles?.length;
    return hasWriteRoles || hasReadRoles;
  }

  private addInvitedToScope(scope: Permissions.ShareOptionsItem, invited: WorkspaceMemberDetails, type: WorkspaceMemberDetailsType) {
    if (['group', 'external-group'].includes(type)) {
      scope.groupIds.push(invited.id);
    } else {
      scope.accountIds.push(invited.id);
    }
  }

  sendImpressionEvent(target: string, label?: string): void {
    this.eventsService.event('home_tabs.share', {
      name: this.model.telemetryName,
      location: { title: this.hubService.currentLocation },
      target,
      label: label || 'share_option',
    });
  }

  private handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent): void {
    const key = keys[0];
    if (key === 'escape') {
      if (this.isBlurInput) {
        this.onClickClosePopup(event, 'Escape');
        this.isBlurInput = false;
      } else {
        this.isBlurInput = true;
      }
      event.stopPropagation();
      return;
    }
  }
}
