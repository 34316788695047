import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { DisplaySearchFilterValue, FilterChangeData, NestedSearchFilterValue, NestedFilter, isNestedFilterValue } from '../models';
import { MultiSelectModel, MultiSelectSelectedEvent, SearchState } from '@local/ui-infra';
import { cloneDeep, endsWith } from 'lodash';
import { Style } from '@local/client-contracts';
import { OpenFilterData } from '../multi-select-filter-base.component';
import { startsWihVowel, truncateText } from '@local/ts-infra';

type SelectedToDisplay = {
  firstValueFilter: string;
  firstValueTitle: string;
  firstValueIcon?: Style.EntityIcon<Style.EntityIconType>;
  nestedValuesTitles?: string;
  tooltip?: string;
};
@Component({
  selector: 'nested-filter',
  templateUrl: './nested-filter.component.html',
  styleUrls: ['./nested-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NestedFilterComponent {
  private readonly PLACEHOLDER_MAX_CHARACTERS = 24;
  private readonly SELECTED_MAX_CHARACTERS = 35;
  private readonly ICON_CHARS_COUNT = 3;
  readonly MIN_WIDTH_MULTI_SELECT = '76px';
  readonly MAX_CHARACTERS = 25;
  readonly OPEN_NESTED_FILTER_ICON: Style.EntityIcon<Style.EntityIconType> = { type: 'font-icon', value: 'icon-chevron-right' };
  private _currentModel: NestedFilter;
  private originalModel: NestedFilter;
  private newSelectedValues: NestedSearchFilterValue[] = [];
  options: MultiSelectModel;
  selectedValuesToDisplay: SelectedToDisplay;
  filterPlaceholder: string;

  @Input() filterMatchMode: string;
  @Input() version: number;
  @Input() open = false;
  @Input() clearSearchAfterSelect = true;
  @Input() separateSelected = true;
  @Input() virtualScroll = true;
  @Input() autoSort = true;
  @Input() showItemTooltip = true;
  @Input() filterDisabled: SearchState | boolean = 'FilterOnly';
  @Input() appendTo: any = null;
  @Input() set model(val: NestedFilter) {
    this.currentModel = cloneDeep(val);
    this.originalModel = cloneDeep(val);
    this.initData();
  }

  @Output() onBack = new EventEmitter<void>();
  @Output() onFilterChange = new EventEmitter<FilterChangeData>();
  @Output() onOpened = new EventEmitter<OpenFilterData>();

  get currentModel(): NestedFilter {
    return this._currentModel;
  }

  set currentModel(value: NestedFilter) {
    this._currentModel = value;
  }

  constructor(private cdr: ChangeDetectorRef) {}

  private initData() {
    this.initOptions();
    this.newSelectedValues = [];
    this.initSelectedTitle(this.originalModel);
    this.initDefaultPlaceholder();
    this.cdr.markForCheck();
  }

  private initOptions(forcePreOpen?: boolean) {
    this.options = {
      optionLabel: 'value',
      options: this.currentModel?.values || [],
      open: this.open,
      version: this.version || 0,
      forcePreOpen: forcePreOpen,
    };
  }

  private initSelectedTitle(model: NestedFilter): void {
    this.selectedValuesToDisplay = null;
    const selectedValues = this.getAllNestedSelected(model);
    if (!selectedValues?.length) {
      return;
    }
    const firstSelected = selectedValues.shift();
    this.selectedValuesToDisplay = {
      firstValueTitle: firstSelected.value,
      firstValueIcon: firstSelected.icon,
      firstValueFilter: model.title,
    };
    if (selectedValues?.length) {
      const nestedValues = selectedValues.map((v) => v.value);
      const nestedValuesTitles = `(${nestedValues.join(',')})`;
      const charsCount = this.calcCharsCount(this.selectedValuesToDisplay);
      const maxNestedCharsCount = this.SELECTED_MAX_CHARACTERS - charsCount;
      this.selectedValuesToDisplay.nestedValuesTitles = truncateText(nestedValuesTitles, maxNestedCharsCount);
      if (endsWith(this.selectedValuesToDisplay.nestedValuesTitles, '...')) {
        this.selectedValuesToDisplay.tooltip = `${this.selectedValuesToDisplay.firstValueTitle} ${nestedValuesTitles}`;
      }
    }
  }

  private calcCharsCount(selectedToDisplay: SelectedToDisplay): number {
    let charsCount = selectedToDisplay.firstValueFilter?.length + selectedToDisplay.firstValueTitle?.length;
    if (selectedToDisplay.firstValueIcon) {
      charsCount += this.ICON_CHARS_COUNT;
    }
    return charsCount;
  }

  private resetFilter(isOpen?: boolean) {
    setTimeout(() => {
      if (isOpen !== undefined) {
        this.open = isOpen;
      }
      this.newSelectedValues = [];
      this.currentModel = cloneDeep(this.originalModel);
      this.initOptions(isOpen);
      this.initDefaultPlaceholder();
      this.cdr.markForCheck();
    }, 0);
  }

  private getAllNestedSelected(model: NestedFilter): DisplaySearchFilterValue[] {
    const selected = model.values.find((v) => v.selected);
    const selectedValues: DisplaySearchFilterValue[] = [];
    if (!selected) {
      return selectedValues;
    }
    selectedValues.push(selected);
    if (isNestedFilterValue(selected) && selected.childFilter) {
      selectedValues.push(...this.getAllNestedSelected(selected.childFilter));
    }
    return selectedValues;
  }

  private changeToChildFilter(itemValue: NestedSearchFilterValue) {
    setTimeout(() => {
      const currentFilter = itemValue.childFilter;
      if (!currentFilter) {
        return;
      }
      this.open = true;
      this.currentModel = currentFilter;
      this.initOptions(true);
      this.initNestedFilterPlaceholder(currentFilter.name, itemValue.title);
      this.cdr.markForCheck();
    }, 0);
  }

  private initDefaultPlaceholder() {
    this.filterPlaceholder = this.currentModel.viewDetails?.placeholder ?? this.currentModel.title + '...';
  }

  private initNestedFilterPlaceholder(currentFilterName: string, parentValue: string) {
    const sign = startsWihVowel(parentValue) ? 'an' : 'a';
    const updatedPlaceholder = `Select ${sign} ${parentValue} ${currentFilterName}`;
    this.filterPlaceholder = truncateText(updatedPlaceholder, this.PLACEHOLDER_MAX_CHARACTERS);
  }

  private emitSelectedValues() {
    for (const value of this.newSelectedValues) {
      const changeAction: FilterChangeData = {
        name: value.filterName,
        changes: {
          values: [value],
        },
        current: {
          values: [value],
        },
        action: 'Set',
      };
      this.onFilterChange.emit(changeAction);
    }
  }

  onChange($event: MultiSelectSelectedEvent) {
    switch ($event.action) {
      case 'Set':
        {
          this.newSelectedValues.push($event.itemValue);
          const childValuesCount = $event.itemValue.childFilter?.values?.length;
          if (childValuesCount) {
            if (childValuesCount > 1) {
              this.changeToChildFilter($event.itemValue);
              return;
            }
            //Select the single option automatically when it's the only one available
            const singleValue = $event.itemValue.childFilter.values[0];
            this.newSelectedValues.push(singleValue);
          }
          this.emitSelectedValues();
          this.resetFilter(false);
        }
        break;
      case 'Remove': {
        const shouldReopenChildFilter = $event.itemValue.childFilter?.values?.length > 1;
        if (shouldReopenChildFilter) {
          this.changeToChildFilter($event.itemValue);
          return;
        }
        //When unselect one level, all others clear out too
        this.onClearAllEvent();
      }
    }
  }

  onClearAllEvent() {
    const values = this.getAllNestedSelected(this.originalModel);
    for (const value of values) {
      this.onFilterChange.emit({ action: 'ClearAll', name: value.filterName, changes: {}, current: {} });
    }
  }

  onBackFilter() {
    this.resetFilter(true);
  }

  onPanel(isPanelOpen: boolean, reset = true) {
    this.onOpened.emit({ opened: isPanelOpen, reset });
  }
}
