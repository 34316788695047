import { Injectable } from '@angular/core';
import { Help } from '@local/common';
import { NativeServicesRpcService, ServicesRpcService } from '@shared/services';

@Injectable()
export class HelpService {
  private service: Help.Help;

  constructor(webServices: ServicesRpcService, nativeServices: NativeServicesRpcService) {
    this.service = (nativeServices || webServices).invokeWith(Help.HelpRpcInvoker, 'help');
  }

  get links(): Promise<Help.Links> {
    return this.service.links;
  }
}
