import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { User as UserInt } from '@local/client-contracts';
import { Constants } from '@local/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LogService } from '@shared/services';
import { ReportsService } from '@shared/services/reports.service';
import { componentServicesRpcProvider, ServicesRpcService } from '@shared/services/rpc.service';
import { SessionService } from '@shared/services/session.service';
import { Logger } from '@unleash-tech/js-logger';
import { filter } from 'rxjs/operators';
import { HubService } from 'src/app/bar/services/hub.service';
import { ChatInputComponent } from '../../../components/chat-input/chat-input.component';
import { Message } from './chat-bubble/chat-bubble.component';

@UntilDestroy()
@Component({
  selector: 'feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
  providers: [componentServicesRpcProvider],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackComponent implements OnDestroy, OnInit, AfterViewInit {
  @Output() closePanel = new EventEmitter<void>();

  readonly ASK_FEEDBACK_TITLE: string = 'We want your feedback about everything!';
  readonly ASK_FEEDBACK_TEXT: string =
    'To help us make Unleash the best it can be, we want to hear what you have to say. Your feedback helps us decide which features to build, and what improvements should be made to our app.';
  user: UserInt.Info;
  messages: Message[] = [];
  readonly UNLEASH_AVATAR = './assets/bar/feedback/unleash-avatar.png';
  readonly FEEDBACK_MIN_LENGTH: number = 10;
  readonly FEEDBACK_MAX_LENGTH: number = 5000;
  readonly FEEDBACK_MAX_ROWS: number = 5;

  @ViewChild('scrollFrame', { static: false }) scrollFrame: ElementRef;
  @ViewChild('chatInput') chatInput: ChatInputComponent;
  @ViewChildren('bubble') chatBubbleElements: QueryList<any>;

  private isNearBottom = true;
  private logger: Logger;

  constructor(
    private services: ServicesRpcService,
    logger: LogService,
    private hubService: HubService,
    private sessionService: SessionService,
    private cdr: ChangeDetectorRef,
    private reports: ReportsService
  ) {
    this.logger = logger.scope('FeedbackComponent');
  }

  ngOnInit() {
    this.hubService.readOnly = true;
    this.sessionService.current$
      .pipe(
        untilDestroyed(this),
        filter((v) => !!v)
      )
      .subscribe((s) => {
        this.user = s?.user;
        this.cdr.markForCheck();
      });
  }

  ngAfterViewInit() {
    this.scrollFrame = this.scrollFrame.nativeElement;
    this.chatBubbleElements.changes.pipe(untilDestroyed(this)).subscribe((_) => this.onItemElementsChanged());
    this.chatInput.focus();

    this.renderMessage({
      avatarUrl: this.UNLEASH_AVATAR,
      type: 'received',
      text: `<span class="title">${this.ASK_FEEDBACK_TITLE}</span> ${this.ASK_FEEDBACK_TEXT}`,
    });
  }

  private onItemElementsChanged(): void {
    if (this.isNearBottom) {
      this.scrollToBottom();
    }
  }

  private scrollToBottom(): void {
    //@ts-ignore
    this.scrollFrame.scroll({
      //@ts-ignore

      top: this.scrollFrame.scrollHeight,
      left: 0,
      behavior: 'smooth',
    });
  }

  private isUserNearBottom(): boolean {
    const threshold = 150;
    //@ts-ignore

    const position = this.scrollFrame.scrollTop + this.scrollFrame.offsetHeight;
    //@ts-ignore

    const height = this.scrollFrame.scrollHeight;
    return position > height - threshold;
  }

  scrolled(event: any): void {
    this.isNearBottom = this.isUserNearBottom();
  }

  ngOnDestroy() {
    this.services.destroy();
  }

  renderMessage(message: Message) {
    this.messages.push(message);
    this.scrollToBottom();
  }

  async send(value: string) {
    try {
      await this.reports.submit('Feedback', value);
      this.renderMessage({ avatarUrl: this.user.picture, type: 'sent', text: value });
      setTimeout(() => {
        this.renderMessage({
          avatarUrl: this.UNLEASH_AVATAR,
          type: 'received',
          text: `Thanks for your feedback! Join our <a class="link primary"  target="_blank" href="${Constants.SLACK_COMMUNITY_URL}">Slack Community</a> and shape the future of Unleash with your continued feedback!`,
        });
        this.cdr.markForCheck();
      }, 1000);
    } catch (e) {
      this.messages.push({
        avatarUrl: '',
        type: 'received',
        text: 'Oops something failed.. We apologize for the inconvenience.',
      });
      this.logger.error('failed to send feedback', { error: e });
    } finally {
      this.cdr.markForCheck();
    }
  }
}
