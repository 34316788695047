<section (scroll)="scrolled($event)" #scrollFrame class="chat-container">
  <chat-bubble #bubble *ngFor="let message of messages" [message]="message" [class]="message.type"></chat-bubble>
</section>
<div class="input-container">
  <chat-input
    #chatInput
    [placeholder]="'So, what do you think?'"
    [minLength]="FEEDBACK_MIN_LENGTH"
    [maxLength]="FEEDBACK_MAX_LENGTH"
    [maxRows]="FEEDBACK_MAX_ROWS"
    [name]="'feedback'"
    (send)="send($event)"
  ></chat-input>
</div>
