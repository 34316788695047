import { Search } from '@local/client-contracts';

export type SortOptionType = 'time' | 'alphabetical' | 'relevant' | 'views';

export type SortOption = {
  label: string;
  value: Search.Sort;
  type: SortOptionType;
};

const SORT_OPTIONS: SortOption[] = [
  {
    label: 'Most relevant',
    value: null,
    type: 'relevant',
  },
  {
    label: 'Newest first',
    value: {
      by: 'Timestamp',
      order: 'Descending',
      type: 'Time',
    },
    type: 'time',
  },
  {
    label: 'Oldest first',
    value: {
      by: 'Timestamp',
      order: 'Ascending',
      type: 'Time',
    },
    type: 'time',
  },

  {
    label: 'A to Z',
    value: {
      by: 'Alphabetical',
      order: 'Ascending',
      type: 'Alphabetical',
    },
    type: 'alphabetical',
  },
  {
    label: 'Z to A',
    value: {
      by: 'Alphabetical',
      order: 'Descending',
      type: 'Alphabetical',
    },
    type: 'alphabetical',
  },
  {
    label: 'Views (Max-min)',
    value: {
      by: 'Views',
      order: 'Descending',
      type: 'Views',
    },
    type: 'views',
  },
  {
    label: 'Views (Min-max)',
    value: {
      by: 'Views',
      order: 'Ascending',
      type: 'Views',
    },
    type: 'views',
  },
];

const SORT_BY_VIEW: Record<string, SortOptionType[]> = {
  people: ['alphabetical', 'relevant'],
  golinks: ['alphabetical', 'relevant', 'time'],
  favorites: ['alphabetical', 'relevant', 'time'],
  mail: ['relevant', 'time'],
  view: ['alphabetical', 'relevant', 'time'],
  cards: ['alphabetical', 'relevant', 'time'],
  collections: ['alphabetical', 'relevant', 'time'],
  actions: ['alphabetical', 'relevant'],
  browserTabs: ['alphabetical', 'relevant'],
  browserBookmarks: ['alphabetical', 'relevant'],
  goto: ['alphabetical', 'relevant'],
  visits: ['alphabetical', 'time', 'relevant'],
  'wiki-collection': ['alphabetical', 'time', 'relevant', 'views'],
  'live-collection': ['alphabetical', 'time', 'relevant'],
  default: ['alphabetical', 'relevant', 'time'],
  assistants: ['alphabetical', 'relevant', 'time'],
  'relevant-people': ['alphabetical', 'relevant'],
};

export const getSortOptions = (view: string) => {
  const sortTypes: SortOptionType[] = SORT_BY_VIEW[view] || SORT_BY_VIEW['default'];
  return SORT_OPTIONS.filter((s) => sortTypes.includes(s.type));
};
