import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Accounts, Fyis } from '@local/client-contracts';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DateFormat, IconConstants } from '@shared/consts';
import { EventsService } from '@shared/services';
import { ApplicationsService } from '@shared/services/applications.service';
import { OAuthWindowCollection } from '@shared/services/oauth-windows/oauth-windows-collection';
import { RouterService } from '@shared/services/router.service';
import * as moment from 'moment/moment';
import { FyiService } from 'src/app/bar/services';
import { FyiViewModel } from '../fyi-models';
import { UiIconModel } from '@local/ui-infra';
import { isAccountFyi, isVerificationFyi, isCollectionFyi, isPinnedCollectionFyi } from 'src/app/bar/utils/fyi-utils';
import { CollectionsService } from 'src/app/bar/services/collections.service';
import { HomeTabsService } from 'src/app/bar/services/home-tabs.service';
import { HomePageService } from 'src/app/bar/services/home-page.service';
import { firstValueFrom } from 'rxjs';
import { HubService } from 'src/app/bar/services/hub.service';
import { joinUrl } from '@local/ts-infra';
import { AssistantsIconsConst } from '@local/common';

@UntilDestroy()
@Component({
  selector: 'fyi-dropdown-item',
  templateUrl: './fyi-dropdown-item.component.html',
  styleUrls: ['./fyi-dropdown-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FyiDropdownItemComponent {
  private fyi: Fyis.Fyi;
  private _workspaceAccount: Accounts.WorkspaceAccount;
  model: FyiViewModel;

  @Input() set viewFyi(value: Fyis.Fyi) {
    if (!value) return;
    this.setFyi(value);
  }
  @Input() set syncStatus(value: string) {
    if (!value) return;
    this.updateFyi(value, this.fyi ? (this.fyi as Fyis.InitialSyncFyi).resourcesCount : undefined);
  }
  @Input() set resourcesCount(value: number) {
    if (!value) return;
    this.updateFyi(this.fyi ? (this.fyi as Fyis.InitialSyncFyi).syncStatus : undefined, value);
  }

  @Input() set workspaceAccount(value: Accounts.WorkspaceAccount) {
    if (!value) {
      return;
    }
    this._workspaceAccount = value;
    if (isAccountFyi(this.fyi.type)) {
      this.setFyi(this.fyi);
      this.cdr.markForCheck();
    }
  }
  @Input() lastUpdateTime?: number;
  @Input() locationTitle: string;
  @Input() jsonData: any;

  @ViewChild('userState', { read: ElementRef }) userStateRef: ElementRef;
  constructor(
    private eventsService: EventsService,
    private routerService: RouterService,
    private cdr: ChangeDetectorRef,
    private oauthWindows: OAuthWindowCollection,
    private applications: ApplicationsService,
    private fyiService: FyiService,
    private collectionService: CollectionsService,
    private homeTabsService: HomeTabsService,
    private homePageService: HomePageService,
    private hubService: HubService
  ) {}

  async setFyi(value: Fyis.Fyi) {
    this.fyi = value;
    if (isVerificationFyi(value)) {
      this.model = await this.buildVerificationItem(value);
    }

    switch (value.type) {
      case 'initial_sync': {
        const initialSyncFyi = value as Fyis.InitialSyncFyi;
        this.model = this.buildSyncItem(initialSyncFyi);
        this.updateFyi(initialSyncFyi.syncStatus, initialSyncFyi.resourcesCount);
        break;
      }
      case 'link_staled': {
        this.model = this.buildStaleItem(value as Fyis.LinkStaledFyi);
        break;
      }
      case 'version_update': {
        this.model = this.buildVersionItem(value as Fyis.VersionUpdateFyi);
        break;
      }
      case 'admin_announcements': {
        this.model = this.buildAdminAnnouncement(value as Fyis.AdminAnnouncementFyi);
        break;
      }
      case 'collection_shared':
      case 'collection_pinned': {
        this.model = this.buildCollection(value as Fyis.CollectionFyi);
        break;
      }
      case 'experience_shared': {
        this.model = this.buildExperience(value as Fyis.ExperienceFyi);
        break;
      }
    }
    this.cdr.markForCheck();
  }

  updateFyi(syncStatus: string, resourcesCount: number) {
    if (!this.fyi) return;
    switch (syncStatus) {
      case 'Started' || !syncStatus:
        this.model.title = resourcesCount ? 'Now syncing' : 'Preparing for sync';
        this.model.subtitle = resourcesCount ? `${resourcesCount} Resources synced` : ' it may take a few minutes';
        this.model.indicatorIntent = 'warning';
        break;

      case 'Failed':
        this.model.title = 'Sync failed';
        this.model.indicatorIntent = 'danger';
        this.model.subtitle = '';
        break;

      case 'Succeeded':
        this.model.title = 'Sync completed';
        this.model.subtitle = `${resourcesCount || 0} Resources synced`;
        this.model.indicatorIntent = 'success';
        break;
    }

    this.model.displayDot = !(syncStatus === 'Failed');
    if (!this.model.isLinkExists) {
      this.model.indicatorIntent = 'danger';
    }

    this.cdr.markForCheck();
  }

  async onAction() {
    this.fyiService.closePopup();
    if (isVerificationFyi(this.model)) {
      return this.onVerificationAction();
    }
    switch (this.model.type) {
      case 'link_staled':
        this.onStaleLinkAction();
        break;
      case 'version_update':
      case 'admin_announcements':
        const isAdminAnnouncements = this.model.type === 'admin_announcements';
        const actionTarget = this.getUrlFromFyi();
        if (actionTarget) {
          const extraData = isAdminAnnouncements ? { trackingLabel: (this.fyi as Fyis.AdminAnnouncementFyi).trackingLabel } : {};
          extraData['trigger'] = 'mouse_click';
          if (actionTarget.isInternal) {
            this.routerService.navigateByUrl(actionTarget.url);
            this.reportFyiInteraction('internal_redirect');
          } else {
            window.open(actionTarget.url, '_blank');
            this.reportFyiInteraction('open');
          }
        }
        break;
      case 'collection_shared':
        const fyi = this.fyi as Fyis.CollectionFyi;
        this.fyiService.closePopupLinkStaled();
        const collection = await this.collectionService.getById(fyi.collectionId);
        this.collectionService.openCollection(collection, 'collections-view');
        this.reportFyiInteraction('open');
        break;
      case 'collection_pinned':
        this.fyiService.closePopupLinkStaled();
        const allTabs = await firstValueFrom(this.homeTabsService.all$);
        const tab = allTabs.find((t) => t.id === (<Fyis.CollectionPinnedFyi>this.fyi).tabId);
        if (this.hubService.currentLocation === 'home') {
          this.homePageService.setCurrentTab(tab);
        } else {
          this.routerService.navigate([], { queryParams: { t: this.homePageService.buildTabAsUrlParam(tab) } });
        }
        this.reportFyiInteraction('open');
        break;
      case 'experience_shared':
        const experience = this.fyi as Fyis.ExperienceFyi;
        this.fyiService.closePopupLinkStaled();
        this.routerService.navigateByUrl(`/assistant/${experience.experienceId}`);
        break;
    }

    await this.fyiService.markFyiRead(this.model.id);
    this.setFyi(this.fyi);
  }

  onStaleLinkAction() {
    this.fyiService.closePopupLinkStaled();
    if (this.model?.app?.appId && this.model?.link) {
      this.reportFyiInteraction('fix');
      const state = {
        linkId: this.model?.link?.linkId,
        key: this.model?.link?.key,
        source: '',
      };
      this.openPage(`connect/${this.model?.app?.appId}/new`, null, state);
    }
  }

  async onVerificationAction() {
    const fyi = this.fyi as Fyis.VerificationFyi;
    let url;
    switch (fyi.objectType) {
      case 'card': {
        url = this.collectionService.convertCardUrl(fyi.objectTitle, fyi.objectId);
        this.routerService.navigateByUrl(url);
        break;
      }
      case 'collection': {
        const collection = await this.collectionService.getById(fyi.objectId);
        this.collectionService.openCollection(collection, 'collections-view');
        break;
      }
    }
    this.fyiService.closePopupLinkStaled();
    this.reportFyiInteraction('open');
  }

  private reportFyiInteraction(target: string) {
    const label: string = this.model.type === 'collection_shared' && isCollectionFyi(this.fyi) ? this.fyi.collectionTitle : this.model.type;
    this.eventsService.event('fyi_action', {
      target,
      label,
      location: { title: this.locationTitle },
      jsonData: JSON.stringify(this.jsonData),
    });
  }

  openPage(url: string, q?: string, state?: any) {
    if (q || state) {
      const query = new URLSearchParams();
      if (url.indexOf('?') == -1) url += '?';
      else if (!url.endsWith('?')) url += '&';
      if (q) query.set('q', q);
      if (state)
        Object.entries(state).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((v) => query.append(key, v?.toString()));
          } else {
            query.set(key, value?.toString());
          }
        });
      url += query.toString();
    }
    if (url && !url.startsWith('?') && !url.startsWith('/')) url = '/' + url;
    this.routerService.navigateByUrl(url);
  }

  buildVersionItem(fyi: Fyis.VersionUpdateFyi): FyiViewModel {
    return {
      appIconUrl: {
        type: 'img',
        value: IconConstants.APP_ICON,
      },
      title: `Version ${fyi.version} is now live!`,
      subtitle: 'So many valuable features available. Take a look',
      indicatorIntent: 'none',
      id: fyi.id,
      actions: [{ label: 'Open', isPrimary: true, isDismiss: true }],
      type: fyi.type,
      read: !!fyi.read,
      deleted: false,
      app: { appName: 'unleash' },
      displayDot: false,
      hasActions: true,
    } as FyiViewModel;
  }

  buildAdminAnnouncement(fyi: Fyis.AdminAnnouncementFyi): FyiViewModel {
    const model: FyiViewModel = {
      title: fyi.title,
      subtitle: fyi.subtitle,
      indicatorIntent: 'none',
      id: fyi.id,
      actions: [],
      type: fyi.type,
      deleted: false,
      source: fyi.source,
      status: fyi.status,
      lastUpdateTime: fyi.lastUpdateTime,
      hasActions: true,
    };

    if (fyi.actionButton?.link) {
      model.actions.push({
        label: fyi.actionButton.text,
        isPrimary: true,
        isDismiss: false,
      });
    }

    if (fyi.logoUrl) {
      model.appIconUrl = { type: 'img', value: fyi.logoUrl };
    }
    return model as FyiViewModel;
  }

  private getCollectionFyiType(fyi: Fyis.CollectionFyi) {
    return fyi?.kind === 'Wiki' ? 'wiki' : 'collection';
  }

  private getCollectionFyiIcon(fyi: Fyis.CollectionFyi) {
    return `icon-${this.getCollectionFyiType(fyi)}`;
  }

  buildCollection(fyi: Fyis.CollectionFyi): FyiViewModel {
    let text = 'pinned';
    if (fyi.type === 'collection_shared') {
      if ((fyi as Fyis.CollectionSharedFyi).scope === 'write') {
        text = 'granted you editor access to';
      } else {
        text = `invited you to ${this.getCollectionFyiType(fyi)}`;
      }
    }
    const tabName = isPinnedCollectionFyi(fyi) ? fyi.tabName : null;
    return {
      appIconUrl: this.generateUserImage(),
      isUserIcon: true,
      user: {
        userName: this.generateUserName(),
        text,
        subtext: fyi.type === 'collection_pinned' ? `collection to ${tabName ?? 'your homepage'}` : undefined,
        boldText: fyi.collectionTitle,
        emoji: fyi.collectionEmoji,
        fallbackIcon: { type: 'font', value: this.getCollectionFyiIcon(fyi) },
      },
      indicatorIntent: 'none',
      id: fyi.id,
      type: fyi.type,
      deleted: false,
      displayDot: false,
      source: fyi.source,
      actions: [
        {
          label: 'Open',
          isPrimary: true,
          isDismiss: false,
        },
      ],
      isLinkExists: false,
      read: !!fyi.read,
      shortcut: [],
      status: fyi.status,
      lastUpdateTime: fyi.lastUpdateTime,
      hasActions: true,
    } as FyiViewModel;
  }

  buildExperience(fyi: Fyis.ExperienceFyi) {
    let text = `invited you to assistant`;
    if (fyi.scope === 'write') {
      text = 'granted you editor access to';
    }

    return {
      appIconUrl: this.generateUserImage(),
      isUserIcon: true,
      user: {
        userName: this.generateUserName(),
        text,
        boldText: fyi.experienceTitle,
        fallbackIcon: AssistantsIconsConst[fyi.experienceType],
      },
      indicatorIntent: 'none',
      id: fyi.id,
      type: fyi.type,
      deleted: false,
      displayDot: false,
      source: fyi.source,
      actions: [
        {
          label: 'Open',
          isPrimary: true,
          isDismiss: false,
        },
      ],
      isLinkExists: false,
      read: !!fyi.read,
      shortcut: [],
      status: fyi.status,
      lastUpdateTime: fyi.lastUpdateTime,
      hasActions: true,
    } as FyiViewModel;
  }

  buildStaleItem(fyi: Fyis.LinkStaledFyi): FyiViewModel {
    const isDeleted = !fyi.isLinkExists;
    return {
      appIconUrl: {
        type: 'img',
        value: {
          lightUrl: this.makeContentUrl(fyi.appContentUrl, fyi.appIcon.lightUrl),
          darkUrl: fyi.appIcon.darkUrl ? this.makeContentUrl(fyi.appContentUrl, fyi.appIcon.darkUrl) : undefined,
        },
      },
      title: 'Outdated link',
      subtitle: 'Please reconnect to stay up to date',
      indicatorIntent: 'danger',
      id: fyi.id,
      actions: isDeleted ? [] : fyi.linkId && fyi.key ? [{ label: 'fix', isPrimary: true }] : [],
      type: fyi.type,
      app: { appId: fyi.appId, appName: fyi.appName },
      isLinkExists: fyi.isLinkExists,
      displayDot: true,
      read: fyi.read ? true : false,
      deleted: isDeleted ? true : false,
      link: { key: fyi.key, linkId: fyi.linkId, linkName: fyi.linkName, linkColor: fyi.linkColor },
      source: fyi.source,
    } as FyiViewModel;
  }

  /**
   * @description If FYI doesn't include sync data, will be shows as 'Preparing for sync'
   */
  buildSyncItem(fyi: Fyis.InitialSyncFyi): FyiViewModel {
    const isDeleted = !fyi.isLinkExists;
    if (isDeleted && this.model) {
      return this.model;
    }
    const fyiToReturn: Partial<FyiViewModel> = {};
    fyiToReturn.app = { appId: fyi.appId, appName: fyi.appName };
    fyiToReturn.appIconUrl = {
      type: 'img',
      value: {
        lightUrl: this.makeContentUrl(fyi.appContentUrl, fyi.appIcon.lightUrl),
        darkUrl: fyi.appIcon.lightUrl ? this.makeContentUrl(fyi.appContentUrl, fyi.appIcon.darkUrl) : undefined,
      },
    };
    return {
      actions: [],
      id: fyi.id,
      status: fyi.status,
      source: fyi.source,
      type: fyi.type,
      isLinkExists: fyi.isLinkExists,
      read: fyi.read ? true : false,
      deleted: isDeleted ? true : false,
      link: {
        linkName: fyi.linkName,
        linkColor: fyi.linkColor,
      },
      ...fyiToReturn,
    } as FyiViewModel;
  }

  private makeContentUrl(context: string, icon: string) {
    return context && icon ? joinUrl(context, icon) : '';
  }

  public getFullLastUpdateTime(): string {
    return moment(this.lastUpdateTime).format(DateFormat.FULL_DATE_HOURS_A);
  }

  private getUrlFromFyi(): { url: string; isInternal: boolean } {
    const fyi = this.fyi;
    if (this.model.type === 'version_update') {
      return {
        url: (fyi as Fyis.VersionUpdateFyi).releaseNotesUrl,
        isInternal: false,
      };
    }
    if (this.model.type === 'admin_announcements') {
      return {
        url: (fyi as Fyis.AdminAnnouncementFyi).actionButton?.link,
        isInternal: (fyi as Fyis.AdminAnnouncementFyi).actionButton?.isInternalLink,
      };
    }
  }

  private async buildVerificationItem(fyi: Fyis.VerificationFyi): Promise<FyiViewModel> {
    const { id, lastUpdateTime, type, source, status } = fyi;
    const fyiView: FyiViewModel = {
      id,
      lastUpdateTime,
      type,
      status,
      source,
      indicatorIntent: 'none',
      deleted: false,
      read: !!fyi.read,
      hasActions: true,
      actions: [],
    };
    switch (fyi.type) {
      case 'verification_unverified':
        return this.buildVerificationUnverifiedFyi(fyiView, fyi);
      case 'verification_expired':
        return this.buildVerificationExpiredFyi(fyiView, fyi);
      case 'verifier_assigned':
        return this.buildVerificationAssignedFyi(fyiView, fyi);
      case 'verification_requested':
        return this.buildVerificationRequestedFyi(fyiView, fyi);
      case 'verifiers_notification':
        return this.buildVerificationVerifiersFyiValueFyi(fyiView, fyi);
    }
  }

  private buildVerificationRequestedFyi(fyiView: FyiViewModel, fyi: Fyis.VerificationFyi): FyiViewModel {
    fyiView.appIconUrl = this.generateUserImage();
    fyiView.isUserIcon = true;
    fyiView.user = {
      userName: this.generateUserName(),
      text: `has sent a request to verify ${fyi.objectType}`,
      boldText: fyi.objectTitle || '',
    };
    fyiView.actions = [
      {
        label: `Open ${fyi.objectType}`,
        isPrimary: true,
        isDismiss: false,
      },
    ];
    return fyiView;
  }

  private buildVerificationAssignedFyi(fyiView: FyiViewModel, fyi: Fyis.VerificationFyi): FyiViewModel {
    fyiView.appIconUrl = this.verificationIcon;
    fyiView.user = {
      text: `You have been appointed as a verifier for ${fyi.objectType}`,
      boldText: fyi.objectTitle || '',
    };
    fyiView.actions = [
      {
        label: `View ${fyi.objectType}`,
        isPrimary: true,
        isDismiss: false,
      },
    ];
    return fyiView;
  }

  private buildVerificationVerifiersFyiValueFyi(fyiView: FyiViewModel, fyi: Fyis.VerificationFyi): FyiViewModel {
    fyiView.appIconUrl = this.verificationIcon;
    fyiView.user = {
      text: `Edits have been made to ${fyi.objectType}`,
      boldText: fyi.objectTitle || '',
      subtext: 'which has rendered it unverified',
    };
    fyiView.actions = [
      {
        label: 'View the edits',
        isPrimary: true,
        isDismiss: false,
      },
    ];
    return fyiView;
  }

  private buildVerificationExpiredFyi(fyiView: FyiViewModel, fyi: Fyis.VerificationFyi): FyiViewModel {
    fyiView.appIconUrl = this.verificationIcon;
    fyiView.user = {
      text: `The verification interval for the ${fyi.objectType}`,
      subtext: 'has expired',
      boldText: fyi.objectTitle || '',
    };
    fyiView.actions = [
      {
        label: `Reverify the ${fyi.objectType}`,
        isPrimary: true,
        isDismiss: false,
        customColor: 'var(--green-500)',
        customPrimary: true,
      },
    ];
    return fyiView;
  }

  private buildVerificationUnverifiedFyi(fyiView: FyiViewModel, fyi: Fyis.VerificationFyi): FyiViewModel {
    fyiView.appIconUrl = this.generateUserImage();
    fyiView.isUserIcon = true;
    fyiView.user = {
      userName: this.generateUserName(),
      text: `has marked the ${fyi.objectType}`,
      subtext: 'as unverified',
      boldText: fyi.objectTitle || '',
    };
    fyiView.actions = [
      {
        label: `View ${fyi.objectType}`,
        isPrimary: true,
        isDismiss: false,
      },
    ];
    return fyiView;
  }

  private generateUserImage(): UiIconModel {
    return { type: 'img', value: { lightUrl: this._workspaceAccount?.picture } };
  }

  private get verificationIcon(): UiIconModel {
    return { type: 'img', value: { lightUrl: './assets/collections/card-icon.svg' } };
  }

  private generateUserName(): string {
    let userName = `${this._workspaceAccount?.firstName || ''} ${this._workspaceAccount?.lastName || ''}`;
    if (!userName.trim().length) {
      userName = 'A member';
    }
    return userName;
  }
}
