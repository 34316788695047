import { Config } from '@environments/config';
import { Experiences, Style } from '@local/client-contracts';
import { EntityIcon } from '@local/client-contracts/src/style';
import { AssistantsIconsConst, Constants } from '@local/common';
import {
  GENERAL_ASSISTANT_NAME,
  RFP_ASSISTANT_NAME,
  SALESFORCE_ASSISTANT_NAME,
  SLACK_ASSISTANT_NAME,
  TEAM_ASSISTANT_NAME,
  ZENDESK_ASSISTANT_NAME,
} from '@local/common-web';
import { OptionValue } from '../../collections-page/components/multi-choice-popup/multi-choice-popup.component';
import { AssistantBullet } from '../models/assistant-bullet.model';

const WARNING_CONTENT: Record<Experiences.ExperienceType, Record<Experiences.PermissionRole, string>> = {
  rfp: {
    creator: 'Private links and wikis set as data sources will be visible as answers in the form',
    editor: 'Only links and wikis accessible to the assistant creator can be added.',
    viewer: null,
  },
  slack: {
    creator: 'Private links and Wikis set as data sources for the bot will be visible as answers to all channel members.',
    editor: 'Only links and wikis accessible to the assistant creator can be added.',
    viewer: null,
  },
  teams: {
    creator: 'Private links and Wikis set as data sources for the bot will be visible as answers to all channel members.',
    editor: 'Only links and wikis accessible to the assistant creator can be added.',
    viewer: null,
  },
  zendesk: {
    creator: 'For optimal bot functionality, ensure that the links and wikis you set are shared with your Unleash workspace.',
    editor: 'For optimal bot functionality, ensure that the links and wikis you set are shared with your Unleash workspace.',
    viewer: null,
  },
  salesforce: {
    creator: 'For optimal bot functionality, ensure that the links and wikis you set are shared with your Unleash workspace.',
    editor: 'For optimal bot functionality, ensure that the links and wikis you set are shared with your Unleash workspace.',
    viewer: null,
  },
  general: {
    creator: null,
    editor: 'Only links and wikis accessible to the assistant creator can be added.',
    viewer: null,
  },
};
export const assistantContent = {
  created: 'created',
  updated: 'updated',
  headerInfo: {
    slack: 'Select the data sources the bot will use to answer questions asked in this channel',
    zendesk: 'Choose the data sources that Unleash will use to generate answers and suggestions in Zendesk',
    rfp: 'Select the knowledge base for auto-filling RFPs, security questionnaires, and more.',
    teams: 'Select the data sources the bot will use to answer questions asked in this channel',
    salesforce: 'Choose the data sources that Unleash will use to generate answers and suggestions for Salesforce Cases.',
    general: 'Select data sources to customize the search experience for the desired platform.',
  },
  headerInfoReadonly: {
    slack: 'Answers to questions asked in this channel will be extracted from the following data sources',
    zendesk: 'Answers and suggestions provided within your Zendesk Suite are extracted from the following data sources',
    rfp: 'The knowledge bases selected for auto-filling RFPs, security questionnaires, and more.',
    teams: 'Answers to questions asked in this channel will be extracted from the following data sources',
    general: 'Answers provided by the assistant embedded in your platform are extracted from the following data sources.',
    salesforce: 'Suggestions provided for your Salesforce cases are configured and extracted from the following data sources.',
  },
  learnMoreUrl: {
    rfp: Constants.LEARN_MORE_RFP_URL,
    general: Constants.LEARN_MORE_GENERAL_ASSISTANT,
  },
  warningsContent: WARNING_CONTENT,
  preferences: 'Preferences',
  wikiCollections: 'Knowledge from Unleash',
  apps: 'Knowledge from Apps',
  wikiCollectionInfo: {
    teams: 'The bot will use the data and files stored within the cards of the selected Wikis',
    slack: 'The bot will use the data and files stored within the cards of the selected Wikis',
    zendesk: 'The bot will use the data and files stored within the cards of the selected Wikis',
    rfp: 'Unleash will use the data and files stored within the cards of the selected Wikis',
    salesforce: 'The bot will use the data and files stored within the cards of the selected Wikis',
    general: 'The bot will use the data and files stored within the cards of the selected Wikis',
  },
  addQuery: 'Add data source',
  noAppsMessage: 'No data sources have been selected',
  noResults: 'No results',
  assistants: 'Assistants',
  addApp: 'Add App',
  remove: 'Remove',
  cancel: 'Cancel',
  save: 'Save',
  updateText: 'The changes have been saved',
  createText: 'created successfully',
  assistantSubtitle: 'Create AI Assistants tailored for automated question answering, completing forms, and more.',
  learnMore: 'Learn More',
  newAssistant: 'New Assistant',
  disabledCreateButtonTooltip: 'Adding Assistants is limited to administrators of this workspace',
  accountNoAccessMessage: 'No access',
};

export const assistantBulletsContent: Record<Experiences.ExperienceType, AssistantBullet[]> = {
  slack: [
    {
      description: 'Require expert validation before publishing an answer',
      type: 'enableExpertsValidation',
      displayInfoIcon: true,
      infoTooltip:
        'When a user poses a question in a channel, Unleash will initially display the response exclusively to knowledge experts for approval or disapproval. The answer will become visible to everyone in the channel only upon the expert’s approval',
    },
    {
      type: 'enableRelatedQuestions',
      icon: AssistantsIconsConst['slack'] as EntityIcon<Style.EntityIconType>,
      description: 'Display related questions from selected Slack channels',
      actionInfo: {
        preText: 'Up to',
        postText: 'Questions',
      },
    },
    {
      type: 'startReaction',
      emoji: '1F440', //eyes
      description: 'React with',
      descriptionAfterEmoji: ' icon while searching for a suitable answer',
    },
    {
      type: 'endReaction',
      emoji: '1F937-200D-2642-FE0F', //shrug
      description: 'React with',
      descriptionAfterEmoji: ' icon when the bot can’t find an answer',
    },
    {
      type: 'feedbackReasoning',
      description: 'Specify reasons for negative feedback',
      displayInfoIcon: true,
      infoTooltip:
        'To enhance our algorithm, we encourage feedback on the bot’s generated answers. When negative feedback is received, we enable the user to provide a reason for the feedback, allowing us to better understand what went wrong.',
    },
  ],
  teams: [
    {
      type: 'feedbackReasoning',
      description: 'Specify reasons for negative feedback',
      displayInfoIcon: true,
      infoTooltip:
        'To enhance our algorithm, we encourage feedback on the bot’s generated answers. When negative feedback is received, we enable the user to provide a reason for the feedback, allowing us to better understand what went wrong.',
    },
  ],
  rfp: [],
  zendesk: [],
  salesforce: [],
  general: [
    {
      type: 'deploymentContext',
      description: 'Audience',
      infoTooltip: '<b>Audience</b> <br/> Tailor the assistant for internal or customer-facing applications as needed.',
      dropdownOptions: [
        { label: 'Internal users', subLabel: 'Integrate Unleash within platforms exclusive to your organization’s employees.' },
        { label: 'External users', subLabel: 'Integrate Unleash within platforms accessible to your organization’s external audience.' },
      ],
    },
    {
      type: 'identity',
      description: 'Identity',
      infoTooltip:
        '<b>Identity</b> <br/>This field determines search permissions.<br/> "Personal" tailors the experience to user permissions, while "Non-personal" aligns it with the assistant creator’s permissions.',
      dropdownOptions: [
        { label: 'Personal', subLabel: 'Tailored search experience based on user permissions.' },
        { label: 'Non-Personal', subLabel: 'Search experience aligned with the assistant creator’s permissions.' },
      ],
    },
  ],
};

export const filtersSourceActions = {
  privateLink: 'Private Link',
  priorityOn: 'Deprioritize',
  priorityOff: 'Click to prioritize answers from this source over others',
  priorityView: 'Answers from this source will be prioritized higher than other sources',
  priorityOffDisabled: 'Prioritizing requires at least two sources to be selected',
  noResultsInd: '0 Results',
};

export const filterSourceWarning = {
  deletedLink: 'Link removed. Delete this source, then reconnect if necessary.',
  staleLink: 'Link outdated. Reconnect or delete this source.',
  noAccess:
    "The link is missing some information, possibly due to removal or lack of access. Contact the Assistant's creator for further details.",
  creatorNoAccess: {
    editor: 'The link is not accessible to the assistant’s creator. Please remove and add it again once it is shared.',
    viewer: 'The creator of this Assistant has no access to this link so it can not be used as a source. ',
  },
};

export const specificResourcePopupContent = {
  placeholder: 'Search for a specific resource',
  resourcesSelected: 'resources selected',
  cancel: 'Cancel',
  select: 'Select',
  clearAll: 'Clear all',
};

export const assistantsEmptyStateContent = {
  description: 'Optimize team efficiency by introducing Q&A bots and implementing automated form completions',
  assistantsApps: [
    {
      name: GENERAL_ASSISTANT_NAME,
      text: 'Integrate Unleash to elevate your search capabilities. Tailor the search experience to perfectly align with your needs, ensuring users always find relevant information effortlessly.',
      buttonText: 'Create New',
      icon: AssistantsIconsConst['general'],
      value: 'general',
    },
    {
      name: SLACK_ASSISTANT_NAME,
      text: 'Transform your Slack channels with the Unleash Bot, which provides you with relevant answers drawn from your organizational knowledge.',
      buttonText: 'Add to Slack',
      icon: AssistantsIconsConst['slack'],
      value: 'slack',
    },
    {
      name: TEAM_ASSISTANT_NAME,
      text: 'Level up your Microsoft Teams channels with the Unleash Bot, which delivers accurate answers to your questions sourced from your organization’s knowledge.',
      buttonText: Config.teamsInstallUrl ? 'Add to Teams' : 'Coming soon',
      icon: AssistantsIconsConst['teams'],
      disabled: Config.teamsInstallUrl ? false : true,
      value: 'teams',
    },
    {
      name: ZENDESK_ASSISTANT_NAME,
      text: 'Seamlessly integrate Unleash with Zendesk suite to empower agents with instant information, real-time coaching, and a unified knowledge hub.',
      buttonText: 'Add to Zendesk',
      icon: AssistantsIconsConst['zendesk'],
      value: 'zendesk',
    },
    {
      name: SALESFORCE_ASSISTANT_NAME,
      text: 'Resolve your Salesforce cases faster with the Unleash Bot, empowering your teams with instant insights for better customer support.',
      buttonText: 'Create New',
      icon: AssistantsIconsConst['salesforce'],
      value: 'salesforce',
    },
    {
      name: RFP_ASSISTANT_NAME,
      text: 'Seamlessly automate the completion of RFPs, security questionnaires, and other type of forms using your organizational knowledge.',
      buttonText: 'Create New',
      icon: AssistantsIconsConst['rfp'],
      value: 'rfp',
    },
  ],
};

export const addAssistantsContent: OptionValue[] = [
  {
    text: 'General Assistant',
    description: 'Customize your search experience in any platform',
    icon: AssistantsIconsConst['general'],
    value: 'general',
  },
  {
    text: 'RFP Assistant',
    description: 'Automate RFPs, security questionnaires, and more',
    icon: AssistantsIconsConst['rfp'],
    value: 'RFP',
  },
  {
    text: 'Slack Channels Assistant',
    description: 'Automated answers for any Slack channel',
    icon: AssistantsIconsConst['slack'],
    value: 'slack',
    warningText: 'This feature has been disabled by an admin.<br/> Please contact them to enable it for you.',
  },
  {
    text: 'Teams Channels Assistant',
    description: Config.teamsInstallUrl ? 'Automated answers for any Teams channel' : 'Coming soon',
    icon: AssistantsIconsConst['teams'],
    value: 'teams',
    disabled: !Config.teamsInstallUrl,
  },
  {
    text: 'Zendesk Tickets Assistant',
    description: 'Automated responses for tickets in Zendesk',
    icon: AssistantsIconsConst['zendesk'],
    value: 'zendesk',
  },
  {
    text: 'Salesforce Cases Assistant',
    description: 'Automated responses for cases in Salesforce',
    icon: AssistantsIconsConst['salesforce'],
    value: 'salesforce',
  },
];

export const assistantFlags = [
  { value: 'salesforce', flag: Constants.ENABLED_SALESFORCE_CASES_ASSISTANT },
  { value: 'general', flag: Constants.ENABLED_GENERAL_ASSISTANT },
];

export const assistantWikisContent = {
  privateWikis: 'Private Wiki',
  creatorNoAccess: 'Not shared with the creator',
  noSelectedWikis: 'No data sources have been selected',
};
