import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Collections } from '@local/client-contracts';
import { MultiSelectModel, UButtonComponent, UMultiSelectComponent } from '@local/ui-infra';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DisplaySearchFilterValue, Filter } from '@shared/components/filters/models';
import { EventsService } from '@shared/services';
import { CustomKeyboardEvent, KeyboardService } from '@shared/services/keyboard.service';
import { keyCodes, KeyName } from '@local/ts-infra';
import { isEqual } from 'lodash';
import { filter, Subject } from 'rxjs';
import { HubService } from 'src/app/bar/services/hub.service';
import { CollectionTag } from '../../models';
import { CollectionsComponentFocused } from '../collection-view/collection-view.component';
import { removeSpecialCharacters } from '@shared/utils/url-util';

interface DisplayNewFilterValue extends DisplaySearchFilterValue {
  isNew?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'collection-tag-section',
  templateUrl: './collection-tag-section.component.html',
  styleUrls: ['./collection-tag-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionTagSectionComponent implements OnInit, OnDestroy {
  private _id: string;
  @Input() set id(val: string) {
    if (!val) return;
    const prevId = this._id;
    this._id = val;
    if (!isEqual(prevId, val)) {
      setTimeout(() => {
        this.uMultiSelect?.onClearEvent();
      }, 0);
      this.cdr.markForCheck();
    }
  }
  get id() {
    return this._id;
  }

  _initialTags: string[];
  @Input() set initialTags(v: string[]) {
    this._initialTags = v ? [...v] : [];
    this.updateTagValues();
  }

  get initialTags() {
    return this._initialTags;
  }

  private _componentFocused: boolean;
  @Input() set componentFocused(focus: boolean) {
    this._componentFocused = focus;
    if (focus) {
      this.tagMarkIndex = 0;
      this.registerKeyboardHandler();
    } else {
      this.unregisterKeyHandler();
      this.tagMarkIndex = 0;
    }
  }

  @Input() set closeDropdown(val: number) {
    this.closeMultiSelect();
  }

  get componentFocused(): boolean {
    return this._componentFocused;
  }

  @Output() componentFocusedFinish = new EventEmitter<'next' | null>();
  @Output() componentFocusedUpdate = new EventEmitter();

  @ViewChild(UMultiSelectComponent) uMultiSelect: UMultiSelectComponent;
  tagMultiSelectModel: Filter;
  tagFilter = '';

  @ViewChildren('tag', { read: ElementRef }) private tagsRef: QueryList<ElementRef>;
  private allAvailableTags: CollectionTag[];
  private keyHandlerId: string;
  private tagMarkIndex = 0;
  @ViewChild('addTagButton') addTagButton: UButtonComponent;

  @Input() notifyAvailableTagsChange: Subject<CollectionTag[]>;
  @Output() onTagChange = new EventEmitter();
  @Input() canEdit: boolean;
  @Input() itemSubtitle: string;
  @Input() inlineMode: boolean;
  @Input() isLiveUpdate = true;

  get options(): MultiSelectModel {
    return {
      optionLabel: 'value',
      options: this.tagMultiSelectModel?.values || [],
    };
  }

  get overlay() {
    return this.uMultiSelect?.overlay;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private keyboardService: KeyboardService,
    private eventsService: EventsService,
    private hubService: HubService
  ) {
    this.initTagSection();
  }
  ngOnInit(): void {
    this.notifyAvailableTagsChange.pipe(filter((r) => !!r)).subscribe((t) => {
      this.allAvailableTags = t;
      if (this.id) {
        this.updateTagValues();
      }
    });
  }

  ngOnDestroy(): void {
    this.unregisterKeyHandler();
  }
  private updateTagValues() {
    this.tagMultiSelectModel.values = this.allAvailableTags
      ?.filter((r) => !this.initialTags?.find((t) => t === r.value))
      .map((r) => ({ ...r, id: r.value, title: null, subtitle: null, filterName: 'tags' }));
    this.cdr.markForCheck();
  }

  private initTagSection() {
    const title = 'Choose or create tag';
    this.tagMultiSelectModel = {
      picker: 'multi-select',
      name: 'tags',
      title,
      type: 'pre',
      values: [],
      viewDetails: {
        placeholder: title,
      },
    };
  }

  isMultiSelectOpen() {
    return this.uMultiSelect?.multiSelect.overlayVisible;
  }

  onClick(event) {
    if (this.isMultiSelectOpen()) {
      this.sendTelemetryEvent('search_tag');
    }
  }

  private togglePanel() {
    this.isMultiSelectOpen() ? this.uMultiSelect.multiSelect.hide() : this.uMultiSelect.multiSelect.show();
  }

  closeMultiSelect() {
    if (this.isMultiSelectOpen()) {
      this.uMultiSelect.multiSelect.hide();
      this.componentFocusedFinish.emit('next');
    }
  }

  onRemoveTag(tag, index) {
    this.initialTags.splice(index, 1);
    const collectionAction: Collections.UpdateAction = {
      field: 'tags',
      type: 'SetRemove',
      value: tag,
    };
    this.onTagChange.emit({ action: collectionAction, tags: this.initialTags });
    this.sendTelemetryEvent('remove_tag');
  }

  addTags() {
    this.togglePanel();
    if (this.isMultiSelectOpen()) {
      this.componentFocusedUpdate.emit(CollectionsComponentFocused.TAGS);
    }
  }

  addNewTag(tag: string, isNew: boolean) {
    if ((!!tag && !this.initialTags) || this.initialTags?.indexOf(tag) === -1) {
      tag = removeSpecialCharacters(tag);
      this.addTag(tag);
      this.sendTelemetryEvent(isNew ? 'create_tag' : 'choose_tag');
      this.uMultiSelect.multiSelect.hide();
    }
  }

  addTag(tag: string) {
    const collectionAction: Collections.UpdateAction = {
      field: 'tags',
      type: 'SetAdd',
      value: tag,
    };
    if (!this.initialTags) {
      this.initialTags = [];
    }
    if (!this.isLiveUpdate) {
      this.initialTags = [...this.initialTags, tag];
    } else {
      this.initialTags.push(tag);
    }
    this.onTagChange.emit({ action: collectionAction, tags: this.initialTags });
  }

  get isNewTagAlreadyExist() {
    return this.allAvailableTags.find((t) => t.value === this.tagFilter);
  }

  private sendTelemetryEvent(label: 'search_tag' | 'choose_tag' | 'create_tag' | 'remove_tag') {
    this.eventsService.event('collections.collection_tag', {
      location: { title: this.hubService.currentLocation },
      label,
    });
  }

  enterPressed() {
    if (this.tagFilter?.length > 0) {
      this.addNewTag(this.tagFilter, true);
    }
  }

  //KEY HANDLING
  private unregisterKeyHandler() {
    if (!this.keyHandlerId) return;
    this.keyboardService.unregisterKeyHandler(this.keyHandlerId);
    this.keyHandlerId = null;
  }

  private registerKeyboardHandler() {
    if (this.keyHandlerId) return;
    this.keyHandlerId = this.keyboardService.registerKeyHandler((keys, event) => {
      this.handleKeys(keys, event);
    }, 8);
    this.cdr.markForCheck();
  }

  private handleKeys(keys: Array<KeyName>, event: CustomKeyboardEvent) {
    const key = keys[0];

    switch (key) {
      case keyCodes.tab.toLowerCase(): {
        if (this.isMultiSelectOpen()) {
          this.uMultiSelect.multiSelect.hide();
          this.componentFocusedFinish.emit('next');
        } else {
          this.markTag();
        }
        event.stopPropagation();
        event.preventDefault();
        break;
      }
      case keyCodes.escape.toLowerCase(): {
        if (this.isMultiSelectOpen()) {
          this.uMultiSelect.multiSelect.hide();
          this.componentFocusedFinish.emit('next');
        }
        break;
      }
      case keyCodes.enter.toLowerCase(): {
        if (this.uMultiSelect.multiSelect?._filteredOptions?.length > 0) {
          this.addNewTag(this.uMultiSelect.multiSelect?._filteredOptions[0].value, false);
          event.stopPropagation();
          event.preventDefault();
        } else if (this.tagFilter?.length > 0) {
          this.addNewTag(this.tagFilter, true);
          event.stopPropagation();
          event.preventDefault();
        }
        break;
      }
      default:
    }
  }

  private markTag() {
    if (this.tagMarkIndex >= this.tagsRef.length) {
      this.componentFocusedFinish.emit('next');
      this.tagsRef.get(this.tagMarkIndex - 1).nativeElement.blur();
      this.tagMarkIndex = 0;
    } else if (this.tagMarkIndex === this.tagsRef.length - 1) {
      this.togglePanel();
      this.tagMarkIndex++;
    } else {
      this.tagsRef.get(this.tagMarkIndex).nativeElement.focus();
      this.tagMarkIndex++;
    }
  }

  private removeCreateNewOption() {
    this.tagMultiSelectModel.values = this.tagMultiSelectModel.values.filter((f) => !(<DisplayNewFilterValue>f).isNew);
  }

  onFilter(filterValue: string) {
    this.tagFilter = this.tagFilter.toLocaleLowerCase();
    this.tagFilter = filterValue.toLocaleLowerCase();
    let createNewOption: DisplayNewFilterValue = this.tagMultiSelectModel.values.find((f) => (<DisplayNewFilterValue>f).isNew);

    if (this.isNewTagAlreadyExist || !filterValue) {
      this.removeCreateNewOption();
      return;
    }
    createNewOption = this.getCreateNewOption(filterValue);
    this.uMultiSelect.multiSelect._filteredOptions?.push(createNewOption);
    this.uMultiSelect.updateDicOptions(this.uMultiSelect.multiSelect._filteredOptions);
    this.cdr.markForCheck();
  }

  private getCreateNewOption(value?: string) {
    return {
      filterName: 'tags',
      value: value,
      id: value,
      isNew: true,
      title: null,
      subtitle: null,
      disabled: false,
    };
  }
}
