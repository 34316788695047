import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { Links } from '@local/client-contracts';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CardSetSelection, CardSetSelectionItem } from '../../../../shared/components/card-set-selection/card-set.model';

type LinkCardSetSelectionItem = Omit<CardSetSelectionItem, 'id'> & {
  id: Links.LinkLevel;
};

@UntilDestroy()
@Component({
  selector: 'choose-link-level',
  templateUrl: './choose-link-level.component.html',
  styleUrls: ['./choose-link-level.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChooseLinkLevelComponent {
  @Output() linkLevel = new EventEmitter<Links.LinkLevel>();

  cardsSet: CardSetSelection;
  cards: LinkCardSetSelectionItem[] = [
    {
      id: 'Private',
      title: 'Private',
      content: 'For personal<br />use only',
    },
    {
      id: 'Public',
      title: 'Shared',
      content: 'To connect with<br />colleagues',
    },
  ];

  constructor(private cdr: ChangeDetectorRef) {
    this.cardsSet = {
      cards: this.cards,
    };
    this.cdr.markForCheck();
  }

  cardSelected(level: Links.LinkLevel) {
    this.linkLevel.emit(level);
  }
}
